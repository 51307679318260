import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { useSnackbar } from "notistack";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { Browser_Types_File__File_ReadAsByteArray } from "../fable_modules/Fable.Remoting.Client.7.25.0/Extensions.fs.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { createElement } from "react";
import { Page } from "../components/Page.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { Compensation_get_DefaultCompensation } from "../../../shared/Domain.fs.js";
import { CreateDossierStepperPage } from "../../../DesignSystems/dossier/DossierCreate.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail, customerDetail } from "../AppRoutes.fs.js";
import { curry } from "../fable_modules/fable-library.3.7.17/Util.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { DossierCreatePageSource } from "../../../DesignSystems/DossierUtils.fs.js";

export function Create(createInputProps) {
    const api = createInputProps.api;
    const currentPage = createInputProps.currentPage;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const customers = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCustomers(new PagingQuery(0, 2147483647), void 0), [patternInput[0]]);
    const snackbar = useSnackbar();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setCustomer = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const compensationAmount = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCompensationAmount(), []);
    const uploadFile = (dossierCode, file, endpoint) => singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file), (_arg) => singleton.Bind(endpoint(dossierCode)(_arg)(file.name), (_arg_1) => {
        const output = _arg_1;
        if (output.tag === 1) {
            const err = output.fields[0];
            console.error(err);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        }
        else {
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Bestand opgeladen", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
            return singleton.Return();
        }
    }))), (_arg_2) => {
        const e = _arg_2;
        console.error(e);
        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
        return singleton.Zero();
    }));
    const getCustomer = (customerCode) => {
        startImmediate(singleton.Delay(() => singleton.Bind(api.GetCustomer(customerCode), (_arg_8) => {
            const customer_1 = _arg_8;
            if (customer_1.tag === 1) {
                const err_2 = customer_1.fields[0];
                console.error(err_2);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_2}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                setCustomer(customer_1.fields[0]);
                return singleton.Zero();
            }
        })));
    };
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(api.GetCustomerOfPartner(), (_arg_11) => {
            const customer_3 = _arg_11;
            if (customer_3.tag === 1) {
                const err_4 = customer_3.fields[0];
                console.error(err_4);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_4}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                const customer_4 = customer_3.fields[0];
                patternInput_2[1](customer_4);
                setCustomer(customer_4);
                return singleton.Zero();
            }
        })));
    }, []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Nieuw dossier aanmaken",
        actions: [],
        children: [createElement(DisplayDeferred, {
            data: compensationAmount,
            view: (compensationAmt) => {
                const compAmt = (compensationAmt == null) ? Compensation_get_DefaultCompensation() : compensationAmt;
                return createElement(CreateDossierStepperPage, {
                    customerUrl: (arg_5) => Page$2__toUrl_2B594(customerDetail, arg_5),
                    getCustomer: getCustomer,
                    customers: customers,
                    onCreateDossier: (dossierData) => ((comment) => ((floorplan) => ((cadastralRecords) => ((propertyOtrher) => ((sendMailToPartner) => ((onCompleted) => {
                        startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.CreateDossier(dossierData, comment), (_arg_3) => {
                            let floorplan_1;
                            const result = _arg_3;
                            if (result.tag === 0) {
                                const dossierCode_1 = result.fields[0];
                                return singleton.Combine((floorplan == null) ? ((singleton.Zero())) : ((floorplan_1 = floorplan, singleton.Bind(uploadFile(dossierCode_1, floorplan_1, curry(3, api.UploadFloorplan)), () => singleton.Return()))), singleton.Delay(() => {
                                    let cadastralRecords_1;
                                    return singleton.Combine((cadastralRecords == null) ? ((singleton.Zero())) : ((cadastralRecords_1 = cadastralRecords, singleton.Bind(uploadFile(dossierCode_1, cadastralRecords_1, curry(3, api.UploadCadastralRecords)), () => singleton.Return()))), singleton.Delay(() => {
                                        let propertyOtrher_1;
                                        return singleton.Combine((propertyOtrher == null) ? ((singleton.Zero())) : ((propertyOtrher_1 = propertyOtrher, singleton.Bind(uploadFile(dossierCode_1, propertyOtrher_1, curry(3, api.UploadPropertyOther)), () => singleton.Return()))), singleton.Delay(() => {
                                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuw dossier is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                            RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierDetail, dossierCode_1.Value)), 1, 1);
                                            return singleton.Zero();
                                        }));
                                    }));
                                }));
                            }
                            else {
                                const err_1 = result.fields[0];
                                console.error(err_1);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, true));
                                return singleton.Zero();
                            }
                        })), (_arg_7) => {
                            const e_1 = _arg_7;
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, true));
                            console.error(e_1);
                            return singleton.Zero();
                        })), () => {
                            onCompleted();
                        })));
                    })))))),
                    onCreateCustomer: (customerData) => ((onCompleted_1) => {
                        startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.CreateCustomer(customerData), (_arg_9) => {
                            const result_1 = _arg_9;
                            if (result_1.tag === 0) {
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuwe klant is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                getCustomer(result_1.fields[0].Code);
                                return singleton.Zero();
                            }
                            else {
                                const err_3 = result_1.fields[0];
                                console.error(err_3);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_3}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton.Zero();
                            }
                        })), (_arg_10) => {
                            const e_2 = _arg_10;
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_2.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            console.error(e_2);
                            return singleton.Zero();
                        })), () => {
                            onCompleted_1();
                        })));
                    }),
                    customer: patternInput_1[0],
                    setCustomer: setCustomer,
                    partnersList: void 0,
                    setPartnerId: void 0,
                    dossierCreatePageSource: new DossierCreatePageSource(1),
                    compensation: compAmt,
                });
            },
        })],
    });
}

