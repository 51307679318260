import { toConsole, printf, toText, split, isNullOrWhiteSpace, join } from "./fable_modules/fable-library.3.7.17/String.js";
import { ofArray, collect, filter } from "./fable_modules/fable-library.3.7.17/List.js";
import { publicCibFormPath, partnerPortalPath } from "../../shared/Api.fs.js";

export const virtualPath = eval("window.location.pathname");

export function combine(paths) {
    const arg_2 = join("/", filter((arg_1) => (!isNullOrWhiteSpace(arg_1)), filter((segment) => (!(segment.indexOf(".") >= 0)), collect((path) => ofArray(split(path, ["/"], null, 0)), paths))));
    return toText(printf("/%s"))(arg_2);
}

export function normalize(path) {
    toConsole(printf("VirtualPath %A"))(virtualPath);
    return combine(ofArray([virtualPath, path]));
}

export function normalizeRoutes(typeName, methodName) {
    return normalize(partnerPortalPath(typeName, methodName));
}

export function normalizePublicCibRoutes(typeName, methodName) {
    return normalize(publicCibFormPath(typeName, methodName));
}

