import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useSnackbar } from "notistack";
import { ofArray, empty } from "../fable_modules/fable-library.3.7.17/List.js";
import { CommentType, DossierCode, PartnerId } from "../../../shared/Domain.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { createElement } from "react";
import { Page } from "../components/Page.fs.js";
import { ErrorText, Card } from "../../../DesignSystems/DesignSystem.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { EditDossierStepperPage } from "../../../DesignSystems/dossier/DossierEdit.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail, customerDetail } from "../AppRoutes.fs.js";
import { printf, toConsole } from "../fable_modules/fable-library.3.7.17/String.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { DossierCreatePageSource } from "../../../DesignSystems/DossierUtils.fs.js";

export function Edit(editInputProps) {
    const dossierCode = editInputProps.dossierCode;
    const api = editInputProps.api;
    const currentPage = editInputProps.currentPage;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const customer = patternInput[0];
    const snackbar = useSnackbar();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const partnerId = new PartnerId(0);
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossierDetails(new DossierCode(dossierCode)), []);
    const customers = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCustomers(new PagingQuery(0, 2147483647), void 0), []);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(new CommentType(1));
    const commentType = patternInput_4[0];
    const dossierCode_1 = new DossierCode(dossierCode);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(api.GetDossierComments(dossierCode_1, new PagingQuery(0, 2147483647), commentType), (_arg_5) => {
            const comments_1 = _arg_5;
            if (comments_1.tag === 0) {
                patternInput_1[1](comments_1.fields[0].Data);
                return singleton.Zero();
            }
            else {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
        })));
    }, [commentType]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Dossier bewerken",
        actions: [],
        children: [Card([createElement(DisplayDeferred, {
            data: data,
            view: (dossier) => {
                if (dossier == null) {
                    return createElement(ErrorText, {
                        text: `Geen Dossiers gevonden met code ${dossierCode_1}`,
                    });
                }
                else {
                    const dossier_1 = dossier;
                    let customer_3;
                    if (customer == null) {
                        customer_3 = dossier_1.Customer;
                    }
                    else {
                        const c = customer;
                        customer_3 = customer;
                    }
                    return createElement(EditDossierStepperPage, {
                        dossierDetails: dossier_1,
                        comments: patternInput_1[0],
                        commentType: commentType,
                        setCommentType: patternInput_4[1],
                        showInternalComments: false,
                        customerUrl: (arg_7) => Page$2__toUrl_2B594(customerDetail, arg_7),
                        dossierUrl: Page$2__toUrl_2B594(dossierDetail, dossier_1.Dossier.Code.Value),
                        getCustomer: (customerCode) => {
                            startImmediate(singleton.Delay(() => singleton.Bind(api.GetCustomer(customerCode), (_arg) => {
                                const customer_1 = _arg;
                                if (customer_1.tag === 1) {
                                    const err = customer_1.fields[0];
                                    console.error(err);
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    return singleton.Zero();
                                }
                                else {
                                    patternInput[1](customer_1.fields[0]);
                                    return singleton.Zero();
                                }
                            })));
                        },
                        customers: customers,
                        onEditDossier: (dossierData) => ((comment) => ((sendMailToPartner) => ((onCompleted) => {
                            startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                                toConsole(printf("%A"))(dossierData);
                                return singleton.Bind(api.EditDossier(dossierCode_1, dossierData, comment), (_arg_1) => {
                                    let matchValue;
                                    const result = _arg_1;
                                    if (result.tag === 0) {
                                        return singleton.Combine((matchValue = result.fields[0].AssignmentConfirmationResult, (matchValue.tag === 1) ? ((ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een nieuw dossier is aangemaakt. ${matchValue.fields[0]}`, SnackbarProp_Variant_Z609E1E86("warning"), new EnqueueSnackbarOption(1, false)), singleton.Zero())) : ((ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een nieuw dossier is aangemaakt. ${matchValue.fields[0]}`, SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false)), singleton.Zero()))), singleton.Delay(() => {
                                            RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierDetail, dossierCode_1.Value)), 1, 1);
                                            return singleton.Zero();
                                        }));
                                    }
                                    else {
                                        const err_1 = result.fields[0];
                                        console.error(err_1);
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                        return singleton.Zero();
                                    }
                                });
                            }), (_arg_2) => {
                                const e = _arg_2;
                                console.error(e);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton.Zero();
                            })), () => {
                                onCompleted();
                            })));
                        }))),
                        onCreateCustomer: (customerData) => ((onCompleted_1) => {
                            startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.CreateCustomer(customerData), (_arg_3) => {
                                const result_1 = _arg_3;
                                if (result_1.tag === 0) {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuwe klant is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                    return singleton.Zero();
                                }
                                else {
                                    const err_2 = result_1.fields[0];
                                    console.error(err_2);
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_2}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    return singleton.Zero();
                                }
                            })), (_arg_4) => {
                                const e_1 = _arg_4;
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                console.error(e_1);
                                return singleton.Zero();
                            })), () => {
                                onCompleted_1();
                            })));
                        }),
                        customer: customer_3,
                        customerOfPartner: void 0,
                        partnersList: patternInput_2[0],
                        setPartnerId: patternInput_3[1],
                        dossierCreatePageSource: new DossierCreatePageSource(1),
                    });
                }
            },
        })])],
    });
}

