import { useSnackbar } from "notistack";
import { useReact_useMemo_CF4EA67, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { State, searchTerms, initialState, update } from "../../../DesignSystems/dossier/DossierFilter.fs.js";
import { useDebouncedValue } from "../../../CustomHooks/CustomHooks.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { CustomerCode as CustomerCode_2 } from "../../../shared/Domain.fs.js";
import { DossierSearchTerms } from "../../../shared/DomainHelpers.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail, customerEdit } from "../AppRoutes.fs.js";
import { createElement } from "react";
import * as react from "react";
import Edit from "@material-ui/icons/Edit";
import { Page } from "../components/Page.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { DisplayCustomerInfo } from "../../../DesignSystems/CustomerForms.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";
import { PaddingTop } from "../../../Styles/Utils.fs.js";
import { DossiersData } from "../../../DesignSystems/dossier/DossierDetails.fs.js";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";

export function Detail(detailInputProps) {
    let props;
    const customerCode = detailInputProps.customerCode;
    const api = detailInputProps.api;
    const currentPage = detailInputProps.currentPage;
    const snackbar = useSnackbar();
    const patternInput = useReact_useReducer_2B9E6EA0(update, initialState);
    const state_1 = patternInput[0];
    const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => searchTerms(state_1), [state_1]));
    const datas = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossiers(new PagingQuery(state_1.PageNumber, state_1.PageSize), new DossierSearchTerms(search.DossierCode, search.PartnerName, search.AdiName, search.CustomerName, search.Address, search.PhoneNumber, search.Status, search.StartDate, search.EndDate, search.FilterOutDossiersWithoutPartners, search.CibFilter, new CustomerCode_2(customerCode), search.PartnerPortal, search.Urgent, search.PartnerId, search.PartnerIdList)), [state_1.PageNumber, state_1.PageSize, search]);
    const editCustomerButton = MuiHelpers_createElement(Button, [["onClick", (_arg_1) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(customerEdit, customerCode)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Edit, props))], ["children", "Klant bewerken"]]);
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCustomer(new CustomerCode_2(customerCode)), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Klantenfiche",
        actions: [editCustomerButton],
        children: [createElement(DisplayDeferred, {
            data: data,
            view: (customer) => {
                let elems_1, children_1;
                if (customer == null) {
                    return null;
                }
                else {
                    const customer_1 = customer;
                    const children_2 = ofArray([createElement(DisplayCustomerInfo, {
                        customer: customer_1,
                        partner: void 0,
                        onPartnerClick: void 0,
                    }), createElement("div", createObj(ofArray([["className", join(" ", [PaddingTop.M])], (elems_1 = [createElement("h2", {
                        children: ["Gekoppelde dossiers"],
                    }), (children_1 = singleton(createElement(DossiersData, {
                        data: datas,
                        state: new State(state_1.DossierCode, state_1.AdiName, state_1.PartnerName, state_1.CustomerName, new CustomerCode_2(customerCode), state_1.Address, state_1.PhoneNumber, state_1.DateRange, state_1.PartnerId, state_1.Status, state_1.PageNumber, state_1.PageSize, state_1.CibFilter, state_1.PartnerPortal, state_1.Urgent),
                        dispatch: patternInput[1],
                        url: (dossierId) => Page$2__toUrl_2B594(dossierDetail, dossierId.Value),
                        showPartnerName: false,
                        optionalExportCsvFunction: void 0,
                        showCibFilter: true,
                        isBackOffice: false,
                        klantNameHeader: void 0,
                    })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]);
                    return createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    });
                }
            },
        })],
    });
}

