import { fss, fontFaces } from "../partnerportal/src/fable_modules/Fss-lib.Fable.1.0.3/FssFable.fs.js";
import { FontFaceClasses_FontStyle__get_normal, FontFaceClasses_FontWeight__get_normal, FontFaceClasses_Src__opentype_Z721C83C5 } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/FontFace.fs.js";
import { FontStyle, FontWeight, Src } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/FontFace.fs.js";
import { empty, singleton, ofArray } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import { pct, px } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { CssRuleWithNone__get_none } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/MasterTypes.fs.js";
import { Display } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Display.fs.js";
import { DisplayClasses_DisplayClass__get_grid, DisplayClasses_DisplayClass__get_flex } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Display.fs.js";
import { FlexClasses_FlexShrinkGrow__value_5E38073B, FlexClasses_FlexDirection__get_column, FlexClasses_FlexWrap__get_wrap, FlexClasses_FlexDirection__get_row, FlexClasses_AlignSelf__get_end$0027, FlexClasses_JustifySelf__get_end$0027, FlexClasses_AlignSelf__get_center, FlexClasses_JustifySelf__get_center, FlexClasses_AlignSelf__get_start, FlexClasses_JustifySelf__get_start, FlexClasses_JustifyContent__get_spaceBetween, FlexClasses_AlignItems__get_center, FlexClasses_JustifyContent__get_end$0027, FlexClasses_JustifyContent__get_center } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Flex.fs.js";
import { FlexGrow, FlexWrap, FlexDirection, AlignSelf, JustifySelf, AlignItems, JustifyContent } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Flex.fs.js";
import { TextClasses_TextAlign__get_center, TextClasses_TextAlign__get_end$0027, TextClasses_TextAlign__get_start } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Text.fs.js";
import { TextAlign } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Text.fs.js";
import { unitHelpers_DirectionalLength__value_Z498FEB3B, Percent, unitHelpers_CssRuleWithLength__value_Z498FEB3B, unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { MarginRight as MarginRight_1, MarginLeft as MarginLeft_1, MarginBottom as MarginBottom_1, MarginTop as MarginTop_1 } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Margin.fs.js";
import { Padding as Padding_1, PaddingRight as PaddingRight_1, PaddingLeft as PaddingLeft_1, PaddingBottom as PaddingBottom_1, PaddingTop as PaddingTop_1 } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Padding.fs.js";
import { GridClasses_GridGap__value_Z498FEB3B } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Grid.fs.js";
import { GridGap } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Grid.fs.js";
import { FontWeight as FontWeight_1, FontSize } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Font.fs.js";
import { FontClasses_FontWeight__value_Z524259A4 } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Font.fs.js";
import { CursorClasses_CursorParent__get_default$0027, CursorClasses_CursorParent__get_pointer } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Cursor.fs.js";
import { Cursor } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Cursor.fs.js";
import { MinHeight, Height, Width } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/ContentSize.fs.js";
import { ColorClass_Color__hex_Z721C83C5, ColorClass_Color__get_white, ColorClass_Color__get_red } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Color.fs.js";
import { Color } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Color.fs.js";
import { PositionClasses_Position__get_absolute } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Position.fs.js";
import { Left, Top, Position } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Position.fs.js";
import { Transform_Transform, TransformClasses_TransformClass__value_ZF56B0F4 } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Transform.fs.js";
import { Transform } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Transform.fs.js";
import { Media_Device, MediaClasses_Media__queryFor } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Media.fs.js";
import { Media } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Media.fs.js";
import { Label_Label } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Content.fs.js";
import { Custom } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Custom.fs.js";
import { BackgroundColor } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Background.fs.js";
import { BorderRadius } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Border.fs.js";
import { Hover } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/PseudoClass.fs.js";

export const coreFont = fontFaces("Lufga Regular", singleton(ofArray([FontFaceClasses_Src__opentype_Z721C83C5(Src, "./fonts/lufga-regular.otf"), FontFaceClasses_FontWeight__get_normal(FontWeight), FontFaceClasses_FontStyle__get_normal(FontStyle)])));

export const Var = (() => {
    const Colors = {
        Background: "fff",
        Danger: "ef5350",
        Info: "03a9f4",
        Primary: "48b9b2",
        Secondary: "F18333",
        Success: "4caf50",
        Text: "000000",
        TextLight: "333333",
        TextVeryLight: "666666",
        TextWhite: "fff",
        Warning: "ff9800",
    };
    let TextSize_1;
    const Xxxl = px(75);
    const Xxl = px(40);
    const Xl = px(32);
    TextSize_1 = {
        L: px(24),
        M: px(18),
        S: px(16),
        Xl: Xl,
        Xs: px(14),
        Xxl: Xxl,
        Xxs: px(12),
        Xxxl: Xxxl,
    };
    let Spacing;
    const Xxl_1 = px(128);
    const Xl_1 = px(64);
    Spacing = {
        L: px(32),
        M: px(16),
        S: px(8),
        Xl: Xl_1,
        Xs: px(4),
        Xxl: Xxl_1,
        Xxs: px(2),
    };
    return {
        Border: {
            ExtraSharp: px(2),
            Sharp: px(8),
            Soft: px(16),
        },
        Colors: Colors,
        Spacing: Spacing,
        TextSize: TextSize_1,
    };
})();

export const hide = fss(singleton(CssRuleWithNone__get_none(Display)));

export const hCenter = fss(ofArray([DisplayClasses_DisplayClass__get_flex(Display), FlexClasses_JustifyContent__get_center(JustifyContent)]));

export const hEnd = fss(ofArray([DisplayClasses_DisplayClass__get_flex(Display), FlexClasses_JustifyContent__get_end$0027(JustifyContent)]));

export const vCenter = fss(ofArray([DisplayClasses_DisplayClass__get_flex(Display), FlexClasses_AlignItems__get_center(AlignItems)]));

export const center = fss(ofArray([DisplayClasses_DisplayClass__get_flex(Display), FlexClasses_JustifyContent__get_center(JustifyContent), FlexClasses_AlignItems__get_center(AlignItems)]));

export const spaceBetween = fss(ofArray([DisplayClasses_DisplayClass__get_flex(Display), FlexClasses_JustifyContent__get_spaceBetween(JustifyContent)]));

export const textStart = fss(singleton(TextClasses_TextAlign__get_start(TextAlign)));

export const textEnd = fss(singleton(TextClasses_TextAlign__get_end$0027(TextAlign)));

export const textCenter = fss(singleton(TextClasses_TextAlign__get_center(TextAlign)));

export const alignStart = fss(ofArray([FlexClasses_JustifySelf__get_start(JustifySelf), FlexClasses_AlignSelf__get_start(AlignSelf)]));

export const alignCenter = fss(ofArray([FlexClasses_JustifySelf__get_center(JustifySelf), FlexClasses_AlignSelf__get_center(AlignSelf)]));

export const alignEnd = fss(ofArray([FlexClasses_JustifySelf__get_end$0027(JustifySelf), FlexClasses_AlignSelf__get_end$0027(AlignSelf)]));

export const row = fss(ofArray([DisplayClasses_DisplayClass__get_flex(Display), FlexClasses_FlexDirection__get_row(FlexDirection), FlexClasses_FlexWrap__get_wrap(FlexWrap)]));

export const col = fss(ofArray([DisplayClasses_DisplayClass__get_flex(Display), FlexClasses_FlexDirection__get_column(FlexDirection), FlexClasses_FlexWrap__get_wrap(FlexWrap)]));

export const flexExpand = fss(singleton(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1)));

export const MarginTop = (() => {
    const Xxs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginTop_1, Var.Spacing.Xxs)));
    const Xs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginTop_1, Var.Spacing.Xs)));
    const S = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginTop_1, Var.Spacing.S)));
    const M = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginTop_1, Var.Spacing.M)));
    return {
        L: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginTop_1, Var.Spacing.L))),
        M: M,
        S: S,
        Xl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginTop_1, Var.Spacing.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginTop_1, Var.Spacing.Xxl))),
        Xxs: Xxs,
    };
})();

export const MarginBottom = (() => {
    const Xxs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginBottom_1, Var.Spacing.Xxs)));
    const Xs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginBottom_1, Var.Spacing.Xs)));
    const S = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginBottom_1, Var.Spacing.S)));
    const M = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginBottom_1, Var.Spacing.M)));
    return {
        L: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginBottom_1, Var.Spacing.L))),
        M: M,
        S: S,
        Xl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginBottom_1, Var.Spacing.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginBottom_1, Var.Spacing.Xxl))),
        Xxs: Xxs,
    };
})();

export const MarginLeft = (() => {
    const Xxs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginLeft_1, Var.Spacing.Xxs)));
    const Xs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginLeft_1, Var.Spacing.Xs)));
    const S = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginLeft_1, Var.Spacing.S)));
    const M = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginLeft_1, Var.Spacing.M)));
    return {
        L: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginLeft_1, Var.Spacing.L))),
        M: M,
        S: S,
        Xl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginLeft_1, Var.Spacing.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginLeft_1, Var.Spacing.Xxl))),
        Xxs: Xxs,
    };
})();

export const MarginRight = (() => {
    const Xxs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginRight_1, Var.Spacing.Xxs)));
    const Xs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginRight_1, Var.Spacing.Xs)));
    const S = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginRight_1, Var.Spacing.S)));
    const M = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginRight_1, Var.Spacing.M)));
    return {
        L: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginRight_1, Var.Spacing.L))),
        M: M,
        S: S,
        Xl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginRight_1, Var.Spacing.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginRight_1, Var.Spacing.Xxl))),
        Xxs: Xxs,
    };
})();

export const Padding = (() => {
    const Xxs = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.Xxs), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.Xxs), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.Xxs), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.Xxs)]));
    const Xs = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.Xs), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.Xs), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.Xs), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.Xs)]));
    const S = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.S), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.S), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.S), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.S)]));
    const M = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.M), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.M), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.M), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.M)]));
    return {
        L: fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.L), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.L), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.L), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.L)])),
        M: M,
        S: S,
        Xl: fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.Xl), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.Xl), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.Xl), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.Xl)])),
        Xs: Xs,
        Xxl: fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.Xxl), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.Xxl), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.Xxl), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.Xxl)])),
        Xxs: Xxs,
    };
})();

export const PaddingTop = (() => {
    const Xxs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.Xxs)));
    const Xs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.Xs)));
    const S = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.S)));
    const M = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.M)));
    return {
        L: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.L))),
        M: M,
        S: S,
        Xl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop_1, Var.Spacing.Xxl))),
        Xxs: Xxs,
    };
})();

export const PaddingBottom = (() => {
    const Xxs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.Xxs)));
    const Xs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.Xs)));
    const S = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.S)));
    const M = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.M)));
    return {
        L: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.L))),
        M: M,
        S: S,
        Xl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom_1, Var.Spacing.Xxl))),
        Xxs: Xxs,
    };
})();

export const PaddingLeft = (() => {
    const Xxs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.Xxs)));
    const Xs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.Xs)));
    const S = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.S)));
    const M = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.M)));
    return {
        L: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.L))),
        M: M,
        S: S,
        Xl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingLeft_1, Var.Spacing.Xxl))),
        Xxs: Xxs,
    };
})();

export const PaddingRight = (() => {
    const Xxs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.Xxs)));
    const Xs = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.Xs)));
    const S = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.S)));
    const M = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.M)));
    return {
        L: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.L))),
        M: M,
        S: S,
        Xl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingRight_1, Var.Spacing.Xxl))),
        Xxs: Xxs,
    };
})();

export const Gap = (() => {
    const Xxs = fss(singleton(GridClasses_GridGap__value_Z498FEB3B(GridGap, Var.Spacing.Xxs)));
    const Xs = fss(singleton(GridClasses_GridGap__value_Z498FEB3B(GridGap, Var.Spacing.Xs)));
    const S = fss(singleton(GridClasses_GridGap__value_Z498FEB3B(GridGap, Var.Spacing.S)));
    const M = fss(singleton(GridClasses_GridGap__value_Z498FEB3B(GridGap, Var.Spacing.M)));
    return {
        L: fss(singleton(GridClasses_GridGap__value_Z498FEB3B(GridGap, Var.Spacing.L))),
        M: M,
        S: S,
        Xl: fss(singleton(GridClasses_GridGap__value_Z498FEB3B(GridGap, Var.Spacing.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(GridClasses_GridGap__value_Z498FEB3B(GridGap, Var.Spacing.Xxl))),
        Xxs: Xxs,
    };
})();

export const TextSize = (() => {
    const Xxs = fss(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, Var.TextSize.Xxs)));
    const Xs = fss(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, Var.TextSize.Xs)));
    const S = fss(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, Var.TextSize.S)));
    const M = fss(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, Var.TextSize.M)));
    return {
        L: fss(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, Var.TextSize.L))),
        M: M,
        S: S,
        Xl: fss(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, Var.TextSize.Xl))),
        Xs: Xs,
        Xxl: fss(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, Var.TextSize.Xxl))),
        Xxs: Xxs,
        Xxxl: fss(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, Var.TextSize.Xxxl))),
    };
})();

export const Boldness = {
    b300: fss(singleton(FontClasses_FontWeight__value_Z524259A4(FontWeight_1, 300))),
    b400: fss(singleton(FontClasses_FontWeight__value_Z524259A4(FontWeight_1, 400))),
    b500: fss(singleton(FontClasses_FontWeight__value_Z524259A4(FontWeight_1, 500))),
    b600: fss(singleton(FontClasses_FontWeight__value_Z524259A4(FontWeight_1, 600))),
    b700: fss(singleton(FontClasses_FontWeight__value_Z524259A4(FontWeight_1, 700))),
};

export const cursorPointer = fss(singleton(CursorClasses_CursorParent__get_pointer(Cursor)));

export const cursorDefault = fss(singleton(CursorClasses_CursorParent__get_default$0027(Cursor)));

export const fullWidth = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, pct(100))));

export const grid = fss(singleton(DisplayClasses_DisplayClass__get_grid(Display)));

export const error = fss(singleton(ColorClass_Color__get_red(Color)));

export const appointmentCal = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(1200))));

export const progress = fss(ofArray([PositionClasses_Position__get_absolute(Position), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Top, new Percent(0, 50)), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Left, new Percent(0, 50)), TransformClasses_TransformClass__value_ZF56B0F4(Transform, ofArray([new Transform_Transform(11, new Percent(0, -50)), new Transform_Transform(12, new Percent(0, -50))]))]));

export const loginLogo = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(426)), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, px(265))]));

export const loginLogoSmall = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(204)), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, px(60))]));

export const appBarLogo = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(142)), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, px(89))]));

export const hideInPrint = fss(singleton(MediaClasses_Media__queryFor(Media, new Media_Device(2), empty(), singleton(CssRuleWithNone__get_none(Display)))));

export const paddingPrint = fss(ofArray([MediaClasses_Media__queryFor(Media, new Media_Device(2), empty(), singleton(unitHelpers_DirectionalLength__value_Z498FEB3B(Padding_1, px(3)))), Label_Label("PaddingPrint")]));

export const hideShadowInPrint = fss(ofArray([MediaClasses_Media__queryFor(Media, new Media_Device(2), empty(), singleton(Custom("box-shadow", "none"))), Label_Label("hideShadowInPrint")]));

export const printInfoHeader = fss(ofArray([MediaClasses_Media__queryFor(Media, new Media_Device(2), empty(), ofArray([unitHelpers_DirectionalLength__value_Z498FEB3B(Padding_1, px(3)), Custom("lineheight", "1.5")])), Label_Label("printInfoHeader")]));

export const paddingDataDisplay = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(200)), MediaClasses_Media__queryFor(Media, new Media_Device(2), empty(), singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(100)))), Label_Label("paddingDataDisplay")]));

export const paddingDataDisplayWide = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(260)), MediaClasses_Media__queryFor(Media, new Media_Device(2), empty(), singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(100)))), Label_Label("paddingDataDisplay")]));

export const hideInScreen = fss(singleton(MediaClasses_Media__queryFor(Media, new Media_Device(0), empty(), singleton(CssRuleWithNone__get_none(Display)))));

export const modal = fss(ofArray([PositionClasses_Position__get_absolute(Position), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Top, new Percent(0, 50)), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Left, new Percent(0, 50)), TransformClasses_TransformClass__value_ZF56B0F4(Transform, ofArray([new Transform_Transform(11, new Percent(0, -50)), new Transform_Transform(12, new Percent(0, -50))])), ColorClass_Color__get_white(BackgroundColor), unitHelpers_DirectionalLength__value_Z498FEB3B(BorderRadius, Var.Border.Sharp)]));

export const allAppointmentsMinHeight = fss(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MinHeight, px(500))));

export const cancelDossierButton = fss(ofArray([ColorClass_Color__hex_Z721C83C5(BackgroundColor, Var.Colors.Danger), ColorClass_Color__hex_Z721C83C5(Color, Var.Colors.TextWhite), Hover(singleton(ColorClass_Color__hex_Z721C83C5(BackgroundColor, Var.Colors.Danger)))]));

export const markAsPaidButton = fss(ofArray([ColorClass_Color__hex_Z721C83C5(BackgroundColor, "3d9da5"), ColorClass_Color__hex_Z721C83C5(Color, Var.Colors.TextWhite), Hover(singleton(ColorClass_Color__hex_Z721C83C5(BackgroundColor, "146f76")))]));

export const dashboardFooter = fss(ofArray([ColorClass_Color__hex_Z721C83C5(BackgroundColor, "c8dddc"), Hover(singleton(ColorClass_Color__hex_Z721C83C5(BackgroundColor, "c8dddc")))]));

export const TextColor = (() => {
    const Danger = fss(singleton(ColorClass_Color__hex_Z721C83C5(Color, Var.Colors.Danger)));
    const Primary = fss(singleton(ColorClass_Color__hex_Z721C83C5(Color, Var.Colors.Primary)));
    const Secondary = fss(singleton(ColorClass_Color__hex_Z721C83C5(Color, Var.Colors.Secondary)));
    return {
        Danger: Danger,
        Light: fss(singleton(ColorClass_Color__hex_Z721C83C5(Color, Var.Colors.TextLight))),
        Primary: Primary,
        Secondary: Secondary,
        VeryLight: fss(singleton(ColorClass_Color__hex_Z721C83C5(Color, Var.Colors.TextVeryLight))),
    };
})();

export const DossierInspectionLocation = fss(ofArray([DisplayClasses_DisplayClass__get_grid(Display), Custom("grid-template-columns", "270px 270px 270px 270px ")]));

export const TableHeaderCell = fss(ofArray([DisplayClasses_DisplayClass__get_grid(Display), GridClasses_GridGap__value_Z498FEB3B(GridGap, Var.Spacing.M), Custom("grid-template-rows", "48px auto")]));

