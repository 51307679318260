import { useForm, useFormValidator, FormValidator$1_map, useFormField } from "../CustomHooks/ComposedForm.fs.js";
import { NonEmptyString, Address, EmptyString_get_Create, NonEmptyString_get_Create } from "../shared/Domain.fs.js";
import { join, isNullOrEmpty, printf, toConsole } from "../partnerportal/src/fable_modules/fable-library.3.7.17/String.js";
import { TextFieldDisabled } from "./DesignSystem.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Boldness, Padding, TextSize, MarginTop } from "../Styles/Utils.fs.js";
import { createObj } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Util.js";
import { Interop_reactApi } from "../partnerportal/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { ofArray } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";

export function useAddressForm(streetDefault, numberDefault, busDefault, postalCodeDefault, cityDefault) {
    const street = useFormField(streetDefault, NonEmptyString_get_Create());
    const number = useFormField(numberDefault, NonEmptyString_get_Create());
    const bus = useFormField(busDefault, EmptyString_get_Create());
    const postalCode = useFormField(postalCodeDefault, NonEmptyString_get_Create());
    const city = useFormField(cityDefault, NonEmptyString_get_Create());
    return {
        BusField: bus,
        City: city,
        NumberField: number,
        PostalCodeField: postalCode,
        StreetField: street,
        Validator: FormValidator$1_map((x_5) => {
            toConsole(printf("Bus: %A"))(x_5.Bus);
            return new Address(x_5.Street, x_5.Number, x_5.Bus, x_5.PostalCode, x_5.City);
        }, useFormValidator(city.Validator, (r_4, x_4) => ({
            Bus: r_4.Bus,
            City: x_4,
            Number: r_4.Number,
            PostalCode: r_4.PostalCode,
            Street: r_4.Street,
        }), useFormValidator(postalCode.Validator, (r_3, x_3) => ({
            Bus: r_3.Bus,
            Number: r_3.Number,
            PostalCode: x_3,
            Street: r_3.Street,
        }), useFormValidator(bus.Validator, (r_2, x_2) => ({
            Bus: isNullOrEmpty(x_2.Value) ? (void 0) : (new NonEmptyString(x_2.Value)),
            Number: r_2.Number,
            Street: r_2.Street,
        }), useFormValidator(number.Validator, (r_1, x_1) => ({
            Number: x_1,
            Street: r_1.Street,
        }), useFormValidator(street.Validator, (r, x) => ({
            Street: x,
        }), useForm())))))),
    };
}

export function addressFields(addressForm, disabled) {
    const xs = [TextFieldDisabled(addressForm.StreetField, "Straat", "", true, disabled), TextFieldDisabled(addressForm.NumberField, "Nummer", "", true, disabled), TextFieldDisabled(addressForm.BusField, "Bus(optioneel)", "", false, disabled), TextFieldDisabled(addressForm.PostalCodeField, "Postcode", "", true, disabled), TextFieldDisabled(addressForm.City, "Gemeente", "", true, disabled)];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function CreateAddressForm(createAddressFormInputProps) {
    const disabled = createAddressFormInputProps.disabled;
    const heading = createAddressFormInputProps.heading;
    const addressForm = createAddressFormInputProps.addressForm;
    const xs_1 = [createElement("div", {
        className: join(" ", [MarginTop.M, TextSize.M, Padding.Xxs]),
        children: (heading == null) ? "Adres" : heading,
    }), addressFields(addressForm, disabled)];
    return react.createElement(react.Fragment, {}, ...xs_1);
}

export function CreateAddressFormSmallHeader(createAddressFormSmallHeaderInputProps) {
    let elems;
    const disabled = createAddressFormSmallHeaderInputProps.disabled;
    const heading = createAddressFormSmallHeaderInputProps.heading;
    const addressForm = createAddressFormSmallHeaderInputProps.addressForm;
    const xs_1 = [createElement("div", createObj(ofArray([["className", join(" ", [TextSize.S, MarginTop.L, Boldness.b600])], (elems = [(heading == null) ? "Adres" : heading], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), addressFields(addressForm, disabled)];
    return react.createElement(react.Fragment, {}, ...xs_1);
}

export function CreateAddressFormWithReset(createAddressFormWithResetInputProps) {
    let value;
    const isSameAddress = createAddressFormWithResetInputProps.isSameAddress;
    const heading = createAddressFormWithResetInputProps.heading;
    const resetOption = createAddressFormWithResetInputProps.resetOption;
    const addressForm = createAddressFormWithResetInputProps.addressForm;
    const xs = [(value = ((heading == null) ? "Adres" : heading), createElement("h2", {
        children: [value],
    })), resetOption, addressFields(addressForm, isSameAddress)];
    return react.createElement(react.Fragment, {}, ...xs);
}

