import { iterate } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Seq.js";
import { toArray } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Option.js";
import { addDays, date as date_1, utcNow, day, month, year, dayOfWeek } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Date.js";
import { date as date_2, create } from "../partnerportal/src/fable_modules/fable-library.3.7.17/DateOffset.js";
import { fromTicks } from "../partnerportal/src/fable_modules/fable-library.3.7.17/TimeSpan.js";
import { fromInteger } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Long.js";

export function debounce(callback, wait) {
    let timeoutId = void 0;
    return (x) => {
        iterate((arg) => {
            window.clearTimeout(arg);
        }, toArray(timeoutId));
        timeoutId = window.setTimeout(() => callback(x), wait);
    };
}

export function getDaysSinceStartOfWeek(date) {
    const matchValue = dayOfWeek(date) | 0;
    switch (matchValue) {
        case 0: {
            return 0;
        }
        case 1: {
            return 1;
        }
        case 2: {
            return 2;
        }
        case 3: {
            return 3;
        }
        case 4: {
            return 4;
        }
        case 5: {
            return 5;
        }
        case 6: {
            return 6;
        }
        default: {
            return 0;
        }
    }
}

export function getDayStart(date) {
    return create(year(date), month(date), day(date), 0, 0, 0, fromTicks(fromInteger(0, false, 2)));
}

export function getDayEnd(date) {
    return create(year(date), month(date), day(date), 23, 59, 59, fromTicks(fromInteger(0, false, 2)));
}

export const weekStartDate = getDayStart((() => {
    let copyOfStruct_2;
    let copyOfStruct_1;
    let copyOfStruct = utcNow();
    copyOfStruct_1 = date_1(copyOfStruct);
    return addDays(copyOfStruct_1, 0 - getDaysSinceStartOfWeek((copyOfStruct_2 = utcNow(), date_1(copyOfStruct_2))));
})());

export const weekEndDate = getDayEnd((() => {
    let copyOfStruct_1;
    let copyOfStruct = weekStartDate;
    copyOfStruct_1 = date_2(copyOfStruct);
    return addDays(copyOfStruct_1, 6);
})());

export function SafeNotNullCheck(selectOption, callback) {
    if (!(selectOption == null)) {
        callback(selectOption);
    }
}

