import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { createElement } from "react";
import * as react from "react";
import { curry, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";
import { MarginRight, cancelDossierButton as cancelDossierButton_1, MarginLeft, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../../../Styles/Utils.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import Button from "@material-ui/core/Button";
import { empty, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { CommentType, DossierCode } from "../../../shared/Domain.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useSnackbar } from "notistack";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { downloadFile } from "../DownloadFile.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import Close from "@material-ui/icons/Close";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossiers, customerDetail, dossierEdit } from "../AppRoutes.fs.js";
import Edit from "@material-ui/icons/Edit";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Auth0PartnerUserPrivileges, Auth0PartnerUserClaims__HasPrivilege_567ADD95 } from "../../../shared/Auth0Constants.fs.js";
import { Page } from "../components/Page.fs.js";
import { DisplayDossierInfoSyndic } from "../../../DesignSystems/dossier/DossierDetailsSyndic.fs.js";
import { DisplayDossierInfo } from "../../../DesignSystems/dossier/DossierDetails.fs.js";

export function CancellationModal(cancellationModalInputProps) {
    let elems_1;
    const isBusyCancelling = cancellationModalInputProps.isBusyCancelling;
    const onCancelDossier = cancellationModalInputProps.onCancelDossier;
    const setShowCancellationModal = cancellationModalInputProps.setShowCancellationModal;
    const showCancellationModal = cancellationModalInputProps.showCancellationModal;
    const dossierId = cancellationModalInputProps.dossierId;
    return MuiHelpers_createElement(Modal, [["open", showCancellationModal], ["onClose", (_arg_1, v) => {
        setShowCancellationModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_1 = [createElement("div", {
        className: join(" ", [MarginBottom.M]),
        children: Interop_reactApi.Children.toArray(["Dit dossier annuleren?"]),
    }), MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        onCancelDossier(dossierId);
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"], ["disabled", isBusyCancelling]]), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        setShowCancellationModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Nee"], ["disabled", isBusyCancelling]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function Detail(detailInputProps) {
    const auth0User = detailInputProps.auth0User;
    const dossierCode = detailInputProps.dossierCode;
    const api = detailInputProps.api;
    const currentPage = detailInputProps.currentPage;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossierDetails(new DossierCode(dossierCode)), []);
    const patternInput = useFeliz_React__React_useState_Static_1505(empty());
    const comments = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new CommentType(1));
    const setCommentType = patternInput_1[1];
    const commentType = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const samplesUploaded = patternInput_2[0];
    const snackbar = useSnackbar();
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setShowCancellationModal = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setIsBusyCancelling = patternInput_4[1];
    const dossierCode_1 = new DossierCode(dossierCode);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const quoteDetails = patternInput_5[0];
    const download = (filePath) => singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.GetDocument(filePath), (_arg_4) => {
        const file = _arg_4;
        if (file.tag === 1) {
            const err_2 = file.fields[0];
            console.error(err_2);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_2}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        }
        else {
            return singleton.Bind(downloadFile(file.fields[0], filePath), () => singleton.Return());
        }
    })), (_arg_6) => {
        const ex_1 = _arg_6;
        console.error(ex_1);
        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${ex_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
        return singleton.Zero();
    }));
    const getComments = () => singleton.Delay(() => singleton.Bind(api.GetDossierComments(dossierCode_1, new PagingQuery(0, 2147483647), commentType), (_arg_7) => {
        const comments_1 = _arg_7;
        if (comments_1.tag === 0) {
            patternInput[1](comments_1.fields[0].Data);
            return singleton.Zero();
        }
        else {
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        }
    }));
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.GetSamplesUploaded(dossierCode_1), (_arg_2) => {
            const result_1 = _arg_2;
            if (result_1.tag === 1) {
                const err_1 = result_1.fields[0];
                console.error(err_1);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                patternInput_2[1](result_1.fields[0]);
                return singleton.Zero();
            }
        })), (_arg_3) => {
            const ex = _arg_3;
            console.error(ex);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${ex.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        })));
    }, []);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(getComments());
    }, [commentType]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(api.GetQuoteDetailsByDossierCode(dossierCode_1), (_arg_8) => {
            const quteDetails = _arg_8;
            if (quteDetails.tag === 0) {
                patternInput_5[1](quteDetails.fields[0]);
                return singleton.Zero();
            }
            else {
                return singleton.Zero();
            }
        })));
    }, []);
    const onSaveComments = (comment, onSaveComment) => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.CreateDossierComment(dossierCode_1, comment), (_arg_9) => {
            const result_3 = _arg_9;
            if (result_3.tag === 0) {
                onSaveComment();
                startImmediate(getComments());
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Commet saved!", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                onSaveComment();
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${result_3.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
        })), (_arg_10) => {
            const e_1 = _arg_10;
            console.error(e_1);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        })));
    };
    return createElement(DisplayDeferred, {
        data: data,
        view: (dossierDetails) => {
            let props, props_2, xs_2;
            if (dossierDetails == null) {
                return null;
            }
            else {
                const dossierDetail = dossierDetails;
                let cancelDossierButton;
                let isCancelDisabled;
                const matchValue = dossierDetail.Dossier.Status;
                switch (matchValue.tag) {
                    case 1:
                    case 5:
                    case 6:
                    case 7: {
                        isCancelDisabled = true;
                        break;
                    }
                    default: {
                        isCancelDisabled = false;
                    }
                }
                cancelDossierButton = MuiHelpers_createElement(Button, [["onClick", (_arg_11) => {
                    setShowCancellationModal(true);
                }], ["className", join(" ", [MarginLeft.Xs, cancelDossierButton_1])], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Close, props))], ["children", "Dossier Annuleren"], ["disabled", isCancelDisabled]]);
                const editDossierButton = MuiHelpers_createElement(Button, [["onClick", (_arg_12) => {
                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierEdit, dossierCode_1.Value)), 1, 1);
                }], ["className", join(" ", [MarginRight.M])], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_2 = {}, react.createElement(Edit, props_2))], ["children", "Dossier bewerken"]]);
                const availableButtons = toList(delay(() => {
                    if (!Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(1))) {
                        return append(singleton_1(editDossierButton), delay(() => singleton_1(cancelDossierButton)));
                    }
                    else {
                        return empty_1();
                    }
                }));
                return createElement(Page, {
                    currentPage: currentPage,
                    title: "Dossier detail",
                    actions: availableButtons,
                    children: [(xs_2 = toList(delay(() => append(Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(1)) ? singleton_1(createElement(DisplayDossierInfoSyndic, {
                        dossierDetails: dossierDetail,
                        auth0User: void 0,
                        sampledUploaded: samplesUploaded,
                        downloadFile: (filePath_1) => {
                            startImmediate(download(filePath_1));
                        },
                        comments: comments,
                        commentType: commentType,
                        setCommentType: setCommentType,
                        customerUrl: (customerId) => Page$2__toUrl_2B594(customerDetail, customerId.Value),
                        partnerUrl: void 0,
                        appartmentUrl: void 0,
                        isPartner: true,
                        onSaveComment: curry(2, onSaveComments),
                        signatureink: void 0,
                        quoteDetails: quoteDetails,
                    })) : singleton_1(createElement(DisplayDossierInfo, {
                        dossierDetails: dossierDetail,
                        auth0User: void 0,
                        sampledUploaded: samplesUploaded,
                        downloadFile: (filePath_2) => {
                            startImmediate(download(filePath_2));
                        },
                        comments: comments,
                        commentType: commentType,
                        setCommentType: setCommentType,
                        customerUrl: (customerId_1) => Page$2__toUrl_2B594(customerDetail, customerId_1.Value),
                        partnerUrl: void 0,
                        appartmentUrl: void 0,
                        isPartner: true,
                        onSaveComment: curry(2, onSaveComments),
                        signatureink: void 0,
                        quoteDetails: quoteDetails,
                    })), delay(() => singleton_1(createElement(CancellationModal, {
                        dossierId: dossierDetail.Dossier.Id,
                        showCancellationModal: patternInput_3[0],
                        setShowCancellationModal: setShowCancellationModal,
                        onCancelDossier: (dossierId) => {
                            startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                                setIsBusyCancelling(true);
                                return singleton.Bind(api.CancelDossier(dossierId), (_arg) => {
                                    const result = _arg;
                                    setIsBusyCancelling(false);
                                    setShowCancellationModal(false);
                                    if (result.tag === 0) {
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Dossier geannuleerd", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossiers)), 1, 1);
                                        return singleton.Zero();
                                    }
                                    else {
                                        const err = result.fields[0];
                                        console.error(err);
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                        return singleton.Zero();
                                    }
                                });
                            }), (_arg_1) => {
                                const e = _arg_1;
                                console.error(e);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton.Zero();
                            })));
                        },
                        isBusyCancelling: patternInput_4[0],
                    })))))), react.createElement(react.Fragment, {}, ...xs_2))],
                });
            }
        },
    });
}

