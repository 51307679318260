import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createElement } from "react";
import * as react from "react";
import { DisplayData } from "../../../DesignSystems/DesignSystem.fs.js";
import { Row } from "../../../Components/UtilComponents.fs.js";
import { TextSize, MarginTop, center } from "../../../Styles/Utils.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.17/MapUtil.js";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { unitHelpers_CssRuleWithLength__value_Z498FEB3B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { px } from "../fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { FontSize } from "../fable_modules/Fss-lib.Core.1.0.4/css/Font.fs.js";
import Euro from "@material-ui/icons/Euro";
import DialogContent from "@material-ui/core/DialogContent";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

export function PriceListModal(priceListModalInputProps) {
    let elements_2, children_5, props, props_1, props_2, props_3, props_4, children_6;
    const onClose = priceListModalInputProps.onClose;
    return MuiHelpers_createElement(Dialog, [["open", priceListModalInputProps.isOpen], ["onClose", (_arg_1, v) => {
        onClose(false);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Prijslijst (prijzen excl. btw)"]]), (children_5 = ofArray([createElement(DisplayData, {
        data: ofArray([["studio / 1-slaapkamerappartement*", createElement(Row, {
            classes: singleton(center),
            children: ofArray([(props = keyValueList([Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, px(13))))], 1), react.createElement(Euro, props)), " 375,00"]),
        })], ["appartement*", createElement(Row, {
            classes: singleton(center),
            children: ofArray([(props_1 = keyValueList([Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, px(13))))], 1), react.createElement(Euro, props_1)), " 400,00"]),
        })], ["woonhuis (ongeacht de grootte)*", createElement(Row, {
            classes: singleton(center),
            children: ofArray([(props_2 = keyValueList([Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, px(13))))], 1), react.createElement(Euro, props_2)), " 470,00"]),
        })], ["gemeenschappelijke delen", createElement(Row, {
            classes: singleton(center),
            children: singleton("op offerte"),
        })], ["alle andere: bedrijfspanden, meergezinswoningen, handelspanden", createElement(Row, {
            classes: singleton(center),
            children: singleton("op offerte"),
        })], ["perceel grond met TCR \u003e 20m2", createElement(Row, {
            classes: singleton(center),
            children: singleton("op offerte"),
        })], ["puntstaal /mengstaal", createElement(Row, {
            classes: singleton(center),
            children: ofArray([(props_3 = keyValueList([Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, px(13))))], 1), react.createElement(Euro, props_3)), " 20,00"]),
        })], ["mengstaal leidingisolatie", createElement(Row, {
            classes: singleton(center),
            children: ofArray([(props_4 = keyValueList([Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, px(13))))], 1), react.createElement(Euro, props_4)), " 20,00"]),
        })]]),
    }), createElement(Row, {
        classes: ofArray([MarginTop.M, TextSize.Xxs]),
        children: singleton("*inclusief 2 verplichte staalnames van de pleisterwerken en OVAM retributie"),
    }), createElement(Row, {
        classes: ofArray([MarginTop.M, TextSize.Xs]),
        children: singleton("Zonder vergoedingen (deze komen bovenop de prijzen van deze prijslijst)"),
    })]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]])), (children_6 = singleton(MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        onClose(false);
    }], ["children", "Sluiten"]])), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

