import { useSnackbar } from "notistack";
import { useReact_useMemo_CF4EA67, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { searchTerms as searchTerms_1, initialState, update } from "../../../DesignSystems/dossier/DossierFilter.fs.js";
import { useDebouncedValue } from "../../../CustomHooks/CustomHooks.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail, dossierCreate } from "../AppRoutes.fs.js";
import { createElement } from "react";
import * as react from "react";
import Add from "@material-ui/icons/Add";
import { Page } from "../components/Page.fs.js";
import { DossiersData } from "../../../DesignSystems/dossier/DossierDetails.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { downloadFile_CSV } from "../DownloadFile.fs.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";

export function List(listInputProps) {
    let props, children_1;
    const api = listInputProps.api;
    const currentPage = listInputProps.currentPage;
    const snackbar = useSnackbar();
    const patternInput = useReact_useReducer_2B9E6EA0(update, initialState);
    const state_1 = patternInput[0];
    const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => searchTerms_1(state_1), [state_1]));
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossiers(new PagingQuery(state_1.PageNumber, state_1.PageSize), search), [state_1.PageNumber, state_1.PageSize, search]);
    const addNewDossierButton = MuiHelpers_createElement(Button, [["onClick", (_arg_1) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierCreate)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Add, props))], ["children", "Dossier aanmaken"]]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Dossiers",
        actions: [],
        children: [(children_1 = singleton_1(createElement(DossiersData, {
            data: data,
            state: state_1,
            dispatch: patternInput[1],
            url: (dossierId) => Page$2__toUrl_2B594(dossierDetail, dossierId.Value),
            showPartnerName: false,
            optionalExportCsvFunction: (query) => ((searchTerms) => {
                startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                    startImmediate(downloadFile_CSV(api, query, searchTerms));
                    return singleton.Zero();
                }), (_arg_2) => {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${_arg_2.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                })));
            }),
            showCibFilter: false,
            isBackOffice: false,
            klantNameHeader: "Syndic Naam",
        })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))],
    });
}

