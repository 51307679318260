import { useSnackbar } from "notistack";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../partnerportal/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { value as value_3, some } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Option.js";
import { createElement } from "react";
import { Progress } from "./DesignSystem.fs.js";
import { empty } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../Notistack/Notistack.fs.js";

export const defaultErrorMessage = "Een onbekende fout is opgetreden. Gelieve support te contacteren.";

export function DisplayDeferred(displayDeferredInputProps) {
    const view = displayDeferredInputProps.view;
    const data = displayDeferredInputProps.data;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const cached = patternInput[0];
    const value_1 = useReact_useEffect_Z101E1A95(() => {
        let pattern_matching_result, value;
        if (data.tag === 2) {
            if (data.fields[0].tag === 0) {
                pattern_matching_result = 0;
                value = data.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                patternInput[1](some(value));
                break;
            }
            case 1: {
                break;
            }
        }
    }, [data]);
    if (data.tag === 1) {
        if (cached != null) {
            return view(value_3(cached));
        }
        else {
            return createElement(Progress, {
                classes: empty(),
            });
        }
    }
    else if (data.tag === 2) {
        if (data.fields[0].tag === 1) {
            console.log(data.fields[0].fields[0]);
            return ProviderContext__enqueueSnackbar_Z1776A768(snackbar, data.fields[0].fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
        }
        else {
            return view(data.fields[0].fields[0]);
        }
    }
    else if (data.tag === 3) {
        console.log(data.fields[0]);
        return ProviderContext__enqueueSnackbar_Z1776A768(snackbar, defaultErrorMessage, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
    }
    else {
        return null;
    }
}

