import { useForm, useFormValidator, useFormField } from "../../CustomHooks/ComposedForm.fs.js";
import { PartnerType, NonEmptyString___UnsafeUnwrap_Z73AD07C, AdiRoleModule_string, AdiRole, SelectOptionData$1_Create_Z510A3DC0, VatNumber_get_Create, PhoneNumber_get_Create, Email_get_Create, NonEmptyString_get_Create } from "../../shared/Domain.fs.js";
import { toArray, some } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Option.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../partnerportal/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { useToggle } from "../../CustomHooks/CustomHooks.fs.js";
import { empty } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Set.js";
import { compare } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Util.js";
import { CreateAddressFormSmallHeader, useAddressForm } from "../AddressHook.fs.js";
import { createElement } from "react";
import { Row, Col } from "../../Components/UtilComponents.fs.js";
import { MarginTop, Gap } from "../../Styles/Utils.fs.js";
import { ofArray, singleton } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import { iterate, collect, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Seq.js";
import { VmeSocialToggles } from "../Contacts.fs.js";
import { OptionField, EmailField, TextField } from "../DesignSystem.fs.js";
import { MuiHelpers_createElement } from "../../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Interop_reactApi } from "../../partnerportal/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Browser_Types_Event__Event_get_Checked } from "../../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { DisplayDeferred } from "../DisplayDeferred.fs.js";
import { AdiCheckbox } from "./PartnerUtils.fs.js";
import Button from "@material-ui/core/Button";
import { PartnerData } from "../../shared/Api.fs.js";

export function CreatePartnerForm(createPartnerFormInputProps) {
    let f1, f1_1, f1_2, f1_3;
    const partnerType = createPartnerFormInputProps.partnerType;
    const data = createPartnerFormInputProps.data;
    const onCompleted = createPartnerFormInputProps.onCompleted;
    const showCibPartner = createPartnerFormInputProps.showCibPartner;
    const isCibPartner = createPartnerFormInputProps.isCibPartner;
    const saveButtonText = createPartnerFormInputProps.saveButtonText;
    const showActionButtons = createPartnerFormInputProps.showActionButtons;
    const onCreatePartner = createPartnerFormInputProps.onCreatePartner;
    const name = useFormField("", NonEmptyString_get_Create());
    const companyType = useFormField("", (f1 = NonEmptyString_get_Create(), (arg_1) => some(f1(arg_1))));
    const email = useFormField("", Email_get_Create());
    const phoneNumber = useFormField("", PhoneNumber_get_Create());
    const contactFirstName = useFormField("", (f1_1 = NonEmptyString_get_Create(), (arg_3) => some(f1_1(arg_3))));
    const contactLastName = useFormField("", (f1_2 = NonEmptyString_get_Create(), (arg_5) => some(f1_2(arg_5))));
    const vatNumber = useFormField("", VatNumber_get_Create());
    const patternInput = useFeliz_React__React_useState_Static_1505(isCibPartner);
    const isCibPartner_1 = patternInput[0];
    const isVme = useToggle(false);
    const isSocialHousingCompany = useToggle(false);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty({
        Compare: compare,
    }));
    const adiValues = patternInput_1[0];
    const address = useAddressForm("", "", "", "", "");
    const minimumRole = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const commericlaName = useFormField("", (f1_3 = NonEmptyString_get_Create(), (arg_8) => some(f1_3(arg_8))));
    const form = useFormValidator(commericlaName.Validator, (r_8, x_10) => ({
        Address: r_8.Address,
        CommercialName: x_10,
        CompanyType: r_8.CompanyType,
        ContactFirstName: r_8.ContactFirstName,
        ContactLastName: r_8.ContactLastName,
        Email: r_8.Email,
        MinimumRole: r_8.MinimumRole,
        Name: r_8.Name,
        PhoneNumber: r_8.PhoneNumber,
        VatNumber: r_8.VatNumber,
    }), useFormValidator(minimumRole.Validator, (r_7, x_9) => ({
        Address: r_7.Address,
        CompanyType: r_7.CompanyType,
        ContactFirstName: r_7.ContactFirstName,
        ContactLastName: r_7.ContactLastName,
        Email: r_7.Email,
        MinimumRole: x_9,
        Name: r_7.Name,
        PhoneNumber: r_7.PhoneNumber,
        VatNumber: r_7.VatNumber,
    }), useFormValidator(vatNumber.Validator, (r_6, x_8) => ({
        Address: r_6.Address,
        CompanyType: r_6.CompanyType,
        ContactFirstName: r_6.ContactFirstName,
        ContactLastName: r_6.ContactLastName,
        Email: r_6.Email,
        Name: r_6.Name,
        PhoneNumber: r_6.PhoneNumber,
        VatNumber: x_8,
    }), useFormValidator(contactLastName.Validator, (r_5, x_7) => ({
        Address: r_5.Address,
        CompanyType: r_5.CompanyType,
        ContactFirstName: r_5.ContactFirstName,
        ContactLastName: x_7,
        Email: r_5.Email,
        Name: r_5.Name,
        PhoneNumber: r_5.PhoneNumber,
    }), useFormValidator(contactFirstName.Validator, (r_4, x_6) => ({
        Address: r_4.Address,
        CompanyType: r_4.CompanyType,
        ContactFirstName: x_6,
        Email: r_4.Email,
        Name: r_4.Name,
        PhoneNumber: r_4.PhoneNumber,
    }), useFormValidator(phoneNumber.Validator, (r_3, x_5) => ({
        Address: r_3.Address,
        CompanyType: r_3.CompanyType,
        Email: r_3.Email,
        Name: r_3.Name,
        PhoneNumber: x_5,
    }), useFormValidator(email.Validator, (r_2, x_4) => ({
        Address: r_2.Address,
        CompanyType: r_2.CompanyType,
        Email: x_4,
        Name: r_2.Name,
    }), useFormValidator(address.Validator, (r_1, x_3) => ({
        Address: x_3,
        CompanyType: r_1.CompanyType,
        Name: r_1.Name,
    }), useFormValidator(companyType.Validator, (r, x_2) => ({
        CompanyType: x_2,
        Name: r.Name,
    }), useFormValidator(name.Validator, (unitVar, x_1) => ({
        Name: x_1,
    }), useForm()))))))))));
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_2[1];
    const disable = patternInput_2[0];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: toList(delay(() => append(singleton_1(createElement(VmeSocialToggles, {
            vme: isVme,
            social: isSocialHousingCompany,
            disabled: false,
            companyTypeField: companyType,
        })), delay(() => append(singleton_1(TextField(vatNumber, "BTW", "", true)), delay(() => append(singleton_1(TextField(commericlaName, "Commercial Name", "", false)), delay(() => append(singleton_1(TextField(name, "Bedrijfsnaam", "", true)), delay(() => append(singleton_1(TextField(companyType, "Bedrijfstype", "", false)), delay(() => append(singleton_1(TextField(contactFirstName, "Contact Voornaam", "", false)), delay(() => append(singleton_1(TextField(contactLastName, "Contact Naam", "", false)), delay(() => append(singleton_1(EmailField(email, "Email adres", "", true)), delay(() => append(singleton_1(TextField(phoneNumber, "Telefoon", "", true)), delay(() => append(singleton_1(createElement(CreateAddressFormSmallHeader, {
            addressForm: address,
            heading: void 0,
            disabled: false,
        })), delay(() => append(singleton_1(createElement("h2", {
            children: ["Minimum Adi Role"],
        })), delay(() => {
            let elements;
            return append(singleton_1(MuiHelpers_createElement(RadioGroup, [(elements = [OptionField(minimumRole, new AdiRole(0), AdiRoleModule_string(new AdiRole(0)), "", true), OptionField(minimumRole, new AdiRole(1), AdiRoleModule_string(new AdiRole(1)), "", true), OptionField(minimumRole, new AdiRole(2), AdiRoleModule_string(new AdiRole(2)), "", true), OptionField(minimumRole, new AdiRole(3), AdiRoleModule_string(new AdiRole(3)), "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => append(showCibPartner ? singleton_1(createElement(Row, {
                classes: singleton(MarginTop.M),
                children: singleton(MuiHelpers_createElement(FormControlLabel, [["label", "CIB Partner"], ["control", MuiHelpers_createElement(Switch, [["checked", isCibPartner_1]])], ["onChange", (e) => {
                    patternInput[1](Browser_Types_Event__Event_get_Checked(e));
                }]])),
            })) : empty_1(), delay(() => append(singleton_1(createElement("h2", {
                children: ["ADIs (Partner)"],
            })), delay(() => {
                let matchValue, data_3;
                return append((matchValue = data, (matchValue == null) ? singleton_1(null) : ((data_3 = matchValue, singleton_1(createElement(DisplayDeferred, {
                    data: data_3,
                    view: (adis) => {
                        let elements_1;
                        return MuiHelpers_createElement(RadioGroup, [["row", false], (elements_1 = toList(delay(() => collect((adi) => {
                            const adiName = `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName)}`;
                            return singleton_1(createElement(AdiCheckbox, {
                                label: adiName,
                                adiValue: adi,
                                values: adiValues,
                                setAdis: patternInput_1[1],
                                disabled: false,
                            }));
                        }, adis.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]);
                    },
                }))))), delay(() => singleton_1(createElement(Row, {
                    classes: ofArray([Gap.M, MarginTop.L]),
                    children: toList(delay(() => append(showActionButtons ? singleton_1(MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                        onCompleted();
                    }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]])) : empty_1(), delay(() => singleton_1(MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                        iterate((data_1) => {
                            console.log(data_1);
                            setDisable(true);
                            onCreatePartner(new PartnerData(data_1.Name, data_1.CompanyType, data_1.ContactFirstName, data_1.ContactLastName, data_1.Address, data_1.Email, data_1.PhoneNumber, data_1.VatNumber, isCibPartner_1, isVme.State, isSocialHousingCompany.State, adiValues, data_1.MinimumRole.Value, partnerType, data_1.CommercialName))(() => {
                                setDisable(false);
                            });
                        }, toArray(form.Validate()));
                    }], ["children", Interop_reactApi.Children.toArray([saveButtonText])]])))))),
                }))));
            }))))));
        })))))))))))))))))))))))),
    });
}

export function CreateSyndicForm(createSyndicFormInputProps) {
    let f1, f1_1, f1_2, f1_3;
    const data = createSyndicFormInputProps.data;
    const onCompleted = createSyndicFormInputProps.onCompleted;
    const showCibPartner = createSyndicFormInputProps.showCibPartner;
    const isCibPartner = createSyndicFormInputProps.isCibPartner;
    const saveButtonText = createSyndicFormInputProps.saveButtonText;
    const showActionButtons = createSyndicFormInputProps.showActionButtons;
    const onCreatePartner = createSyndicFormInputProps.onCreatePartner;
    const name = useFormField("", NonEmptyString_get_Create());
    const companyType = useFormField("", (f1 = NonEmptyString_get_Create(), (arg_1) => some(f1(arg_1))));
    const email = useFormField("", Email_get_Create());
    const phoneNumber = useFormField("", PhoneNumber_get_Create());
    const contactFirstName = useFormField("", (f1_1 = NonEmptyString_get_Create(), (arg_3) => some(f1_1(arg_3))));
    const contactLastName = useFormField("", (f1_2 = NonEmptyString_get_Create(), (arg_5) => some(f1_2(arg_5))));
    const vatNumber = useFormField("", VatNumber_get_Create());
    const patternInput = useFeliz_React__React_useState_Static_1505(isCibPartner);
    const isVme = useToggle(false);
    const isSocialHousingCompany = useToggle(false);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty({
        Compare: compare,
    }));
    const address = useAddressForm("", "", "", "", "");
    const commericlaName = useFormField("", (f1_3 = NonEmptyString_get_Create(), (arg_7) => some(f1_3(arg_7))));
    const form = useFormValidator(commericlaName.Validator, (r_7, x_9) => ({
        Address: r_7.Address,
        CommercialName: x_9,
        CompanyType: r_7.CompanyType,
        ContactFirstName: r_7.ContactFirstName,
        ContactLastName: r_7.ContactLastName,
        Email: r_7.Email,
        Name: r_7.Name,
        PhoneNumber: r_7.PhoneNumber,
        VatNumber: r_7.VatNumber,
    }), useFormValidator(vatNumber.Validator, (r_6, x_8) => ({
        Address: r_6.Address,
        CompanyType: r_6.CompanyType,
        ContactFirstName: r_6.ContactFirstName,
        ContactLastName: r_6.ContactLastName,
        Email: r_6.Email,
        Name: r_6.Name,
        PhoneNumber: r_6.PhoneNumber,
        VatNumber: x_8,
    }), useFormValidator(contactLastName.Validator, (r_5, x_7) => ({
        Address: r_5.Address,
        CompanyType: r_5.CompanyType,
        ContactFirstName: r_5.ContactFirstName,
        ContactLastName: x_7,
        Email: r_5.Email,
        Name: r_5.Name,
        PhoneNumber: r_5.PhoneNumber,
    }), useFormValidator(contactFirstName.Validator, (r_4, x_6) => ({
        Address: r_4.Address,
        CompanyType: r_4.CompanyType,
        ContactFirstName: x_6,
        Email: r_4.Email,
        Name: r_4.Name,
        PhoneNumber: r_4.PhoneNumber,
    }), useFormValidator(phoneNumber.Validator, (r_3, x_5) => ({
        Address: r_3.Address,
        CompanyType: r_3.CompanyType,
        Email: r_3.Email,
        Name: r_3.Name,
        PhoneNumber: x_5,
    }), useFormValidator(email.Validator, (r_2, x_4) => ({
        Address: r_2.Address,
        CompanyType: r_2.CompanyType,
        Email: x_4,
        Name: r_2.Name,
    }), useFormValidator(address.Validator, (r_1, x_3) => ({
        Address: x_3,
        CompanyType: r_1.CompanyType,
        Name: r_1.Name,
    }), useFormValidator(companyType.Validator, (r, x_2) => ({
        CompanyType: x_2,
        Name: r.Name,
    }), useFormValidator(name.Validator, (unitVar, x_1) => ({
        Name: x_1,
    }), useForm())))))))));
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_2[1];
    const disable = patternInput_2[0];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: ofArray([createElement(VmeSocialToggles, {
            vme: isVme,
            social: isSocialHousingCompany,
            disabled: false,
            companyTypeField: companyType,
        }), TextField(vatNumber, "BTW", "", true), TextField(commericlaName, "Commercial Name", "", false), TextField(name, "Bedrijfsnaam", "", true), TextField(companyType, "Bedrijfstype", "", false), TextField(contactFirstName, "Contact Voornaam", "", false), TextField(contactLastName, "Contact Naam", "", false), EmailField(email, "Email adres", "", true), TextField(phoneNumber, "Telefoon", "", true), createElement(CreateAddressFormSmallHeader, {
            addressForm: address,
            heading: void 0,
            disabled: false,
        }), createElement(Row, {
            classes: ofArray([Gap.M, MarginTop.L]),
            children: toList(delay(() => append(showActionButtons ? singleton_1(MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                onCompleted();
            }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]])) : empty_1(), delay(() => singleton_1(MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                iterate((data_1) => {
                    console.log(data_1);
                    setDisable(true);
                    onCreatePartner(new PartnerData(data_1.Name, data_1.CompanyType, data_1.ContactFirstName, data_1.ContactLastName, data_1.Address, data_1.Email, data_1.PhoneNumber, data_1.VatNumber, patternInput[0], isVme.State, isSocialHousingCompany.State, patternInput_1[0], void 0, new PartnerType(1), data_1.CommercialName))(() => {
                        setDisable(false);
                    });
                }, toArray(form.Validate()));
            }], ["children", Interop_reactApi.Children.toArray([saveButtonText])]])))))),
        })]),
    });
}

