import { render } from "react-dom";
import { createElement } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { Root } from "./Root.fs.js";
import "./styles/global.scss";


render(createElement(Auth0Provider, {
    domain: "asbest-partner-portal.eu.auth0.com",
    clientId: "r7tIsc7YdvslYE3ECleGN6Bz0ESGusTp",
    redirectUri: window.location.origin,
    children: createElement(Root, null),
}), document.getElementById("feliz-app"));

