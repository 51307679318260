import { StyleImports_createMuiTheme_argsArray } from "../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/./Styles.fs.js";
import { fromFlatEntries } from "../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/./Flatten.fs.js";
import { ofArray } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";

export const main = (() => {
    const merge = [];
    return StyleImports_createMuiTheme_argsArray(fromFlatEntries(ofArray([["palette.primary.main", "#808080"], ["palette.secondary.main", "#F18333"], ["overrides.MuiButton.containedSecondary", {
        color: "#FFFFFF",
    }], ["typography.fontFamily", "\u0027Lufga Regular\u0027,sans-serif,sans-serif"]])), ...merge);
})();

