import { Union } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Types.js";
import { union_type } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Reflection.js";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import { MuiHelpers_createElement } from "../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Chip from "@material-ui/core/Chip";
import { Compensation_compensation, Quote_statusSyndic, Quote_status, Dossier_status } from "../shared/Format.fs.js";
import { singleton as singleton_1, empty, ofArray, filter, map } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import { NumberFieldDecimal, OptionFieldDisabled, OptionField } from "./DesignSystem.fs.js";
import { contains } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Set.js";
import { Decimal, Compensation, AsbestInventory } from "../shared/Domain.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Row, Col } from "../Components/UtilComponents.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Seq.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { center, fullWidth, PaddingLeft, Gap } from "../Styles/Utils.fs.js";
import { fromParts } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Decimal.js";
import { Interop_reactApi } from "../partnerportal/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export class DossierCreatePageSource extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Backoffice", "PartnerPortal", "CibForm"];
    }
}

export function DossierCreatePageSource$reflection() {
    return union_type("DossierUtils.DossierCreatePageSource", [], DossierCreatePageSource, () => [[], [], []]);
}

export function dossierStatusColor(dossierStatus) {
    switch (dossierStatus.tag) {
        case 1: {
            return amber["800"];
        }
        case 2: {
            return green["100"];
        }
        case 3: {
            return green["200"];
        }
        case 4: {
            return green["300"];
        }
        case 5: {
            return green.A100;
        }
        case 6: {
            return green.A400;
        }
        case 7: {
            return green.A700;
        }
        case 8: {
            return grey["400"];
        }
        default: {
            return grey["300"];
        }
    }
}

export function quoteStatusColor(quoteStatus) {
    if (quoteStatus.tag === 1) {
        return green["100"];
    }
    else {
        return grey["300"];
    }
}

export function DossierStatusChip(dossierStatusChipInputProps) {
    const status = dossierStatusChipInputProps.status;
    return MuiHelpers_createElement(Chip, [["style", {
        backgroundColor: dossierStatusColor(status),
    }], ["label", Dossier_status(status)]]);
}

export function QuoteStatusChip(quoteStatusChipInputProps) {
    const status = quoteStatusChipInputProps.status;
    return MuiHelpers_createElement(Chip, [["style", {
        backgroundColor: quoteStatusColor(status),
    }], ["label", Quote_status(status)]]);
}

export function QuoteStatusChipSyndic(quoteStatusChipSyndicInputProps) {
    const status = quoteStatusChipSyndicInputProps.status;
    return MuiHelpers_createElement(Chip, [["style", {
        backgroundColor: quoteStatusColor(status),
    }], ["label", Quote_statusSyndic(status)]]);
}

export function AsbestInventoryDataOptions(formField, profile) {
    return map((tupledArg_1) => OptionField(formField, tupledArg_1[0], tupledArg_1[1], "", false), filter((tupledArg) => contains(tupledArg[0], profile.Allowed), ofArray([[new AsbestInventory(0), "Overdracht"], [new AsbestInventory(9), "Gemene delen"], [new AsbestInventory(1), "Werken"], [new AsbestInventory(2), "Actualisatie asbestinventaris-attest"], [new AsbestInventory(3), "Sloop zonder SOP"], [new AsbestInventory(4), "Sloop met SOP"], [new AsbestInventory(5), "Werkgever"], [new AsbestInventory(6), "Overdracht met vrijwillig, aanvullend onderzoek"], [new AsbestInventory(7), "Generiek"], [new AsbestInventory(8), "Generiek met vrijwillig, aanvullend onderzoek"]])));
}

export class Step extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Create", "Contacts", "Review"];
    }
}

export function Step$reflection() {
    return union_type("DossierUtils.Step", [], Step, () => [[], [], []]);
}

export function stepString(step) {
    switch (step.tag) {
        case 1: {
            return "Communicatie en facturatie";
        }
        case 2: {
            return "Overzicht";
        }
        default: {
            return "aanmaken";
        }
    }
}

export const steps = ofArray([new Step(0), new Step(1), new Step(2)]);

export class QuoteStep extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["QuoteCreate", "QuoteContacts", "QuoteDocuments", "QuoteReview"];
    }
}

export function QuoteStep$reflection() {
    return union_type("DossierUtils.QuoteStep", [], QuoteStep, () => [[], [], [], []]);
}

export function quoteStepString(step) {
    switch (step.tag) {
        case 1: {
            return "Communicatie en facturatie";
        }
        case 2: {
            return "Documenten";
        }
        case 3: {
            return "Overzicht";
        }
        default: {
            return "aanmaken";
        }
    }
}

export const quoteSteps = ofArray([new QuoteStep(0), new QuoteStep(1), new QuoteStep(2), new QuoteStep(3)]);

export function CompensationOptions(compensationOptionsInputProps) {
    const showHeader = compensationOptionsInputProps.showHeader;
    const compensationCustom = compensationOptionsInputProps.compensationCustom;
    const compensationData = compensationOptionsInputProps.compensationData;
    const xs = [createElement(Col, {
        classes: empty(),
        children: toList(delay(() => append(showHeader ? singleton(createElement("h2", {
            children: ["Vergoedingen"],
        })) : empty_1(), delay(() => {
            let elements;
            return singleton(MuiHelpers_createElement(RadioGroup, [["row", false], (elements = [createElement(Row, {
                classes: singleton_1(Gap.L),
                children: ofArray([createElement(Col, {
                    classes: empty(),
                    children: ofArray([OptionFieldDisabled(compensationData, new Compensation(0), Compensation_compensation(new Compensation(0)), "", false, false), OptionFieldDisabled(compensationData, new Compensation(1), Compensation_compensation(new Compensation(1)), "", false, false), OptionFieldDisabled(compensationData, new Compensation(2), `${Compensation_compensation(new Compensation(2))} (Standaard vergoeding)`, "", false, false), OptionFieldDisabled(compensationData, new Compensation(3), Compensation_compensation(new Compensation(3)), "", false, false), OptionFieldDisabled(compensationData, new Compensation(4), Compensation_compensation(new Compensation(4)), "", false, false), OptionFieldDisabled(compensationData, new Compensation(5), Compensation_compensation(new Compensation(5)), "", false, false)]),
                }), createElement(Col, {
                    classes: empty(),
                    children: toList(delay(() => append(singleton(OptionFieldDisabled(compensationData, new Compensation(6), Compensation_compensation(new Compensation(6)), "", false, false)), delay(() => append(singleton(OptionFieldDisabled(compensationData, new Compensation(7), Compensation_compensation(new Compensation(7)), "", false, false)), delay(() => append(singleton(OptionFieldDisabled(compensationData, new Compensation(8), Compensation_compensation(new Compensation(8)), "", false, false)), delay(() => append(singleton(OptionFieldDisabled(compensationData, new Compensation(9), Compensation_compensation(new Compensation(9)), "", false, false)), delay(() => append(singleton(OptionFieldDisabled(compensationData, new Compensation(10), Compensation_compensation(new Compensation(10)), "", false, false)), delay(() => {
                        let matchValue, v;
                        return append(singleton(OptionFieldDisabled(compensationData, (matchValue = compensationData.FieldValue, (matchValue != null) ? ((matchValue.tag === 11) ? ((v = matchValue.fields[0], new Compensation(11, v))) : (new Compensation(11, new Decimal(fromParts(0, 0, 0, false, 0))))) : (new Compensation(11, new Decimal(fromParts(0, 0, 0, false, 0))))), "Andere", "", false, false)), delay(() => {
                            let matchValue_1;
                            return (!((matchValue_1 = compensationData.FieldValue, (matchValue_1 != null) ? (!(matchValue_1.tag === 11)) : true))) ? singleton(createElement(Col, {
                                classes: singleton_1(PaddingLeft.L),
                                children: singleton_1(NumberFieldDecimal(compensationCustom, "", "", true)),
                            })) : empty_1();
                        }));
                    })))))))))))),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]));
        })))),
    })];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function noContentMessage(colSpan, msg) {
    let elems_1, elems;
    const children = singleton_1(MuiHelpers_createElement(TableRow, [(elems_1 = [MuiHelpers_createElement(TableCell, [["colSpan", colSpan], (elems = [createElement(Row, {
        classes: empty(),
        children: singleton_1(createElement(Col, {
            classes: ofArray([fullWidth, center]),
            children: singleton_1(createElement("h3", {
                children: [msg],
            })),
        })),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]));
    return MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

