import { fromDateTimeOffset, toString } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Date.js";
import { format } from "../partnerportal/src/fable_modules/fable-library.3.7.17/String.js";
import { AppointmentStatus, NonEmptyString, InspectionLocation, DossierStatus, QuoteStatus, CommentSource, Email___UnsafeUnwrap_Z27D11339, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "./Domain.fs.js";
import { fromParts } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Decimal.js";

export function GeneralFormats_dateTimeStamp(dateAndTime) {
    return toString(dateAndTime, "dd/MM/yyyy HH:mm");
}

export function GeneralFormats_dateTimeStampShort(dateAndTime) {
    return toString(dateAndTime, "dd/MM/yyyy");
}

export function GeneralFormats_id(id) {
    return format('{0:' + "0000000" + '}', id);
}

export function GeneralFormats_dateTimeOffset(d) {
    return toString(d, "yyyy-MM-dd");
}

export function GeneralFormats_dateTime(d) {
    return toString(d, "yyyy-MM-dd");
}

export function Name_name(firstName, lastName) {
    return `${NonEmptyString___UnsafeUnwrap_Z73AD07C(firstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(lastName)}`;
}

export function Customer_name(customer) {
    const matchValue = [customer.FirstName, customer.LastName, customer.CompanyName];
    let pattern_matching_result, firstName, lastName, companyName;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            firstName = matchValue[0];
            lastName = matchValue[1];
        }
        else if (matchValue[2] != null) {
            pattern_matching_result = 1;
            companyName = matchValue[2];
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue[2] != null) {
        pattern_matching_result = 1;
        companyName = matchValue[2];
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            return `${NonEmptyString___UnsafeUnwrap_Z73AD07C(firstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(lastName)}`;
        }
        case 1: {
            return NonEmptyString___UnsafeUnwrap_Z73AD07C(companyName);
        }
        case 2: {
            return "";
        }
    }
}

export function Customer_id(customer) {
    return `${customer.Id.Value}`;
}

export function Customer2_name(customer) {
    if (customer.tag === 1) {
        return NonEmptyString___UnsafeUnwrap_Z73AD07C(customer.fields[0].CompanyName);
    }
    else {
        const customer_1 = customer.fields[0];
        return `${NonEmptyString___UnsafeUnwrap_Z73AD07C(customer_1.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(customer_1.LastName)}`;
    }
}

export function Customer2_id(customer) {
    return `${customer.Id.Value}`;
}

export function Adi_name(adi) {
    return `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName)}`;
}

export function Adi_id(adi) {
    return `${adi.Id.Value}`;
}

export function Address_address(address) {
    let bus_1;
    const matchValue = address.Bus;
    if (matchValue == null) {
        bus_1 = "";
    }
    else {
        const bus = matchValue;
        bus_1 = (`, ${NonEmptyString___UnsafeUnwrap_Z73AD07C(bus)}`);
    }
    return `${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.Street)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.Number)} ${bus_1}, ${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.PostalCode)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.City)}`;
}

export function Email_email(email) {
    return Email___UnsafeUnwrap_Z27D11339(email);
}

export function CommentSource_source(source) {
    switch (source.tag) {
        case 1: {
            return "Beheerder";
        }
        case 2: {
            return "ADI";
        }
        case 3: {
            return "Klant";
        }
        case 4: {
            return "Partner";
        }
        default: {
            return "Systeem";
        }
    }
}

export function CommentSource_parseSource(source) {
    switch (source) {
        case "Systeem": {
            return new CommentSource(0);
        }
        case "Beheerder": {
            return new CommentSource(1);
        }
        case "ADI": {
            return new CommentSource(2);
        }
        case "Klant": {
            return new CommentSource(3);
        }
        case "Partner": {
            return new CommentSource(4);
        }
        default: {
            return void 0;
        }
    }
}

export function Quote_status(status) {
    if (status.tag === 1) {
        return "Omgezet";
    }
    else {
        return "Niet Omgezet";
    }
}

export function Quote_statusSyndic(status) {
    if (status.tag === 1) {
        return "Omgezet";
    }
    else {
        return "Voorstel";
    }
}

export function Quote_parseStatus(status) {
    switch (status) {
        case "Niet Omgezet": {
            return new QuoteStatus(0);
        }
        case "Omgezet": {
            return new QuoteStatus(1);
        }
        default: {
            return void 0;
        }
    }
}

export function Dossier_status(status) {
    switch (status.tag) {
        case 1: {
            return "Geannuleerd";
        }
        case 2: {
            return "Bevestigd";
        }
        case 3: {
            return "Afspraak ingeboekt";
        }
        case 4: {
            return "Afspraak afgerond";
        }
        case 5: {
            return "Gefactureerd";
        }
        case 6: {
            return "Factuur betaald";
        }
        case 7: {
            return "Compleet";
        }
        case 8: {
            return "In behandeling";
        }
        default: {
            return "Opgestart";
        }
    }
}

export function Dossier_parseStatus(status) {
    switch (status) {
        case "Opgestart": {
            return new DossierStatus(0);
        }
        case "Geannuleerd": {
            return new DossierStatus(1);
        }
        case "Bevestigd": {
            return new DossierStatus(2);
        }
        case "Afspraak ingeboekt": {
            return new DossierStatus(3);
        }
        case "Afspraak afgerond": {
            return new DossierStatus(4);
        }
        case "Gefactureerd": {
            return new DossierStatus(5);
        }
        case "Factuur betaald": {
            return new DossierStatus(6);
        }
        case "In behandeling": {
            return new DossierStatus(8);
        }
        default: {
            return void 0;
        }
    }
}

export function Dossier_Comment_comment(comment) {
    const matchValue = comment.Name;
    if (matchValue == null) {
        return `${GeneralFormats_dateTime(fromDateTimeOffset(comment.UpdatedOn, 0))}`;
    }
    else {
        const name = matchValue;
        return `${GeneralFormats_dateTime(fromDateTimeOffset(comment.UpdatedOn, 0))} ${name.Value} (${CommentSource_source(comment.CommentSource)})`;
    }
}

export function Dossier_Events_event(event) {
    if (event.tag === 1) {
        return "Dossier gemaakt van quote";
    }
    else {
        return "Dossier gemaakt";
    }
}

export function Compensation_compensation(compensation) {
    switch (compensation.tag) {
        case 1: {
            return "€ 10";
        }
        case 2: {
            return "€ 20";
        }
        case 3: {
            return "€ 30";
        }
        case 4: {
            return "€ 40";
        }
        case 5: {
            return "€ 50";
        }
        case 6: {
            return "€ 60";
        }
        case 7: {
            return "€ 70";
        }
        case 8: {
            return "€ 80";
        }
        case 9: {
            return "€ 90";
        }
        case 10: {
            return "€ 100";
        }
        case 11: {
            return `€ ${compensation.fields[0].Value}`;
        }
        default: {
            return "Geen vergoeding";
        }
    }
}

export function Compensation_compensationToDecimal(compensation) {
    switch (compensation.tag) {
        case 1: {
            return fromParts(10, 0, 0, false, 0);
        }
        case 2: {
            return fromParts(20, 0, 0, false, 0);
        }
        case 3: {
            return fromParts(30, 0, 0, false, 0);
        }
        case 4: {
            return fromParts(40, 0, 0, false, 0);
        }
        case 5: {
            return fromParts(50, 0, 0, false, 0);
        }
        case 6: {
            return fromParts(60, 0, 0, false, 0);
        }
        case 7: {
            return fromParts(70, 0, 0, false, 0);
        }
        case 8: {
            return fromParts(80, 0, 0, false, 0);
        }
        case 9: {
            return fromParts(90, 0, 0, false, 0);
        }
        case 10: {
            return fromParts(100, 0, 0, false, 0);
        }
        case 11: {
            return compensation.fields[0].Value;
        }
        default: {
            return fromParts(0, 0, 0, false, 0);
        }
    }
}

export function InspectionLocation_type$0027(type$0027) {
    switch (type$0027.tag) {
        case 1: {
            return "Vlaams Gewest";
        }
        case 2: {
            return "Lokaal - regionaal";
        }
        case 3: {
            return "Openbaar Andere";
        }
        case 4: {
            return "Handelshuis";
        }
        case 5: {
            return "Handelsgelijkvloers";
        }
        case 6: {
            return "Horeca";
        }
        case 7: {
            return "Industrie";
        }
        case 8: {
            return "Jeugdvereniging";
        }
        case 9: {
            return "Kantoor";
        }
        case 10: {
            return "Landbouw";
        }
        case 11: {
            return "Logeerfunctie of Bed\u0026Breakfast";
        }
        case 12: {
            return "Niet-residentieel Andere";
        }
        case 13: {
            return "Woonhuis";
        }
        case 14: {
            return "Studio";
        }
        case 15: {
            return "Eenkamer appartement";
        }
        case 16: {
            return "Appartement";
        }
        case 17: {
            return "Collectief woongebouw";
        }
        case 18: {
            return "Meerdere appartement";
        }
        case 19: {
            return "Garage";
        }
        case 20: {
            return "Overdekte open staanplaats";
        }
        case 21: {
            return "Woning + Magazijn";
        }
        case 22: {
            return "Gemene delen";
        }
        case 23: {
            return "Residentieel Andere";
        }
        case 24: {
            return "Onderwijs: AGION";
        }
        case 25: {
            return "Onderwijs: GO!";
        }
        case 26: {
            return "Onderwijs: hoger onderwijs";
        }
        case 27: {
            return "Religie";
        }
        case 28: {
            return "Sport/recreatie";
        }
        case 29: {
            return "Zorgvoorziening";
        }
        case 30: {
            return "Maatschappelijk Andere";
        }
        default: {
            return "Federaal";
        }
    }
}

export function InspectionLocation_parseType(type$0027) {
    switch (type$0027) {
        case "Federaal": {
            return new InspectionLocation(0);
        }
        case "Vlaams Gewest": {
            return new InspectionLocation(1);
        }
        case "Lokaal - regionaal": {
            return new InspectionLocation(2);
        }
        case "Openbaar Andere": {
            return new InspectionLocation(3, new NonEmptyString(""));
        }
        case "Handelshuis": {
            return new InspectionLocation(4);
        }
        case "Handelsgelijkvloers": {
            return new InspectionLocation(5);
        }
        case "Horeca": {
            return new InspectionLocation(6);
        }
        case "Industrie": {
            return new InspectionLocation(7);
        }
        case "Jeugdvereniging": {
            return new InspectionLocation(8);
        }
        case "Kantoor": {
            return new InspectionLocation(9);
        }
        case "Landbouw": {
            return new InspectionLocation(10);
        }
        case "Logeerfunctie of Bed\u0026Breakfast": {
            return new InspectionLocation(11);
        }
        case "Niet-residentieel Andere": {
            return new InspectionLocation(12, new NonEmptyString(""));
        }
        case "Woonhuis": {
            return new InspectionLocation(13);
        }
        case "Studio": {
            return new InspectionLocation(14);
        }
        case "Eenkamer appartenemt": {
            return new InspectionLocation(15);
        }
        case "Appartement": {
            return new InspectionLocation(16);
        }
        case "Collectief woongebouw": {
            return new InspectionLocation(17);
        }
        case "Meerdere appartement": {
            return new InspectionLocation(18);
        }
        case "Garage": {
            return new InspectionLocation(19);
        }
        case "Overdekte open staanplaats": {
            return new InspectionLocation(20);
        }
        case "Woning + Magazijn": {
            return new InspectionLocation(21);
        }
        case "Gemene delen": {
            return new InspectionLocation(22);
        }
        case "Residentieel Andere": {
            return new InspectionLocation(23, new NonEmptyString(""));
        }
        case "Onderwijs: AGION": {
            return new InspectionLocation(24);
        }
        case "Onderwijs: GO!": {
            return new InspectionLocation(25);
        }
        case "Onderwijs: hoger onderwijs": {
            return new InspectionLocation(26);
        }
        case "Religie": {
            return new InspectionLocation(27);
        }
        case "Sport/recreatie": {
            return new InspectionLocation(28);
        }
        case "Zorgvoorziening": {
            return new InspectionLocation(29);
        }
        case "Maatschappelijk Andere": {
            return new InspectionLocation(30, new NonEmptyString(""));
        }
        default: {
            return void 0;
        }
    }
}

export function InspectionLocation_extra(extra) {
    switch (extra.tag) {
        case 1: {
            return "Staanplaats";
        }
        case 2: {
            return "Garagebox";
        }
        default: {
            return "Berging";
        }
    }
}

export function Appointment_status(status) {
    switch (status.tag) {
        case 2: {
            return "Afspraak afgerond";
        }
        case 1: {
            return "Afspraak te vervolledigen";
        }
        case 3: {
            return "Geannuleerd";
        }
        default: {
            return "Afspraak gemaakt";
        }
    }
}

export function Appointment_parseStatus(status) {
    switch (status) {
        case "Afspraak gemaakt": {
            return new AppointmentStatus(0);
        }
        case "Afspraak afgerond": {
            return new AppointmentStatus(2);
        }
        case "Afspraak te vervolledigen": {
            return new AppointmentStatus(1);
        }
        case "Geannuleerd": {
            return new AppointmentStatus(3);
        }
        default: {
            return void 0;
        }
    }
}

