import { useSnackbar } from "notistack";
import { MuiHelpers_createElement } from "../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Util.js";
import { toConsole, printf, toText, join } from "../partnerportal/src/fable_modules/fable-library.3.7.17/String.js";
import { Boldness, MarginTop, hideInPrint, cursorPointer, vCenter, MarginLeft, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../Styles/Utils.fs.js";
import { empty as empty_1, append, singleton, delay, toList } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Seq.js";
import { Interop_reactApi } from "../partnerportal/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { sortByDescending, empty, map, singleton as singleton_1, isEmpty, ofArray } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import Button from "@material-ui/core/Button";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../Notistack/Notistack.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../partnerportal/src/fable_modules/Feliz.1.68.0/React.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { Col, Row } from "../Components/UtilComponents.fs.js";
import { rgba } from "../partnerportal/src/fable_modules/Feliz.1.68.0/Colors.fs.js";
import { Dossier_Comment_comment } from "../shared/Format.fs.js";
import Delete from "@material-ui/icons/Delete";
import { compare } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Date.js";
import { intersperse } from "../partnerportal/src/fable_modules/FSharpPlus.1.4.0/Extensions/List.fs.js";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { CommentType, CommentType__get_toString, CommentSource } from "../shared/Domain.fs.js";
import { Card } from "./DesignSystem.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export function DeleteCommentModal(deleteCommentModalInputProps) {
    let elems_1, elems;
    const onDeleteComment = deleteCommentModalInputProps.onDeleteComment;
    const setShowDeletionModal = deleteCommentModalInputProps.setShowDeletionModal;
    const showDeleteModal = deleteCommentModalInputProps.showDeleteModal;
    const comment = deleteCommentModalInputProps.comment;
    const snackbar = useSnackbar();
    return MuiHelpers_createElement(Modal, [["open", showDeleteModal], ["onClose", (_arg_1, v) => {
        setShowDeletionModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = toList(delay(() => {
        if (comment == null) {
            return singleton(null);
        }
        else {
            const comment_1 = comment;
            return singleton(`Verwijder opmerking: ${comment_1.Comment}`);
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        if (comment == null) {
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Geen opmerking geselecteerd.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            console.error("Geen opmerking geselecteerd");
            setShowDeletionModal(false);
        }
        else {
            onDeleteComment(comment);
            setShowDeletionModal(false);
        }
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        setShowDeletionModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Nee"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function CommentList(commentListInputProps) {
    let children_5, children_4;
    const onDeleteComment = commentListInputProps.onDeleteComment;
    const comments = commentListInputProps.comments;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowDeletionModal = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const children_6 = ofArray([createElement(DeleteCommentModal, {
        comment: patternInput_1[0],
        showDeleteModal: patternInput[0],
        setShowDeletionModal: setShowDeletionModal,
        onDeleteComment: (comment) => {
            if (onDeleteComment == null) {
            }
            else {
                onDeleteComment(comment);
            }
        },
    }), (children_5 = singleton_1((children_4 = toList(delay(() => {
        let children, xs_4, source;
        return isEmpty(comments) ? singleton((children = singleton_1(MuiHelpers_createElement(ListItemText, [["secondary", "Geen Opmerkingen"], ["primary", ""]])), MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))) : singleton((xs_4 = ((source = map((comment_2) => {
            let elements_1;
            const xs_3 = [MuiHelpers_createElement(ListItem, [(elements_1 = [createElement(Row, {
                classes: empty(),
                children: toList(delay(() => {
                    let elems_1, value_9;
                    return append(singleton(createElement(Col, {
                        classes: empty(),
                        children: ofArray([createElement("div", {
                            children: comment_2.Comment,
                        }), createElement("div", createObj(ofArray([["style", {
                            color: rgba(0, 0, 0, 0.54),
                        }], (elems_1 = [createElement(Col, {
                            classes: empty(),
                            children: singleton_1((value_9 = Dossier_Comment_comment(comment_2), createElement("div", {
                                children: [value_9],
                            }))),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]),
                    })), delay(() => {
                        let elems_2, props_4;
                        return (onDeleteComment == null) ? singleton(null) : singleton(createElement(Row, {
                            classes: ofArray([MarginLeft.Xxl, vCenter]),
                            children: singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [cursorPointer])], (elems_2 = [(props_4 = {}, react.createElement(Delete, props_4))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]), ["onClick", (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                patternInput_1[1](comment_2);
                                setShowDeletionModal(true);
                            }]])))),
                        }));
                    }));
                })),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])];
            return react.createElement(react.Fragment, {}, ...xs_3);
        }, sortByDescending((comment_1) => comment_1.UpdatedOn, comments, {
            Compare: compare,
        })), intersperse(MuiHelpers_createElement(Divider, [["component", "li"]]), source))), react.createElement(react.Fragment, {}, ...xs_4)));
    })), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
}

export function CommentSource_toNumber(source) {
    switch (source.tag) {
        case 1: {
            return 1;
        }
        case 2: {
            return 2;
        }
        case 3: {
            return 3;
        }
        case 4: {
            return 4;
        }
        default: {
            return -1;
        }
    }
}

export function CommentSource_fromNumber(n) {
    switch (n) {
        case 1: {
            return new CommentSource(1);
        }
        case 2: {
            return new CommentSource(2);
        }
        case 3: {
            return new CommentSource(3);
        }
        case 4: {
            return new CommentSource(4);
        }
        default: {
            return void 0;
        }
    }
}

export const CommentSource_defaultCommentSources = ofArray([new CommentSource(1), new CommentSource(2), new CommentSource(3), new CommentSource(4)]);

export function CommentEditor(commentEditorInputProps) {
    let elems;
    const commentType = commentEditorInputProps.commentType;
    const setComment = commentEditorInputProps.setComment;
    const comment = commentEditorInputProps.comment;
    return createElement("div", createObj(ofArray([["className", join(" ", [hideInPrint])], (elems = [Card([createElement(Row, {
        classes: empty(),
        children: ofArray([MuiHelpers_createElement(TextField, [["label", `${CommentType__get_toString(commentType)} Opmerking`], ["placeholder", `${CommentType__get_toString(commentType)} Opmerking`], ["multiline", true], ["rows", 4], ["variant", "standard"], ["value", comment], ["onChange", (e) => {
            setComment(Browser_Types_Event__Event_get_Value(e));
        }], ["fullWidth", true]]), createElement("div", {
            className: join(" ", [MarginTop.M, Boldness.b500]),
            placeholder: "Naam",
            children: "",
            readOnly: true,
        })]),
    })])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function DossierCommentEditor(dossierCommentEditorInputProps) {
    let commentType_1, xs_1, elements_2, elements_1;
    const setCommentType = dossierCommentEditorInputProps.setCommentType;
    const commentType = dossierCommentEditorInputProps.commentType;
    const setComment = dossierCommentEditorInputProps.setComment;
    const comment = dossierCommentEditorInputProps.comment;
    const tabPanel = (index, description, comment_1, setComment_1) => {
        let elements;
        return MuiHelpers_createElement(TabPanel, [["value", index], (elements = [MuiHelpers_createElement(TextField, [["label", description], ["placeholder", description], ["multiline", true], ["rows", 4], ["variant", "standard"], ["value", comment_1], ["onChange", (e) => {
            setComment_1(Browser_Types_Event__Event_get_Value(e));
        }], ["fullWidth", true]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
    };
    const patternInput = useFeliz_React__React_useState_Static_1505((commentType_1 = commentType, (commentType_1.tag === 0) ? 1 : ((commentType_1.tag === 1) ? 2 : 0)));
    const tabValue = patternInput[0] | 0;
    return Card([(xs_1 = [MuiHelpers_createElement(TabContext, [["value", toText(printf("%i"))(tabValue)], (elements_2 = [MuiHelpers_createElement(Tabs, [["value", tabValue], ["onChange", (_arg, v) => {
        let n;
        const a = v | 0;
        toConsole(printf("tab %i"))(a);
        patternInput[1](a);
        setCommentType((n = (a | 0), (n === 0) ? (new CommentType(2)) : ((n === 1) ? (new CommentType(0)) : ((n === 2) ? (new CommentType(1)) : ((console.error("tab index error"), (() => {
            throw (new Error("tab index error"));
        })()))))));
    }], (elements_1 = [MuiHelpers_createElement(Tab, [["value", 0], ["label", "INTERNAL"]]), MuiHelpers_createElement(Tab, [["value", 1], ["label", "ADI"]]), MuiHelpers_createElement(Tab, [["value", 2], ["label", "PARTNER"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), tabPanel("0", "Opmerking Internal", comment, setComment), tabPanel("1", "Opmerking Adi", comment, setComment), tabPanel("2", "Opmerking Partner", comment, setComment)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]), createElement("div", {
        className: join(" ", [MarginTop.M, Boldness.b500]),
        placeholder: "Naam",
        children: "",
        readOnly: true,
    })], react.createElement(react.Fragment, {}, ...xs_1))]);
}

export function CommentTypeTabs(commentTypeTabsInputProps) {
    let commentType_1, elements_2, elements_1;
    const showPartner = commentTypeTabsInputProps.showPartner;
    const showAdi = commentTypeTabsInputProps.showAdi;
    const showInternal = commentTypeTabsInputProps.showInternal;
    const content = commentTypeTabsInputProps.content;
    const setCommentType = commentTypeTabsInputProps.setCommentType;
    const tabPanel = (index) => MuiHelpers_createElement(TabPanel, [["style", {
        width: 100 + "%",
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight: 0,
    }], ["value", index], ["children", Interop_reactApi.Children.toArray([content])]]);
    const patternInput = useFeliz_React__React_useState_Static_1505((commentType_1 = commentTypeTabsInputProps.commentType, (commentType_1.tag === 0) ? 1 : ((commentType_1.tag === 1) ? 2 : 0)));
    const tabValue = patternInput[0] | 0;
    const xs_1 = [createElement("h2", {
        children: ["Opmerkingen"],
    }), MuiHelpers_createElement(TabContext, [["value", toText(printf("%i"))(tabValue)], (elements_2 = [MuiHelpers_createElement(Tabs, [["value", tabValue], ["onChange", (_arg, v) => {
        let n;
        const a = v | 0;
        toConsole(printf("tab %i"))(a);
        patternInput[1](a);
        setCommentType((n = (a | 0), (n === 0) ? (new CommentType(2)) : ((n === 1) ? (new CommentType(0)) : ((n === 2) ? (new CommentType(1)) : ((console.error("tab index error"), (() => {
            throw (new Error("tab index error"));
        })()))))));
    }], (elements_1 = toList(delay(() => append(showInternal ? singleton(MuiHelpers_createElement(Tab, [["value", 0], ["label", "INTERNAL"]])) : empty_1(), delay(() => append(showAdi ? singleton(MuiHelpers_createElement(Tab, [["value", 1], ["label", "ADI"]])) : empty_1(), delay(() => (showPartner ? singleton(MuiHelpers_createElement(Tab, [["value", 2], ["label", "PARTNER"]])) : empty_1()))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), tabPanel("0"), tabPanel("1"), tabPanel("2")], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]), createElement("div", {
        className: join(" ", [MarginTop.M, Boldness.b500]),
        placeholder: "Naam",
        children: "",
        readOnly: true,
    })];
    return react.createElement(react.Fragment, {}, ...xs_1);
}

