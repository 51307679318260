import { Record, Union } from "../fable_modules/fable-library.3.7.17/Types.js";
import { record_type, string_type, union_type } from "../fable_modules/fable-library.3.7.17/Reflection.js";
import { length, item, empty, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { iterate, empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { createElement } from "react";
import { Card, CheckBox, DisplayData, Info, InfoTitle } from "../../../DesignSystems/DesignSystem.fs.js";
import { Col, Row } from "../../../Components/UtilComponents.fs.js";
import { center, appBarLogo, alignEnd, Boldness, Padding, TextColor, MarginTop, MarginBottom, spaceBetween, flexExpand, Gap } from "../../../Styles/Utils.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { FlexClasses_FlexShrinkGrow__value_5E38073B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Flex.fs.js";
import { FlexGrow } from "../fable_modules/Fss-lib.Core.1.0.4/css/Flex.fs.js";
import Divider from "@material-ui/core/Divider";
import { CreateDossierData2, CustomerCompanyData, CustomerData2, CustomerPersonData, DossierCustomerSelection, DossierCustomer, CreateDossierDataContact, InvoicePricing, Compensation_get_DefaultCompensation, PartnerType, NonEmptyString_get_Create, PhoneNumber_get_Create, SelectOptionData$1_Create_Z510A3DC0, AssignmentDocumentModule_string, HomeVisitContactModule_string, CustomerData2__get_Address, CustomerData2__get_PhoneNumber, CustomerData2__get_Email, Customer2__get_Address, Customer2__get_PhoneNumber, Customer2__get_Email, VatNumber___UnsafeUnwrap_18C04ACB, PhoneNumber___UnsafeUnwrap_23E45174, Email___UnsafeUnwrap_Z27D11339, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import { toArray, map, defaultArg } from "../fable_modules/fable-library.3.7.17/Option.js";
import { PartnerData__get_LastName, PartnerData__get_FirstName } from "../../../shared/Api.fs.js";
import { InspectionLocation_type$0027, Address_address } from "../../../shared/Format.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import List from "@material-ui/core/List";
import { printf, toConsole, join } from "../fable_modules/fable-library.3.7.17/String.js";
import Button from "@material-ui/core/Button";
import { useReact_useElementRef, useReact_useButtonRef, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useSnackbar } from "notistack";
import { useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { ChooseAssignmentDocumentContact, ChooseCertificateContact, ChooseInvoicing, ChooseContact, useCreateCustomerCompanyForm, useCreateCustomerPersonForm } from "../../../DesignSystems/Contacts.fs.js";
import { mapIndexed } from "../fable_modules/fable-library.3.7.17/Array.js";
import Stepper from "@material-ui/core/Stepper";
import Step_1 from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { CreatePartnerForm } from "../../../DesignSystems/partner/PartnerForms.fs.js";
import { CreateDossierForm } from "../../../DesignSystems/dossier/DossierCreate.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { customerDetail } from "../AppRoutes.fs.js";
import { DossierCreatePageSource } from "../../../DesignSystems/DossierUtils.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { PageCib } from "../components/Page.fs.js";
import { BkgImage } from "../../../Styles/ClientPageStyles.fs.js";
import AppBar from "@material-ui/core/AppBar";
import { CssRuleWithNone__get_none } from "../fable_modules/Fss-lib.Core.1.0.4/Types/MasterTypes.fs.js";
import { BoxShadow } from "../fable_modules/Fss-lib.Core.1.0.4/css/BoxShadow.fs.js";
import { ColorClass_Color__hex_Z721C83C5 } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Color.fs.js";
import { BorderBottomWidth, BorderBottomStyle, BorderBottomColor } from "../fable_modules/Fss-lib.Core.1.0.4/css/Border.fs.js";
import { BorderClasses_BorderStyleParent__get_solid } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Border.fs.js";
import { unitHelpers_DirectionalLength__value_Z498FEB3B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { px } from "../fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import Toolbar from "@material-ui/core/Toolbar";
import { logo } from "../Img.fs.js";
import { PriceListModal } from "../components/PriceListModal.fs.js";

export class Step extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Partner", "DossierCreate", "Contacts", "Review"];
    }
}

export function Step$reflection() {
    return union_type("PublicCibForm.Step", [], Step, () => [[], [], [], []]);
}

export function stepString(step) {
    switch (step.tag) {
        case 1: {
            return "Dossier aanmaken";
        }
        case 2: {
            return "Communicatie en facturatie";
        }
        case 3: {
            return "Overzicht";
        }
        default: {
            return "Gegevens CIB makelaar";
        }
    }
}

export const steps = ofArray([new Step(0), new Step(1), new Step(2), new Step(3)]);

export class CustomerOption extends Record {
    constructor(Label, Code) {
        super();
        this.Label = Label;
        this.Code = Code;
    }
}

export function CustomerOption$reflection() {
    return record_type("PublicCibForm.CustomerOption", [], CustomerOption, () => [["Label", string_type], ["Code", string_type]]);
}

export function CustomerOption_get_none() {
    return new CustomerOption("Geen", "0");
}

export function CustomerOption_get_create() {
    return new CustomerOption("Klant aanmaken", "-1");
}

export function Review(reviewInputProps) {
    const dossierDataContact = reviewInputProps.dossierDataContact;
    const dossierData = reviewInputProps.dossierData;
    const partner = reviewInputProps.partner;
    const children = toList(delay(() => append(singleton(createElement(InfoTitle, {
        text: "Factuur",
    })), delay(() => append(singleton(createElement(Info, {
        secondary: "Verantwoordelijkheid",
        primary: "Makelaar verzorgt de facturatie en communicatie",
    })), delay(() => {
        let elems, elems_1, elems_2, elems_3, matchValue_6, matchValue_7, matchValue_8, matchValue_9, elems_4;
        return singleton(createElement(Row, {
            classes: singleton_1(Gap.L),
            children: ofArray([MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 2))), (elems = [createElement(Col, {
                classes: empty(),
                children: ofArray([createElement(InfoTitle, {
                    text: "Gegevens CIB makelaar",
                }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                    data: ofArray([["Bedrijfsnaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.CompanyName)], ["Bedrijfstype", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, partner.CompanyType), "-")], ["Contact Voornaam", PartnerData__get_FirstName(partner)], ["Contact Naam", PartnerData__get_LastName(partner)], ["Emailadres", Email___UnsafeUnwrap_Z27D11339(partner.Email)], ["Telefoon", PhoneNumber___UnsafeUnwrap_23E45174(partner.PhoneNumber)], ["BTW", VatNumber___UnsafeUnwrap_18C04ACB(partner.VatNumber)], ["Adres", Address_address(partner.Address)]]),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 2))), (elems_1 = [createElement(Col, {
                classes: empty(),
                children: ofArray([createElement(InfoTitle, {
                    text: "Klant",
                }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                    data: toList(delay(() => {
                        let matchValue_4, person_2, company_2, matchValue_3, person_1, company_1, matchValue_1, person, company;
                        const matchValue = dossierDataContact.InvoicingParty;
                        switch (matchValue.tag) {
                            case 1: {
                                const matchValue_2 = matchValue.fields[0];
                                if (matchValue_2.tag === 0) {
                                    const customer_3 = matchValue_2.fields[0];
                                    return append((matchValue_4 = customer_3, (matchValue_4.tag === 0) ? ((person_2 = matchValue_4.fields[0], append(singleton(["Voornaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person_2.FirstName)]), delay(() => singleton(["Naam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person_2.LastName)]))))) : ((company_2 = matchValue_4.fields[0], append(singleton(["Bedrijfsnaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(company_2.CompanyName)]), delay(() => singleton(["Bedrijfstype", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company_2.CompanyType), "-")])))))), delay(() => append(singleton(["Emailadres", Email___UnsafeUnwrap_Z27D11339(Customer2__get_Email(customer_3))]), delay(() => append(singleton(["Telefoon", defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, Customer2__get_PhoneNumber(customer_3)), "-")]), delay(() => singleton(["Adres", Address_address(Customer2__get_Address(customer_3))])))))));
                                }
                                else {
                                    const customer_2 = matchValue_2.fields[0];
                                    return append((matchValue_3 = customer_2, (matchValue_3.tag === 0) ? ((person_1 = matchValue_3.fields[0], append(singleton(["Voornaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person_1.FirstName)]), delay(() => singleton(["Naam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person_1.LastName)]))))) : ((company_1 = matchValue_3.fields[0], append(singleton(["Bedrijfsnaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(company_1.CompanyName)]), delay(() => singleton(["Bedrijfstype", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company_1.CompanyType), "-")])))))), delay(() => append(singleton(["Emailadres", Email___UnsafeUnwrap_Z27D11339(CustomerData2__get_Email(customer_2))]), delay(() => append(singleton(["Telefoon", defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, CustomerData2__get_PhoneNumber(customer_2)), "-")]), delay(() => singleton(["Adres", Address_address(CustomerData2__get_Address(customer_2))])))))));
                                }
                            }
                            case 2: {
                                return empty_1();
                            }
                            default: {
                                const customer = matchValue.fields[0];
                                return append((matchValue_1 = customer, (matchValue_1.tag === 0) ? ((person = matchValue_1.fields[0], append(singleton(["Voornaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person.FirstName)]), delay(() => singleton(["Naam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person.LastName)]))))) : ((company = matchValue_1.fields[0], append(singleton(["Bedrijfsnaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(company.CompanyName)]), delay(() => singleton(["Bedrijfstype", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company.CompanyType), "-")])))))), delay(() => append(singleton(["Emailadres", Email___UnsafeUnwrap_Z27D11339(Customer2__get_Email(customer))]), delay(() => append(singleton(["Telefoon", defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, Customer2__get_PhoneNumber(customer)), "-")]), delay(() => singleton(["Adres", Address_address(Customer2__get_Address(customer))])))))));
                            }
                        }
                    })),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 2))), (elems_2 = [createElement(Col, {
                classes: empty(),
                children: ofArray([createElement(InfoTitle, {
                    text: "Eigenaar",
                }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                    data: toList(delay(() => {
                        const matchValue_5 = dossierData.DossierOwner;
                        if (matchValue_5.tag === 0) {
                            const person_3 = matchValue_5.fields[0];
                            return append(singleton(["Voornaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person_3.FirstName)]), delay(() => append(singleton(["Naam", NonEmptyString___UnsafeUnwrap_Z73AD07C(person_3.LastName)]), delay(() => append(singleton(["Emailadres", Email___UnsafeUnwrap_Z27D11339(person_3.Email)]), delay(() => append(singleton(["Telefoon", defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, person_3.PhoneNumber), "-")]), delay(() => singleton(["Adres", Address_address(person_3.Address)])))))))));
                        }
                        else {
                            const company_3 = matchValue_5.fields[0];
                            return append(singleton(["Bedrijfsnaam", NonEmptyString___UnsafeUnwrap_Z73AD07C(company_3.CompanyName)]), delay(() => append(singleton(["Bedrijfstype", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company_3.CompanyType), "-")]), delay(() => append(singleton(["Emailadres", Email___UnsafeUnwrap_Z27D11339(company_3.Email)]), delay(() => append(singleton(["Telefoon", defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, company_3.PhoneNumber), "-")]), delay(() => singleton(["Adres", Address_address(company_3.Address)])))))))));
                        }
                    })),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 3))), (elems_3 = [createElement(Col, {
                classes: empty(),
                children: ofArray([createElement(InfoTitle, {
                    text: "Inspectielocatie",
                }), MuiHelpers_createElement(Divider, []), createElement(Row, {
                    classes: empty(),
                    children: ofArray([createElement(Col, {
                        classes: singleton_1(flexExpand),
                        children: singleton_1(createElement(DisplayData, {
                            data: ofArray([["Type", InspectionLocation_type$0027(dossierData.InspectionLocation.Value)], ["Straat", NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierData.Address.Street)], ["Nummer", NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierData.Address.Number)], ["Bus", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierData.Address.Bus), "--")], ["Postcode", NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierData.Address.PostalCode)], ["Gemeente", NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierData.Address.City)]]),
                        })),
                    }), createElement(Col, {
                        classes: singleton_1(flexExpand),
                        children: singleton_1(createElement(DisplayData, {
                            data: ofArray([["Gebouwdeel/Vleugel/Blok", (matchValue_6 = dossierData.BuildingDetail, (matchValue_6 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_6))], ["Wooneenheden", (matchValue_7 = dossierData.HousingUnit, (matchValue_7 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_7))], ["Jaar van constructie", (matchValue_8 = dossierData.YearsOfConstruction, (matchValue_8 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_8))], ["Jaar van renovatie", (matchValue_9 = dossierData.YearsOfRenovation, (matchValue_9 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_9))]]),
                        })),
                    })]),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 3))), (elems_4 = [createElement(Col, {
                classes: empty(),
                children: ofArray([createElement(InfoTitle, {
                    text: "Communicatie en facturatie",
                }), MuiHelpers_createElement(Divider, []), createElement(Row, {
                    classes: empty(),
                    children: singleton_1(createElement(Col, {
                        classes: singleton_1(flexExpand),
                        children: ofArray([createElement(DisplayData, {
                            data: ofArray([["Afspraak maken voor locatie", HomeVisitContactModule_string(dossierDataContact.HomeVisitContact)], ["Bestel formulier ondertekenaars", AssignmentDocumentModule_string(dossierDataContact.AssignmentDocumentContact)]]),
                        }), MuiHelpers_createElement(Divider, []), createElement(InfoTitle, {
                            text: "Emails met asbestcertificaten",
                        }), createElement(DisplayData, {
                            data: toList(delay(() => append(singleton([partner.CompanyName.Value, CheckBox(dossierDataContact.CertificateEmailPartner)]), delay(() => append(singleton(["Klant", CheckBox(dossierDataContact.CertificateEmailClient)]), delay(() => {
                                const matchValue_10 = dossierDataContact.CertificateCustomEmails;
                                if (matchValue_10 != null) {
                                    return singleton(["Extra Emails", matchValue_10.Value]);
                                }
                                else {
                                    return empty_1();
                                }
                            })))))),
                        })]),
                    })),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])]),
        }));
    }))))));
    return MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function SuccessPage(dossierCode) {
    let elems;
    const pageTitle = createElement(Row, {
        classes: singleton_1(spaceBetween),
        children: singleton_1(createElement(Col, {
            classes: empty(),
            children: singleton_1(createElement("h1", {
                children: "Bedankt voor uw aanvraag.",
                className: join(" ", [MarginBottom.L, MarginTop.S, TextColor.Primary]),
            })),
        })),
    });
    return createElement(Col, {
        classes: empty(),
        children: singleton_1(createElement(Col, {
            classes: singleton_1(MarginBottom.L),
            children: singleton_1(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 2))), ["className", join(" ", [Padding.L])], (elems = [pageTitle, createElement(Col, {
                classes: singleton_1(MarginBottom.L),
                children: ofArray([createElement(Col, {
                    classes: singleton_1(MarginBottom.L),
                    children: ofArray([createElement("div", {
                        children: "Ze is geregistreerd. U ontvangt kortelings een mail met een klantennummer of opening van dossier.",
                    }), createElement("div", {
                        children: "Een afspraak wordt snel ingeboekt.",
                    })]),
                }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                    data: singleton_1(["Dossier nummer", dossierCode.Value]),
                })]),
            }), MuiHelpers_createElement(Button, [["variant", "contained"], ["children", "Nieuw dossier indienen"], ["onClick", (_arg) => {
                window.location.reload();
            }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])),
        })),
    });
}

export function Create(createInputProps) {
    let elements_8, elements_1, elements, elements_3, elements_2, elements_5, elements_4, elements_7, elements_6, elems_9, elems_10, value_32, elems_11, elems_12, elems_17, elements_9;
    const code = createInputProps.code;
    const api = createInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const setActiveStep = patternInput[1];
    const activeStep = patternInput[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([false, false, false, false]);
    const stepsCompleted = patternInput_1[0];
    const setStepsCompleted = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const dossierData = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(CustomerOption_get_none());
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const snackbar = useSnackbar();
    const customer = useFeliz_React__React_useState_Static_1505(void 0)[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(void 0);
    const partner = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505("");
    const dossierCreateButtonRef = useReact_useButtonRef();
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(false);
    const setCreatingDossier = patternInput_8[1];
    const creatingDossier = patternInput_8[0];
    const topRef = useReact_useElementRef();
    const dossierCreated = useToggle(false);
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_10 = useFeliz_React__React_useState_Static_1505(void 0);
    const dossierDataContact = patternInput_10[0];
    const contact = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const contactEmail = useFormField("", PhoneNumber_get_Create());
    const contactName = useFormField("", NonEmptyString_get_Create());
    const invoice = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const invoiceCustomerPerson = useCreateCustomerPersonForm("", "", "", "", "", "", "", "", "");
    const invoiceCustomerCompany = useCreateCustomerCompanyForm("", "", "", "", "", "", "", "", "", "", false, false, "", "");
    const invoicingPartySameAsOwner = useToggle(false);
    const assignmentDocumentContact = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const assignmentDocumentOther = useFormField("", NonEmptyString_get_Create());
    const assignmentDocumentLegalRepEmail = useFormField("", NonEmptyString_get_Create());
    const assignmentDocumentSyndicEmail = useFormField("", NonEmptyString_get_Create());
    const isPartnerSelected = useToggle(true);
    const partnerName = useFeliz_React__React_useState_Static_1505("Partner")[0];
    const partnerToggle = useToggle(isPartnerSelected.State);
    const customerToggle = useToggle(true);
    const emailToggle = useToggle(false);
    const emails = useFormField("", NonEmptyString_get_Create());
    const emailsError = useToggle(false);
    const foundCustomer = useFeliz_React__React_useState_Static_1505(false)[0];
    const scrollToTop = () => {
        const matchValue = topRef.current;
        if (matchValue == null) {
        }
        else {
            const topRef_1 = matchValue;
            topRef_1.scrollIntoView(true);
        }
    };
    const updateStepCompletion = (idx, value, array) => mapIndexed((i, v) => {
        if (i === idx) {
            return value;
        }
        else {
            return v;
        }
    }, array);
    const patternInput_13 = useFeliz_React__React_useState_Static_1505(false);
    const setPriceListModalOpen = patternInput_13[1];
    if (!(code === "tuaG6VaVz6nZBXZs")) {
        return createElement("h1", {
            children: ["Invalid request!"],
        });
    }
    else {
        let stepperContent;
        const children_2 = ofArray([MuiHelpers_createElement(Stepper, [["activeStep", activeStep], (elements_8 = [MuiHelpers_createElement(Step_1, [["completed", stepsCompleted[0]], ["className", join(" ", [Boldness.b700])], (elements_1 = [MuiHelpers_createElement(StepLabel, [(elements = [stepString(new Step(0))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), MuiHelpers_createElement(Step_1, [["completed", stepsCompleted[1]], (elements_3 = [MuiHelpers_createElement(StepLabel, [(elements_2 = [stepString(new Step(1))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]), MuiHelpers_createElement(Step_1, [["completed", stepsCompleted[2]], (elements_5 = [MuiHelpers_createElement(StepLabel, [(elements_4 = [stepString(new Step(2))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])]), MuiHelpers_createElement(Step_1, [["completed", stepsCompleted[3]], (elements_7 = [MuiHelpers_createElement(StepLabel, [(elements_6 = [stepString(new Step(3))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_6))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_7))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_8))])]), createElement("div", createObj(ofArray([["hidden", activeStep !== 0], (elems_9 = [createElement("h1", {
            children: ["Vul hier uw gegevens in als CIB makelaar"],
        }), createElement(Col, {
            classes: singleton_1(Gap.M),
            children: singleton_1(createElement(CreatePartnerForm, {
                onCreatePartner: (partnerData) => ((onCompleted_1) => {
                    patternInput_6[1](partnerData);
                    setStepsCompleted(updateStepCompletion(activeStep, true, stepsCompleted));
                    setActiveStep(activeStep + 1);
                    return onCompleted_1();
                }),
                showActionButtons: false,
                saveButtonText: "Volgende stap",
                isCibPartner: true,
                showCibPartner: false,
                onCompleted: () => {
                },
                data: void 0,
                partnerType: new PartnerType(0),
            })),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["hidden", activeStep !== 1], (elems_10 = [(value_32 = stepString(new Step(1)), createElement("h1", {
            children: [value_32],
        })), createElement(CreateDossierForm, {
            customerUrl: (customerId) => Page$2__toUrl_2B594(customerDetail, customerId.Value),
            backUrl: void 0,
            partners: void 0,
            onCreateDossier: (dossierData_2) => ((comment) => ((floorplan) => ((cadastralRecords) => ((propertyOther) => ((sendMailToPartner) => ((onCompleted_3) => {
                patternInput_2[1](dossierData_2);
                onCompleted_3();
            })))))),
            isCibDossier: true,
            buttonRef: dossierCreateButtonRef,
            dossierCreatePageSource: new DossierCreatePageSource(2),
            compensation: Compensation_get_DefaultCompensation(),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["hidden", activeStep !== 2], (elems_11 = toList(delay(() => {
            let value_36;
            return append(singleton((value_36 = stepString(new Step(2)), createElement("h1", {
                children: [value_36],
            }))), delay(() => {
                let children;
                const matchValue_10 = dossierData;
                if (matchValue_10 == null) {
                    return singleton(null);
                }
                else {
                    const dossierData_3 = matchValue_10;
                    return singleton((children = ofArray([createElement(ChooseContact, {
                        contactOption: contact,
                        partnerName: partnerName,
                        isPartnerSelected: isPartnerSelected.State,
                        homeVisitContactName: contactName,
                        homeVisitContactPhoneNo: contactEmail,
                    }), createElement(ChooseInvoicing, {
                        invoiceOption: invoice,
                        partnerName: partnerName,
                        customerControl: void 0,
                        invoicingPartySameAsOwner: invoicingPartySameAsOwner,
                        setInvoicedValue: invoice.SetFieldValue,
                        contactOwner: dossierData_3.DossierOwner,
                        customerPerson: invoiceCustomerPerson,
                        customerCompany: invoiceCustomerCompany,
                        isPartnerInvoicingAvailable: isPartnerSelected.State,
                        foundCustomer: foundCustomer,
                        dossierCreatePageSource: new DossierCreatePageSource(2),
                        invoicePricing: void 0,
                    }), createElement(ChooseCertificateContact, {
                        partnerToggle: partnerToggle,
                        customerToggle: customerToggle,
                        emailToggle: emailToggle,
                        partnerName: partnerName,
                        emails: emails,
                        emailsError: emailsError.State,
                        isPartnerSelected: isPartnerSelected.State,
                    }), createElement(ChooseAssignmentDocumentContact, {
                        assignmentDocumentOption: assignmentDocumentContact,
                        partnerName: partnerName,
                        legalRepEmail: assignmentDocumentLegalRepEmail,
                        syndicEmail: assignmentDocumentSyndicEmail,
                        other: assignmentDocumentOther,
                        isPartnerSelected: isPartnerSelected.State,
                    })]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    })));
                }
            }));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["hidden", activeStep !== 3], (elems_12 = toList(delay(() => {
            let value_40;
            return append(singleton((value_40 = stepString(new Step(3)), createElement("h1", {
                children: [value_40],
            }))), delay(() => append(singleton("Review"), delay(() => {
                const matchValue_11 = [partner, dossierData, dossierDataContact];
                let pattern_matching_result, dossierData_4, dossierDataContact_1, partner_3;
                if (matchValue_11[0] != null) {
                    if (matchValue_11[1] != null) {
                        if (matchValue_11[2] != null) {
                            pattern_matching_result = 0;
                            dossierData_4 = matchValue_11[1];
                            dossierDataContact_1 = matchValue_11[2];
                            partner_3 = matchValue_11[0];
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return singleton(createElement(Review, {
                            partner: partner_3,
                            dossierData: dossierData_4,
                            dossierDataContact: dossierDataContact_1,
                        }));
                    }
                    case 1: {
                        return singleton(null);
                    }
                }
            }))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement(Row, {
            classes: ofArray([Gap.M, MarginTop.L]),
            children: toList(delay(() => {
                let elems_13;
                const hidden = activeStep === 0;
                return append((item(activeStep, steps).tag === 0) ? singleton(null) : singleton(createElement("div", createObj(ofArray([["className", alignEnd], ["hidden", hidden], (elems_13 = [MuiHelpers_createElement(Button, [["color", "default"], ["variant", "contained"], ["disabled", hidden], ["disabled", creatingDossier], ["children", "Terug"], ["onClick", (_arg_3) => {
                    scrollToTop();
                    setActiveStep(activeStep - 1);
                }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))), delay(() => {
                    let elems_14;
                    return (item(activeStep, steps).tag === 0) ? singleton(null) : singleton(createElement("div", createObj(ofArray([["className", alignEnd], (elems_14 = [MuiHelpers_createElement(Button, toList(delay(() => {
                        let matchValue_14;
                        return append(singleton(["disabled", (matchValue_14 = item(activeStep, steps), (matchValue_14.tag === 1) ? false : ((matchValue_14.tag === 2) ? false : ((matchValue_14.tag === 3) && creatingDossier)))]), delay(() => append((activeStep === (length(steps) - 1)) ? singleton(["color", "secondary"]) : singleton(["color", "primary"]), delay(() => append(singleton(["variant", "contained"]), delay(() => append(singleton(["children", (activeStep === (length(steps) - 1)) ? "Bevestigen" : "Volgende stap"]), delay(() => singleton(["onClick", (_arg_4) => {
                            const arg_7 = item(activeStep, steps);
                            toConsole(printf("handleNext() %A"))(arg_7);
                            scrollToTop();
                            const matchValue_8 = item(activeStep, steps);
                            switch (matchValue_8.tag) {
                                case 1: {
                                    iterate((buttonElement) => {
                                        buttonElement.click();
                                    }, toArray(dossierCreateButtonRef.current));
                                    setStepsCompleted(updateStepCompletion(activeStep, true, stepsCompleted));
                                    setActiveStep(activeStep + 1);
                                    break;
                                }
                                case 2: {
                                    toConsole(printf("Contacts\u003e\u003e\u003e\u003e"));
                                    iterate((data) => {
                                        let homeVisitContact, matchValue_6, name_1, emails_1, customEmail, e_3, assignmentDocContact;
                                        toConsole(printf("data %A"))(data.AssignmentDocumentContact);
                                        toConsole(printf("data.InvoicingParty %A"))(data.InvoicingParty);
                                        if (data.InvoicingParty.tag === 2) {
                                        }
                                        else if ((((homeVisitContact = data.Contact.Value, (emailsError.Off(), (homeVisitContact.tag === 2) ? ((matchValue_6 = [data.ContactName, data.ContactEmail], (matchValue_6[0] != null) && ((name_1 = matchValue_6[0], true)))) : ((homeVisitContact.tag === 3) ? ((matchValue_6 = [data.ContactName, data.ContactEmail], (matchValue_6[0] != null) && ((name_1 = matchValue_6[0], true)))) : true)))) && ((emails_1 = data.CertificateCustomEmails, (customEmail = data.CertificateEmailCustom, (emailsError.Off(), (((!data.CertificateEmailPartner) && (!customEmail)) && (!data.CertificateEmailClient)) ? ((emailsError.On(), false)) : (customEmail ? ((emails_1 == null) ? false : ((e_3 = emails_1, true))) : true)))))) && ((assignmentDocContact = data.AssignmentDocumentContact.Value, (assignmentDocContact.tag === 2) ? (data.AssignmentContactEmail != null) : ((assignmentDocContact.tag === 3) ? (data.AssignmentContactEmail != null) : ((assignmentDocContact.tag === 4) ? (data.AssignmentContactEmail != null) : true))))) {
                                            const createDossierDataContact = new CreateDossierDataContact(data.AssignmentContactEmail, data.AssignmentDocumentContact.Value, data.CertificateCustomEmails, data.CertificateEmailClient, data.CertificateEmailCustom, data.CertificateEmailPartner, data.Contact.Value, data.ContactName, data.ContactEmail, data.InvoicingParty, new InvoicePricing(1));
                                            toConsole(printf("data.InvoicingParty, createDossierDataContact: %A"))(createDossierDataContact);
                                            patternInput_10[1](createDossierDataContact);
                                            setStepsCompleted(updateStepCompletion(activeStep, true, stepsCompleted));
                                            setActiveStep(activeStep + 1);
                                        }
                                    }, toArray(useFormValidator(assignmentDocumentContact.Validator, (r_3, x_8) => {
                                        let assignVal;
                                        const matchValue_5 = x_8.Value;
                                        switch (matchValue_5.tag) {
                                            case 2: {
                                                assignVal = useFormValidator(assignmentDocumentLegalRepEmail.Validator, (unitVar_13, x_9) => ({
                                                    AssignmentDocumentContactEmail: x_9,
                                                }), useForm()).Validate();
                                                break;
                                            }
                                            case 3: {
                                                assignVal = useFormValidator(assignmentDocumentSyndicEmail.Validator, (unitVar_14, x_10) => ({
                                                    AssignmentDocumentContactEmail: x_10,
                                                }), useForm()).Validate();
                                                break;
                                            }
                                            case 4: {
                                                assignVal = useFormValidator(assignmentDocumentOther.Validator, (unitVar_15, x_11) => ({
                                                    AssignmentDocumentContactEmail: x_11,
                                                }), useForm()).Validate();
                                                break;
                                            }
                                            default: {
                                                assignVal = (void 0);
                                            }
                                        }
                                        if (assignVal == null) {
                                            return {
                                                AssignmentContactEmail: void 0,
                                                AssignmentDocumentContact: x_8,
                                                CertificateCustomEmails: r_3.CertificateCustomEmails,
                                                CertificateEmailClient: r_3.CertificateEmailClient,
                                                CertificateEmailCustom: r_3.CertificateEmailCustom,
                                                CertificateEmailPartner: r_3.CertificateEmailPartner,
                                                Contact: r_3.Contact,
                                                ContactEmail: r_3.ContactEmail,
                                                ContactName: r_3.ContactName,
                                                InvoicingParty: r_3.InvoicingParty,
                                            };
                                        }
                                        else {
                                            return {
                                                AssignmentContactEmail: assignVal.AssignmentDocumentContactEmail,
                                                AssignmentDocumentContact: x_8,
                                                CertificateCustomEmails: r_3.CertificateCustomEmails,
                                                CertificateEmailClient: r_3.CertificateEmailClient,
                                                CertificateEmailCustom: r_3.CertificateEmailCustom,
                                                CertificateEmailPartner: r_3.CertificateEmailPartner,
                                                Contact: r_3.Contact,
                                                ContactEmail: r_3.ContactEmail,
                                                ContactName: r_3.ContactName,
                                                InvoicingParty: r_3.InvoicingParty,
                                            };
                                        }
                                    }, useFormValidator(invoice.Validator, (r_2, x_5) => {
                                        if (foundCustomer) {
                                            if (customer == null) {
                                                return {
                                                    CertificateCustomEmails: r_2.CertificateCustomEmails,
                                                    CertificateEmailClient: r_2.CertificateEmailClient,
                                                    CertificateEmailCustom: r_2.CertificateEmailCustom,
                                                    CertificateEmailPartner: r_2.CertificateEmailPartner,
                                                    Contact: r_2.Contact,
                                                    ContactEmail: r_2.ContactEmail,
                                                    ContactName: r_2.ContactName,
                                                    InvoicingParty: new DossierCustomer(2),
                                                };
                                            }
                                            else {
                                                return {
                                                    CertificateCustomEmails: r_2.CertificateCustomEmails,
                                                    CertificateEmailClient: r_2.CertificateEmailClient,
                                                    CertificateEmailCustom: r_2.CertificateEmailCustom,
                                                    CertificateEmailPartner: r_2.CertificateEmailPartner,
                                                    Contact: r_2.Contact,
                                                    ContactEmail: r_2.ContactEmail,
                                                    ContactName: r_2.ContactName,
                                                    InvoicingParty: new DossierCustomer(1, new DossierCustomerSelection(0, customer)),
                                                };
                                            }
                                        }
                                        else {
                                            const matchValue_2 = x_5.Value;
                                            switch (matchValue_2.tag) {
                                                case 1: {
                                                    const matchValue_3 = useFormValidator(invoiceCustomerPerson.Validator, (unitVar_11, x_6) => ({
                                                        CustomerPersonData: x_6,
                                                    }), useForm()).Validate();
                                                    if (matchValue_3 == null) {
                                                        return {
                                                            CertificateCustomEmails: r_2.CertificateCustomEmails,
                                                            CertificateEmailClient: r_2.CertificateEmailClient,
                                                            CertificateEmailCustom: r_2.CertificateEmailCustom,
                                                            CertificateEmailPartner: r_2.CertificateEmailPartner,
                                                            Contact: r_2.Contact,
                                                            ContactEmail: r_2.ContactEmail,
                                                            ContactName: r_2.ContactName,
                                                            InvoicingParty: new DossierCustomer(2),
                                                        };
                                                    }
                                                    else {
                                                        const c_1 = matchValue_3.CustomerPersonData;
                                                        return {
                                                            CertificateCustomEmails: r_2.CertificateCustomEmails,
                                                            CertificateEmailClient: r_2.CertificateEmailClient,
                                                            CertificateEmailCustom: r_2.CertificateEmailCustom,
                                                            CertificateEmailPartner: r_2.CertificateEmailPartner,
                                                            Contact: r_2.Contact,
                                                            ContactEmail: r_2.ContactEmail,
                                                            ContactName: r_2.ContactName,
                                                            InvoicingParty: new DossierCustomer(1, new DossierCustomerSelection(1, new CustomerData2(0, new CustomerPersonData(c_1.LastName, c_1.FirstName, c_1.Address, c_1.Email, c_1.PhoneNumber, true)))),
                                                        };
                                                    }
                                                }
                                                case 2: {
                                                    const matchValue_4 = useFormValidator(invoiceCustomerCompany.Validator, (unitVar_12, x_7) => ({
                                                        CustomerCompanyData: x_7,
                                                    }), useForm()).Validate();
                                                    if (matchValue_4 == null) {
                                                        return {
                                                            CertificateCustomEmails: r_2.CertificateCustomEmails,
                                                            CertificateEmailClient: r_2.CertificateEmailClient,
                                                            CertificateEmailCustom: r_2.CertificateEmailCustom,
                                                            CertificateEmailPartner: r_2.CertificateEmailPartner,
                                                            Contact: r_2.Contact,
                                                            ContactEmail: r_2.ContactEmail,
                                                            ContactName: r_2.ContactName,
                                                            InvoicingParty: new DossierCustomer(2),
                                                        };
                                                    }
                                                    else {
                                                        const c_3 = matchValue_4.CustomerCompanyData;
                                                        return {
                                                            CertificateCustomEmails: r_2.CertificateCustomEmails,
                                                            CertificateEmailClient: r_2.CertificateEmailClient,
                                                            CertificateEmailCustom: r_2.CertificateEmailCustom,
                                                            CertificateEmailPartner: r_2.CertificateEmailPartner,
                                                            Contact: r_2.Contact,
                                                            ContactEmail: r_2.ContactEmail,
                                                            ContactName: r_2.ContactName,
                                                            InvoicingParty: new DossierCustomer(1, new DossierCustomerSelection(1, new CustomerData2(1, new CustomerCompanyData(c_3.CompanyName, c_3.CompanyType, c_3.Address, c_3.Email, c_3.PhoneNumber, c_3.VatNumber, true, c_3.IsVME, c_3.IsSocialHousingCompany, c_3.LastName, c_3.FirstName)))),
                                                        };
                                                    }
                                                }
                                                default: {
                                                    if (partner == null) {
                                                        return {
                                                            CertificateCustomEmails: r_2.CertificateCustomEmails,
                                                            CertificateEmailClient: r_2.CertificateEmailClient,
                                                            CertificateEmailCustom: r_2.CertificateEmailCustom,
                                                            CertificateEmailPartner: r_2.CertificateEmailPartner,
                                                            Contact: r_2.Contact,
                                                            ContactEmail: r_2.ContactEmail,
                                                            ContactName: r_2.ContactName,
                                                            InvoicingParty: new DossierCustomer(2),
                                                        };
                                                    }
                                                    else {
                                                        const partner_2 = partner;
                                                        return {
                                                            CertificateCustomEmails: r_2.CertificateCustomEmails,
                                                            CertificateEmailClient: r_2.CertificateEmailClient,
                                                            CertificateEmailCustom: r_2.CertificateEmailCustom,
                                                            CertificateEmailPartner: r_2.CertificateEmailPartner,
                                                            Contact: r_2.Contact,
                                                            ContactEmail: r_2.ContactEmail,
                                                            ContactName: r_2.ContactName,
                                                            InvoicingParty: new DossierCustomer(1, new DossierCustomerSelection(1, new CustomerData2(1, new CustomerCompanyData(partner_2.CompanyName, partner_2.CompanyType, partner_2.Address, partner_2.Email, partner_2.PhoneNumber, partner_2.VatNumber, true, partner_2.IsVME, partner_2.IsSocialHousingCompany, partner_2.ContactLastName, partner_2.ContactFirstName)))),
                                                        };
                                                    }
                                                }
                                            }
                                        }
                                    }, useFormValidator(contact.Validator, (r_1, x_3) => {
                                        const partnerEmail = isPartnerSelected.State && partnerToggle.State;
                                        if (emailToggle.State) {
                                            const emailsVal_1 = useFormValidator(emails.Validator, (unitVar_10, x_4) => ({
                                                CertificateCustomEmails: x_4,
                                            }), useForm()).Validate();
                                            if (emailsVal_1 == null) {
                                                return {
                                                    CertificateCustomEmails: void 0,
                                                    CertificateEmailClient: customerToggle.State,
                                                    CertificateEmailCustom: emailToggle.State,
                                                    CertificateEmailPartner: partnerEmail,
                                                    Contact: r_1.Contact,
                                                    ContactEmail: r_1.ContactEmail,
                                                    ContactName: r_1.ContactName,
                                                };
                                            }
                                            else {
                                                return {
                                                    CertificateCustomEmails: emailsVal_1.CertificateCustomEmails,
                                                    CertificateEmailClient: customerToggle.State,
                                                    CertificateEmailCustom: emailToggle.State,
                                                    CertificateEmailPartner: partnerEmail,
                                                    Contact: r_1.Contact,
                                                    ContactEmail: r_1.ContactEmail,
                                                    ContactName: r_1.ContactName,
                                                };
                                            }
                                        }
                                        else {
                                            return {
                                                CertificateCustomEmails: void 0,
                                                CertificateEmailClient: customerToggle.State,
                                                CertificateEmailCustom: emailToggle.State,
                                                CertificateEmailPartner: partnerEmail,
                                                Contact: r_1.Contact,
                                                ContactEmail: r_1.ContactEmail,
                                                ContactName: r_1.ContactName,
                                            };
                                        }
                                    }, useFormValidator(contact.Validator, (unitVar_8, x) => {
                                        const matchValue_1 = x.Value;
                                        switch (matchValue_1.tag) {
                                            case 2:
                                            case 3: {
                                                const emailsVal = useFormValidator(contactEmail.Validator, (r, x_2) => ({
                                                    ContactEmail: x_2,
                                                    ContactName: r.ContactName,
                                                }), useFormValidator(contactName.Validator, (unitVar_9, x_1) => ({
                                                    ContactName: x_1,
                                                }), useForm())).Validate();
                                                if (emailsVal == null) {
                                                    return {
                                                        Contact: x,
                                                        ContactEmail: void 0,
                                                        ContactName: void 0,
                                                    };
                                                }
                                                else {
                                                    const e_1 = emailsVal;
                                                    return {
                                                        Contact: x,
                                                        ContactEmail: e_1.ContactEmail,
                                                        ContactName: e_1.ContactName,
                                                    };
                                                }
                                            }
                                            default: {
                                                return {
                                                    Contact: x,
                                                    ContactEmail: void 0,
                                                    ContactName: void 0,
                                                };
                                            }
                                        }
                                    }, useForm())))).Validate()));
                                    break;
                                }
                                case 3: {
                                    toConsole(printf("Review\u003e\u003e\u003e\u003e dossierData %A, dossierContactData %A"))(dossierData)(dossierDataContact);
                                    const matchValue_9 = [dossierData, dossierDataContact];
                                    let pattern_matching_result_1, contacts, data_1;
                                    if (matchValue_9[0] != null) {
                                        if (matchValue_9[1] != null) {
                                            pattern_matching_result_1 = 0;
                                            contacts = matchValue_9[1];
                                            data_1 = matchValue_9[0];
                                        }
                                        else {
                                            pattern_matching_result_1 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                    switch (pattern_matching_result_1) {
                                        case 0: {
                                            setCreatingDossier(true);
                                            const dossierData_1 = new CreateDossierData2(data_1, contacts);
                                            startImmediate(singleton_2.Delay(() => singleton_2.TryFinally(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                                                if (partner != null) {
                                                    const partner_1 = partner;
                                                    return singleton_2.Bind(api.CreateCibDossier(partner_1, dossierData_1), (_arg) => {
                                                        const result = _arg;
                                                        if (result.tag === 0) {
                                                            dossierCreated.Toggle();
                                                            patternInput_9[1](result.fields[0]);
                                                            scrollToTop();
                                                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuw dossier is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                                            return singleton_2.Zero();
                                                        }
                                                        else {
                                                            const err = result.fields[0];
                                                            console.error(err);
                                                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                                            return singleton_2.Zero();
                                                        }
                                                    });
                                                }
                                                else {
                                                    return singleton_2.Zero();
                                                }
                                            }), (_arg_1) => {
                                                const e = _arg_1;
                                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                                console.error(e);
                                                return singleton_2.Zero();
                                            })), () => {
                                                (() => {
                                                    setCreatingDossier(false);
                                                })();
                                            })));
                                            break;
                                        }
                                        case 1: {
                                            break;
                                        }
                                    }
                                    break;
                                }
                                default: {
                                    if (partner != null) {
                                        setStepsCompleted(updateStepCompletion(activeStep, true, stepsCompleted));
                                        setActiveStep(activeStep + 1);
                                    }
                                }
                            }
                        }])))))))));
                    })))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))));
                }));
            })),
        })]);
        stepperContent = createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
        const pageContent = (!dossierCreated.State) ? createElement(PageCib, {
            title: "Aanvraag asbestattest",
            actions: [],
            className: void 0,
            children: [Card([createElement("span", {
                ref: topRef,
            }), stepperContent])],
        }) : createElement(PageCib, {
            title: "",
            actions: [],
            className: BkgImage,
            children: toList(delay(() => append(singleton(createElement("span", {
                ref: topRef,
            })), delay(() => {
                const matchValue_15 = patternInput_9[0];
                return (matchValue_15 == null) ? singleton(null) : singleton(createElement(SuccessPage, matchValue_15));
            })))),
        });
        return createElement(Col, {
            classes: singleton_1(flexExpand),
            children: ofArray([MuiHelpers_createElement(AppBar, [["style", {
                backgroundColor: "#FFFFFF",
            }], Feliz_prop__prop_fss_Static_72C268A9(ofArray([CssRuleWithNone__get_none(BoxShadow), ColorClass_Color__hex_Z721C83C5(BorderBottomColor, "f0f0f0"), BorderClasses_BorderStyleParent__get_solid(BorderBottomStyle), unitHelpers_DirectionalLength__value_Z498FEB3B(BorderBottomWidth, px(1))])), ["position", "sticky"], (elems_17 = [MuiHelpers_createElement(Toolbar, [["classes.root", spaceBetween], (elements_9 = [createElement(Row, {
                classes: ofArray([spaceBetween, TextColor.Primary, flexExpand]),
                children: ofArray([createElement(Row, {
                    classes: empty(),
                    children: singleton_1(createElement("img", {
                        className: appBarLogo,
                        alt: "Asbest Consulting Logo",
                        src: logo,
                    })),
                }), createElement(Row, {
                    classes: singleton_1(center),
                    children: singleton_1(MuiHelpers_createElement(Button, [["color", "primary"], ["variant", "contained"], ["onClick", (_arg_5) => {
                        setPriceListModalOpen(true);
                    }], ["children", Interop_reactApi.Children.toArray(["Prijslijst"])]])),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elements_9))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]), createElement(Row, {
                classes: empty(),
                children: ofArray([createElement(PriceListModal, {
                    isOpen: patternInput_13[0],
                    onClose: setPriceListModalOpen,
                }), pageContent]),
            })]),
        });
    }
}

