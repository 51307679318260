import { toString, FSharpRef, Union, Record } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Types.js";
import { class_type, lambda_type, option_type, list_type, union_type, record_type, string_type } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { map, map2, some } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Option.js";
import { tryPick, empty, ofArray, singleton, append, toArray, mapFold, head, tail, isEmpty } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import { tryParse } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Int32.js";
import { tryParse as tryParse_1, fromInt } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Long.js";
import { tryParse as tryParse_2 } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Guid.js";
import { tryParse as tryParse_3 } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Double.js";
import { tryParse as tryParse_4 } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Decimal.js";
import Decimal from "../partnerportal/src/fable_modules/fable-library.3.7.17/Decimal.js";

export class QueryParam extends Record {
    constructor(Name) {
        super();
        this.Name = Name;
    }
}

export function QueryParam$reflection() {
    return record_type("BidirectionalRouting.QueryParam", [], QueryParam, () => [["Name", string_type]]);
}

export class Segment extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Literal", "Param"];
    }
}

export function Segment$reflection() {
    return union_type("BidirectionalRouting.Segment", [], Segment, () => [[["Item", string_type]], []]);
}

export class RouteDefinition$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RouteDefinition"];
    }
}

export function RouteDefinition$1$reflection(gen0) {
    return union_type("BidirectionalRouting.RouteDefinition`1", [gen0], RouteDefinition$1, () => [[["Item1", list_type(Segment$reflection())], ["Item2", list_type(QueryParam$reflection())]]]);
}

export class RouteDefinition extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RouteDefinitionNoParams"];
    }
}

export function RouteDefinition$reflection() {
    return union_type("BidirectionalRouting.RouteDefinition", [], RouteDefinition, () => [[["Item1", list_type(Segment$reflection())], ["Item2", list_type(QueryParam$reflection())]]]);
}

export class Parameter$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Parameter"];
    }
}

export function Parameter$1$reflection(gen0) {
    return union_type("BidirectionalRouting.Parameter`1", [gen0], Parameter$1, () => [[["Item", lambda_type(string_type, option_type(gen0))]]]);
}

export function ParseSegments_finalSuccess() {
    return some(void 0);
}

export function ParseSegments_finalFail() {
    return void 0;
}

export function ParseSegments_parse(segments_mut, url_mut, continuation_mut, final_mut) {
    ParseSegments_parse:
    while (true) {
        const segments = segments_mut, url = url_mut, continuation = continuation_mut, final = final_mut;
        const matchValue = [segments, url];
        let pattern_matching_result, restSegments, restValues, segment, value;
        if (!isEmpty(matchValue[0])) {
            if (!isEmpty(matchValue[1])) {
                pattern_matching_result = 1;
                restSegments = tail(matchValue[0]);
                restValues = tail(matchValue[1]);
                segment = head(matchValue[0]);
                value = head(matchValue[1]);
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else if (isEmpty(matchValue[1])) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return final();
            }
            case 1: {
                if (segment.tag === 1) {
                    return continuation(restSegments, restValues, value);
                }
                else if (segment.fields[0] === value) {
                    segments_mut = restSegments;
                    url_mut = restValues;
                    continuation_mut = continuation;
                    final_mut = final;
                    continue ParseSegments_parse;
                }
                else {
                    return void 0;
                }
            }
            case 2: {
                return void 0;
            }
        }
        break;
    }
}

export function ParseSegments_parse0(segments, url) {
    return ParseSegments_parse(segments, url, (segments_1, url_1, value) => (void 0), ParseSegments_finalSuccess);
}

export function ParseSegments_parse1(segments, url, _arg) {
    return ParseSegments_parse(segments, url, (segments_1, url_1, value) => {
        const option_1 = ParseSegments_parse0(segments_1, url_1);
        return map2((a, _arg_1) => a, _arg.fields[0](value), option_1);
    }, ParseSegments_finalFail);
}

export function ParseSegments_parse2(segments, url, _arg, p2) {
    return ParseSegments_parse(segments, url, (segments_1, url_1, value) => {
        const option_1 = ParseSegments_parse1(segments_1, url_1, p2);
        return map2((a, b) => [a, b], _arg.fields[0](value), option_1);
    }, ParseSegments_finalFail);
}

export function ParseSegments_parse3(segments, url, _arg, p2, p3) {
    return ParseSegments_parse(segments, url, (segments_1, url_1, value) => {
        const option_1 = ParseSegments_parse2(segments_1, url_1, p2, p3);
        return map2((a, tupledArg) => [a, tupledArg[0], tupledArg[1]], _arg.fields[0](value), option_1);
    }, ParseSegments_finalFail);
}

export function ParseSegments_parse4(segments, url, _arg, p2, p3, p4) {
    return ParseSegments_parse(segments, url, (segments_1, url_1, value) => {
        const option_1 = ParseSegments_parse3(segments_1, url_1, p2, p3, p4);
        return map2((a, tupledArg) => [a, tupledArg[0], tupledArg[1], tupledArg[2]], _arg.fields[0](value), option_1);
    }, ParseSegments_finalFail);
}

export function ParseSegments_parse5(segments, url, _arg, p2, p3, p4, p5) {
    return ParseSegments_parse(segments, url, (segments_1, url_1, value) => {
        const option_1 = ParseSegments_parse4(segments_1, url_1, p2, p3, p4, p5);
        return map2((a, tupledArg) => [a, tupledArg[0], tupledArg[1], tupledArg[2], tupledArg[3]], _arg.fields[0](value), option_1);
    }, ParseSegments_finalFail);
}

export function ParseSegments_toUrl(segments, parameters) {
    return mapFold((parameters_1, segment) => {
        if (segment.tag === 1) {
            if (isEmpty(parameters_1)) {
                throw (new Error("Should not happen. Bug in the Router parser."));
            }
            else {
                return [head(parameters_1), tail(parameters_1)];
            }
        }
        else {
            return [segment.fields[0], parameters_1];
        }
    }, parameters, segments)[0];
}

export function Parse_int(input) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(input, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Parse_int64(input) {
    let matchValue;
    let outArg = fromInt(0);
    matchValue = [tryParse_1(input, 511, false, 64, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Parse_guid(input) {
    let matchValue;
    let outArg = "00000000-0000-0000-0000-000000000000";
    matchValue = [tryParse_2(input, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Parse_double(input) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse_3(input, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Parse_decimal(input) {
    let matchValue;
    let outArg = new Decimal(0);
    matchValue = [tryParse_4(input, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Parse_bool(input) {
    const matchValue = input.toLocaleLowerCase();
    switch (matchValue) {
        case "1":
        case "true": {
            return true;
        }
        case "0":
        case "false": {
            return false;
        }
        case "": {
            return true;
        }
        default: {
            return void 0;
        }
    }
}

export class Page$2 {
    constructor(parse, toUrl) {
        this["parse@121"] = parse;
        this["toUrl@121"] = toUrl;
    }
    parse(x) {
        const this$ = this;
        return this$["parse@121"](x);
    }
}

export function Page$2$reflection(gen0, gen1) {
    return class_type("BidirectionalRouting.Page`2", [gen0, gen1], Page$2);
}

export function Page$2_$ctor_44372A54(parse, toUrl) {
    return new Page$2(parse, toUrl);
}

export function Page$2__toUrl_2B594(this$, x) {
    return toArray(this$["toUrl@121"](x));
}

export class R$5 {
    constructor(def, p1, p2, p3, p4, p5) {
        this.def = def;
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
        this.p4 = p4;
        this.p5 = p5;
    }
}

export function R$5$reflection(gen0, gen1, gen2, gen3, gen4) {
    return class_type("BidirectionalRouting.R`5", [gen0, gen1, gen2, gen3, gen4], R$5);
}

export function R$5_$ctor_3DED8FA5(def, p1, p2, p3, p4, p5) {
    return new R$5(def, p1, p2, p3, p4, p5);
}

export function R$5__segment_Z721C83C5(this$, value) {
    const patternInput = this$.def;
    return R$5_$ctor_3DED8FA5(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(0, value))), patternInput.fields[1]), this$.p1, this$.p2, this$.p3, this$.p4, this$.p5);
}

export function R$5__parse_1334CEF1(this$, url) {
    return ParseSegments_parse5(this$.def.fields[0], url, this$.p1, this$.p2, this$.p3, this$.p4, this$.p5);
}

export function R$5__toUrl_55DCDF11(this$, a, b, c, d, e) {
    return ParseSegments_toUrl(this$.def.fields[0], ofArray([toString(a), toString(b), toString(c), toString(d), toString(e)]));
}

export function R$5__page_Z54AA9CBE(this$, constructor) {
    return Page$2_$ctor_44372A54((x) => map(constructor, R$5__parse_1334CEF1(this$, x)), (tupledArg) => R$5__toUrl_55DCDF11(this$, tupledArg[0], tupledArg[1], tupledArg[2], tupledArg[3], tupledArg[4]));
}

export class R$4 {
    constructor(def, p1, p2, p3, p4) {
        this.def = def;
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
        this.p4 = p4;
    }
}

export function R$4$reflection(gen0, gen1, gen2, gen3) {
    return class_type("BidirectionalRouting.R`4", [gen0, gen1, gen2, gen3], R$4);
}

export function R$4_$ctor_Z5CF77C5B(def, p1, p2, p3, p4) {
    return new R$4(def, p1, p2, p3, p4);
}

export function R$4__segment_Z721C83C5(this$, value) {
    const patternInput = this$.def;
    return R$4_$ctor_Z5CF77C5B(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(0, value))), patternInput.fields[1]), this$.p1, this$.p2, this$.p3, this$.p4);
}

export function R$4__param_Z2F4DFF34(this$, p) {
    const patternInput = this$.def;
    return R$5_$ctor_3DED8FA5(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(1))), patternInput.fields[1]), this$.p1, this$.p2, this$.p3, this$.p4, p);
}

export function R$4__parse_1334CEF1(this$, url) {
    return ParseSegments_parse4(this$.def.fields[0], url, this$.p1, this$.p2, this$.p3, this$.p4);
}

export function R$4__toUrl_Z7984E580(this$, a, b, c, d) {
    return ParseSegments_toUrl(this$.def.fields[0], ofArray([toString(a), toString(b), toString(c), toString(d)]));
}

export function R$4__page_7F8BE65C(this$, constructor) {
    return Page$2_$ctor_44372A54((x) => map(constructor, R$4__parse_1334CEF1(this$, x)), (tupledArg) => R$4__toUrl_Z7984E580(this$, tupledArg[0], tupledArg[1], tupledArg[2], tupledArg[3]));
}

export class R$3 {
    constructor(def, p1, p2, p3) {
        this.def = def;
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
    }
}

export function R$3$reflection(gen0, gen1, gen2) {
    return class_type("BidirectionalRouting.R`3", [gen0, gen1, gen2], R$3);
}

export function R$3_$ctor_2909C1A5(def, p1, p2, p3) {
    return new R$3(def, p1, p2, p3);
}

export function R$3__segment_Z721C83C5(this$, value) {
    const patternInput = this$.def;
    return R$3_$ctor_2909C1A5(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(0, value))), patternInput.fields[1]), this$.p1, this$.p2, this$.p3);
}

export function R$3__param_Z2F4DFF34(this$, p) {
    const patternInput = this$.def;
    return R$4_$ctor_Z5CF77C5B(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(1))), patternInput.fields[1]), this$.p1, this$.p2, this$.p3, p);
}

export function R$3__parse_1334CEF1(this$, url) {
    return ParseSegments_parse3(this$.def.fields[0], url, this$.p1, this$.p2, this$.p3);
}

export function R$3__toUrl_BD52456(this$, a, b, c) {
    return ParseSegments_toUrl(this$.def.fields[0], ofArray([toString(a), toString(b), toString(c)]));
}

export function R$3__page_46692BC5(this$, constructor) {
    return Page$2_$ctor_44372A54((x) => map(constructor, R$3__parse_1334CEF1(this$, x)), (tupledArg) => R$3__toUrl_BD52456(this$, tupledArg[0], tupledArg[1], tupledArg[2]));
}

export class R$2 {
    constructor(def, p1, p2) {
        this.def = def;
        this.p1 = p1;
        this.p2 = p2;
    }
}

export function R$2$reflection(gen0, gen1) {
    return class_type("BidirectionalRouting.R`2", [gen0, gen1], R$2);
}

export function R$2_$ctor_B5A6E65(def, p1, p2) {
    return new R$2(def, p1, p2);
}

export function R$2__segment_Z721C83C5(this$, value) {
    const patternInput = this$.def;
    return R$2_$ctor_B5A6E65(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(0, value))), patternInput.fields[1]), this$.p1, this$.p2);
}

export function R$2__param_Z2F4DFF34(this$, p) {
    const patternInput = this$.def;
    return R$3_$ctor_2909C1A5(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(1))), patternInput.fields[1]), this$.p1, this$.p2, p);
}

export function R$2__parse_1334CEF1(this$, url) {
    return ParseSegments_parse2(this$.def.fields[0], url, this$.p1, this$.p2);
}

export function R$2__toUrl_5BDDA1(this$, a, b) {
    return ParseSegments_toUrl(this$.def.fields[0], ofArray([toString(a), toString(b)]));
}

export function R$2__page_368EB55D(this$, constructor) {
    return Page$2_$ctor_44372A54((x) => map(constructor, R$2__parse_1334CEF1(this$, x)), (tupledArg) => R$2__toUrl_5BDDA1(this$, tupledArg[0], tupledArg[1]));
}

export class R$1 {
    constructor(def, p1) {
        this.def = def;
        this.p1 = p1;
    }
}

export function R$1$reflection(gen0) {
    return class_type("BidirectionalRouting.R`1", [gen0], R$1);
}

export function R$1_$ctor_Z3307AA1B(def, p1) {
    return new R$1(def, p1);
}

export function R$1__segment_Z721C83C5(this$, value) {
    const patternInput = this$.def;
    return R$1_$ctor_Z3307AA1B(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(0, value))), patternInput.fields[1]), this$.p1);
}

export function R$1__param_Z2F4DFF34(this$, p) {
    const patternInput = this$.def;
    return R$2_$ctor_B5A6E65(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(1))), patternInput.fields[1]), this$.p1, p);
}

export function R$1__parse_1334CEF1(this$, url) {
    return ParseSegments_parse1(this$.def.fields[0], url, this$.p1);
}

export function R$1__toUrl_2B595(this$, a) {
    return ParseSegments_toUrl(this$.def.fields[0], singleton(toString(a)));
}

export function R$1__page_6CB7246(this$, constructor) {
    return Page$2_$ctor_44372A54((x) => map(constructor, R$1__parse_1334CEF1(this$, x)), (arg_1) => R$1__toUrl_2B595(this$, arg_1));
}

export class R {
    constructor(def) {
        this.def = def;
    }
}

export function R$reflection() {
    return class_type("BidirectionalRouting.R", void 0, R);
}

export function R_$ctor_Z31E125C8(def) {
    return new R(def);
}

export function R_get_Int() {
    return new Parameter$1(0, Parse_int);
}

export function R_get_Int64() {
    return new Parameter$1(0, Parse_int64);
}

export function R_get_String() {
    return new Parameter$1(0, (x) => x);
}

export function R_get_Guid() {
    return new Parameter$1(0, Parse_guid);
}

export function R_get_Double() {
    return new Parameter$1(0, Parse_double);
}

export function R_get_Decimal() {
    return new Parameter$1(0, Parse_decimal);
}

export function R_get_Bool() {
    return new Parameter$1(0, Parse_bool);
}

export function R_create() {
    return R_$ctor_Z31E125C8(new RouteDefinition(0, empty(), empty()));
}

export function R__segment_Z721C83C5(this$, value) {
    const patternInput = this$.def;
    return R_$ctor_Z31E125C8(new RouteDefinition(0, append(patternInput.fields[0], singleton(new Segment(0, value))), patternInput.fields[1]));
}

export function R__param_Z2F4DFF34(this$, p) {
    const patternInput = this$.def;
    return R$1_$ctor_Z3307AA1B(new RouteDefinition$1(0, append(patternInput.fields[0], singleton(new Segment(1))), patternInput.fields[1]), p);
}

export function R__parse_1334CEF1(this$, url) {
    return ParseSegments_parse0(this$.def.fields[0], url);
}

export function R__toUrl(this$) {
    return ParseSegments_toUrl(this$.def.fields[0], empty());
}

export function R__page_1505(this$, value) {
    return Page$2_$ctor_44372A54((x) => map(() => value, R__parse_1334CEF1(this$, x)), () => R__toUrl(this$));
}

export function Match_routes(pages, url) {
    return tryPick((page) => page.parse(url), pages);
}

