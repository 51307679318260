import { useSnackbar } from "notistack";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { Customer2__get_Code, CustomerCode } from "../../../shared/Domain.fs.js";
import { createElement } from "react";
import { Page } from "../components/Page.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { EditCustomerForm } from "../../../DesignSystems/CustomerForms.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { customerDetail } from "../AppRoutes.fs.js";

export function Edit(editInputProps) {
    const customerCode = editInputProps.customerCode;
    const api = editInputProps.api;
    const currentPage = editInputProps.currentPage;
    const snackbar = useSnackbar();
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCustomer(new CustomerCode(customerCode)), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Klantenfiche",
        actions: [],
        children: [createElement(DisplayDeferred, {
            data: data,
            view: (customer) => {
                if (customer == null) {
                    return `No customer found with code ${customerCode}`;
                }
                else {
                    const customer_1 = customer;
                    return createElement(EditCustomerForm, {
                        customer: customer_1,
                        onEditCustomer: (onCompleted) => ((id) => ((customerData) => {
                            startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                                return singleton.Bind(api.EditCustomer(id, customerData), (_arg) => {
                                    const result = _arg;
                                    if (result.tag === 0) {
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "De klant is aangepast", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(customerDetail, customerCode)), 1, 1);
                                        return singleton.Zero();
                                    }
                                    else {
                                        const err = result.fields[0];
                                        console.error(err);
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                        return singleton.Zero();
                                    }
                                });
                            }), (_arg_1) => {
                                const e = _arg_1;
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                console.error(e);
                                return singleton.Zero();
                            })), () => {
                                onCompleted();
                            })));
                        })),
                        url: Page$2__toUrl_2B594(customerDetail, Customer2__get_Code(customer_1).Value),
                    });
                }
            },
        })],
    });
}

