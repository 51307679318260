import { map as map_2, empty, singleton, append, delay, toList } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Row, Col } from "../../Components/UtilComponents.fs.js";
import { flexExpand, Gap, cursorPointer, TableHeaderCell, alignEnd, MarginBottom, MarginTop, MarginRight } from "../../Styles/Utils.fs.js";
import { empty as empty_1, sortByDescending, length, map as map_1, toArray, singleton as singleton_1, ofArray } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import { MuiHelpers_createElement } from "../../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { format, join } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/String.js";
import { PagingQuery } from "../../shared/Paging.fs.js";
import { Msg, searchTerms } from "./QuoteFilter.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { CheckIcon, DisplayDiscount, CheckBox, Link, DisplayData, InfoTitle, MultiSelect, TableHeader, FilterField } from "../DesignSystem.fs.js";
import { map, defaultArg } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Option.js";
import TableCell from "@material-ui/core/TableCell";
import { Interop_reactApi } from "../../partnerportal/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { FilterFieldDateRange } from "../FilterUtils.fs.js";
import { InspectionLocation_extra, InspectionLocation_type$0027, Customer2_name, Address_address, GeneralFormats_dateTimeStamp, Quote_status } from "../../shared/Format.fs.js";
import { InspectionLocationExtra, VatNumber___UnsafeUnwrap_18C04ACB, PhoneNumber___UnsafeUnwrap_23E45174, Email___UnsafeUnwrap_Z27D11339, Customer2__get_Code, HomeVisitContactModule_string, NonEmptyString___UnsafeUnwrap_Z73AD07C, QuoteStatus_get_list } from "../../shared/Domain.fs.js";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { DisplayDeferred } from "../DisplayDeferred.fs.js";
import { QuoteStatusChipSyndic, noContentMessage } from "../DossierUtils.fs.js";
import { RouterModule_encodeParts } from "../../partnerportal/src/fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { fromDateTimeOffset } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Date.js";
import { Quote_contact, Quote_contactPhoneNumber } from "../../shared/DomainHelpers.fs.js";
import { comparePrimitives } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Util.js";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { useSnackbar } from "notistack";
import Paper from "@material-ui/core/Paper";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../../partnerportal/src/fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { FlexClasses_FlexShrinkGrow__value_5E38073B } from "../../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Flex.fs.js";
import { FlexGrow } from "../../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Flex.fs.js";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import { QuoteInvoice } from "./QuoteInvoiceDetails.fs.js";
import { ImageList, DocumentsList } from "../QuoteFiles.fs.js";

export function QuotesDataSyndic(quotesDataSyndicInputProps) {
    const downloadLink = quotesDataSyndicInputProps.downloadLink;
    const showPartnerPortal = quotesDataSyndicInputProps.showPartnerPortal;
    const showCibFilter = quotesDataSyndicInputProps.showCibFilter;
    const optionalExportCsvFunction = quotesDataSyndicInputProps.optionalExportCsvFunction;
    const showPartnerName = quotesDataSyndicInputProps.showPartnerName;
    const url = quotesDataSyndicInputProps.url;
    const dispatch = quotesDataSyndicInputProps.dispatch;
    const state = quotesDataSyndicInputProps.state;
    const data = quotesDataSyndicInputProps.data;
    const xs_3 = toList(delay(() => {
        let matchValue, exportCsvFunction;
        return append((matchValue = optionalExportCsvFunction, (matchValue == null) ? singleton(null) : ((exportCsvFunction = matchValue, singleton(createElement(Col, {
            classes: ofArray([MarginRight.S, MarginTop.S, MarginBottom.M]),
            children: singleton_1(MuiHelpers_createElement(Button, [["className", join(" ", [alignEnd])], ["target", "_blank"], ["href", "#"], ["variant", "contained"], ["color", "secondary"], ["onClick", (e) => {
                e.preventDefault();
                e.stopPropagation();
                exportCsvFunction(new PagingQuery(state.PageNumber, state.PageSize))(searchTerms(state));
            }], ["children", "Exporteren naar csv-bestand"]])),
        }))))), delay(() => {
            let elems_23, elems_22, children_10, children_9;
            return append(singleton(MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_23 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_22 = [(children_10 = singleton_1((children_9 = toList(delay(() => {
                let children;
                return append(singleton((children = singleton_1(createElement(Col, {
                    classes: singleton_1(TableHeaderCell),
                    children: ofArray([FilterField("Filter", (arg_2) => {
                        dispatch(new Msg(0, arg_2));
                    }, defaultArg(map((d) => d.Value, state.QuoteCode), "")), createElement(TableHeader, {
                        text: "Offerte nummer",
                    })]),
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), delay(() => {
                    let children_1;
                    return append(singleton((children_1 = singleton_1(createElement(Col, {
                        classes: singleton_1(TableHeaderCell),
                        children: ofArray([FilterFieldDateRange("Filter", (arg_4) => {
                            dispatch(new Msg(7, arg_4));
                        }, state.DateRange), createElement(TableHeader, {
                            text: "Datum en tijd",
                        })]),
                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), delay(() => {
                        let children_2;
                        return append(singleton((children_2 = singleton_1(createElement(Col, {
                            classes: singleton_1(TableHeaderCell),
                            children: ofArray([createElement(MultiSelect, {
                                label: "Filter",
                                items: toArray(map_1(Quote_status, QuoteStatus_get_list())),
                                v: state.Status,
                                onChange: (arg_6) => {
                                    dispatch(new Msg(5, arg_6));
                                },
                            }), createElement(TableHeader, {
                                text: "Status",
                            })]),
                        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), delay(() => {
                            let children_3;
                            return append(showPartnerName ? singleton((children_3 = singleton_1(createElement(Col, {
                                classes: singleton_1(TableHeaderCell),
                                children: ofArray([FilterField("Filter", (arg_8) => {
                                    dispatch(new Msg(1, arg_8));
                                }, defaultArg(state.PartnerName, "")), createElement(TableHeader, {
                                    text: "Syndic",
                                })]),
                            })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))) : empty(), delay(() => {
                                let children_4;
                                return append(singleton((children_4 = singleton_1(createElement(Col, {
                                    classes: singleton_1(TableHeaderCell),
                                    children: ofArray([FilterField("Filter", (arg_10) => {
                                        dispatch(new Msg(4, arg_10));
                                    }, defaultArg(state.PhoneNumber, "")), createElement(TableHeader, {
                                        text: "Tel dossierbeheerder",
                                    })]),
                                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))), delay(() => {
                                    let children_5;
                                    return append(singleton((children_5 = singleton_1(createElement(Col, {
                                        classes: singleton_1(TableHeaderCell),
                                        children: ofArray([FilterField("Filter", (arg_12) => {
                                            dispatch(new Msg(2, arg_12));
                                        }, defaultArg(state.CustomerName, "")), createElement(TableHeader, {
                                            text: "VME",
                                        })]),
                                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))), delay(() => {
                                        let children_6;
                                        return append(singleton((children_6 = singleton_1(createElement(Col, {
                                            classes: singleton_1(TableHeaderCell),
                                            children: ofArray([FilterField("Filter", (arg_14) => {
                                                dispatch(new Msg(2, arg_14));
                                            }, defaultArg(state.CustomerName, "")), createElement(TableHeader, {
                                                text: "Beheerder",
                                            })]),
                                        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))), delay(() => {
                                            let children_7;
                                            return append(singleton((children_7 = singleton_1(createElement(Col, {
                                                classes: singleton_1(TableHeaderCell),
                                                children: ofArray([FilterField("Filter", (arg_16) => {
                                                    dispatch(new Msg(3, arg_16));
                                                }, defaultArg(state.Address, "")), createElement(TableHeader, {
                                                    text: "Inspectielocatie",
                                                })]),
                                            })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))), delay(() => {
                                                let children_8;
                                                return singleton((children_8 = singleton_1(createElement(Col, {
                                                    classes: singleton_1(TableHeaderCell),
                                                    children: ofArray([createElement("div", {}), createElement(TableHeader, {
                                                        text: "Offerte PDF",
                                                    })]),
                                                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]])));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            })), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_10))]])), createElement(DisplayDeferred, {
                data: data,
                view: (quoteDetails) => {
                    const xs_2 = toList(delay(() => {
                        let children_20;
                        return (length(quoteDetails.Data) === 0) ? singleton(noContentMessage(8, "Er zijn nog geen offertes")) : singleton((children_20 = toList(delay(() => map_2((detail) => {
                            let elems_20;
                            return MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["onClick", (_arg) => {
                                window.open(RouterModule_encodeParts(ofArray(url(detail.Quote.Code)), 1), "_blank");
                            }], ["key", detail.Quote.Id.Value], (elems_20 = toList(delay(() => append(singleton(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([detail.Quote.Code.Value])]])), delay(() => {
                                let children_12;
                                return append(singleton((children_12 = singleton_1(GeneralFormats_dateTimeStamp(fromDateTimeOffset(detail.Quote.CreatedOn, 0))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_12))]]))), delay(() => {
                                    let children_13;
                                    return append(singleton((children_13 = singleton_1(createElement(QuoteStatusChipSyndic, {
                                        status: detail.Quote.Status,
                                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_13))]]))), delay(() => {
                                        let children_14;
                                        return append(showPartnerName ? singleton((children_14 = toList(delay(() => {
                                            const matchValue_1 = detail.Partner;
                                            return (matchValue_1 == null) ? singleton("-") : singleton(NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_1.CompanyName));
                                        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_14))]]))) : empty(), delay(() => {
                                            let children_15;
                                            let vmeName;
                                            const matchValue_2 = detail.Quote.Owner_CompanyName;
                                            vmeName = ((matchValue_2 == null) ? "" : matchValue_2.Value);
                                            let ownerPersonName;
                                            let oFName;
                                            const matchValue_3 = detail.Quote.Owner_FirstName;
                                            oFName = ((matchValue_3 == null) ? "" : matchValue_3.Value);
                                            let oLName;
                                            const matchValue_4 = detail.Quote.Owner_LastName;
                                            oLName = ((matchValue_4 == null) ? "" : matchValue_4.Value);
                                            ownerPersonName = (`${oFName} ${oLName}`);
                                            return append(singleton((children_15 = singleton_1(Quote_contactPhoneNumber(detail.Quote, detail.Partner)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_15))]]))), delay(() => append(singleton(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([vmeName])]])), delay(() => append(singleton(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([ownerPersonName])]])), delay(() => {
                                                let children_18;
                                                return append(singleton((children_18 = singleton_1(Address_address(detail.Quote.Address)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_18))]]))), delay(() => {
                                                    let children_19;
                                                    return singleton((children_19 = singleton_1(downloadLink(detail.Quote.QuoteDocument)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_19))]])));
                                                }));
                                            }))))));
                                        }));
                                    }));
                                }));
                            })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]);
                        }, sortByDescending((s) => s.Quote.Id.Value, quoteDetails.Data, {
                            Compare: comparePrimitives,
                        })))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_20))]])));
                    }));
                    return react.createElement(react.Fragment, {}, ...xs_2);
                },
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])), delay(() => singleton(createElement(Col, {
                classes: empty_1(),
                children: singleton_1(createElement(DisplayDeferred, {
                    data: data,
                    view: (dossiers) => {
                        let values;
                        return MuiHelpers_createElement(TablePagination, [["className", join(" ", [alignEnd])], ["rowsPerPage", state.PageSize], ["page", state.PageNumber], ["count", dossiers.TotalPages], ["onChangePage", (_arg_1, v) => {
                            dispatch(new Msg(6, v));
                        }], (values = (new Int32Array([])), ["rowsPerPageOptions", Array.from(values)]), ["onChangeRowsPerPage", (value_66) => {
                        }]]);
                    },
                })),
            }))));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_3);
}

export function DisplayQuoteInfoSyndic(displayQuoteInfoSyndicInputProps) {
    const getImage = displayQuoteInfoSyndicInputProps.getImage;
    const getFile = displayQuoteInfoSyndicInputProps.getFile;
    const downloadLink = displayQuoteInfoSyndicInputProps.downloadLink;
    const isPartner = displayQuoteInfoSyndicInputProps.isPartner;
    const customerUrl = displayQuoteInfoSyndicInputProps.customerUrl;
    const quoteDetails = displayQuoteInfoSyndicInputProps.quoteDetails;
    const snackbar = useSnackbar();
    return createElement(Row, {
        classes: singleton_1(Gap.L),
        children: singleton_1(createElement(Col, {
            classes: singleton_1(Gap.L),
            children: toList(delay(() => append(singleton(createElement(Row, {
                classes: singleton_1(Gap.L),
                children: toList(delay(() => {
                    let elems;
                    return append(singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), (elems = [createElement(Col, {
                        classes: empty_1(),
                        children: ofArray([createElement(InfoTitle, {
                            text: "Asbestattest",
                        }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                            data: toList(delay(() => append(singleton(["Status", createElement(QuoteStatusChipSyndic, {
                                status: quoteDetails.Quote.Status,
                            })]), delay(() => {
                                let matchValue, ai;
                                return append((matchValue = quoteDetails.Quote.TypeOfDossier, (matchValue.tag === 1) ? ((empty())) : singleton(["Aanleiding asbestinventaris", (ai = matchValue.fields[0], (ai.tag === 1) ? "Werken" : ((ai.tag === 2) ? "Actualisatie asbestinventaris-attest" : ((ai.tag === 3) ? "Sloop zonder SOP" : ((ai.tag === 4) ? "Sloop met SOP" : ((ai.tag === 5) ? "Werkgever" : ((ai.tag === 6) ? "Overdracht met vrijwillig, aanvullend onderzoek" : ((ai.tag === 7) ? "Generiek" : ((ai.tag === 8) ? "Generiek met vrijwillig, aanvullend onderzoek" : ((ai.tag === 9) ? "Gemene delen" : "Overdracht")))))))))])), delay(() => append(singleton(["Afspraak Plaatsbezoek", defaultArg(map(HomeVisitContactModule_string, quoteDetails.Quote.HomeVisitContact), "")]), delay(() => {
                                    let matchValue_1;
                                    return append((matchValue_1 = quoteDetails.Quote.HomeVisitContact, (matchValue_1 == null) ? ((empty())) : ((matchValue_1.tag === 3) ? append(singleton(["Afspraak Plaatsbezoek Naam", defaultArg(map((n) => n, quoteDetails.Quote.HomeVisitContactName), "")]), delay(() => singleton(["Afspraak Plaatsbezoek Telefoon", defaultArg(map((n_1) => n_1, quoteDetails.Quote.HomeVisitContactEmail), "")]))) : ((empty())))), delay(() => append(singleton(["Offerte PDF", downloadLink(quoteDetails.Quote.QuoteDocument)]), delay(() => singleton(["Prijs Offerte", defaultArg(map((n_2) => format('{0:' + "0.00" + '}', n_2.Value), quoteDetails.Quote.CustomPrice), "") + " €"])))));
                                }))));
                            })))),
                        })]),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])), delay(() => {
                        let elems_1;
                        return append(singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 1))), (elems_1 = [createElement(Col, {
                            classes: empty_1(),
                            children: ofArray([createElement(InfoTitle, {
                                text: "Syndic",
                            }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                                data: toList(delay(() => append(singleton(["Offerte nummer", quoteDetails.Quote.Code.Value]), delay(() => append(singleton(["Syndic", createElement(Link, {
                                    text: Customer2_name(quoteDetails.Customer),
                                    url: customerUrl(Customer2__get_Code(quoteDetails.Customer)),
                                })]), delay(() => {
                                    let client;
                                    return append(singleton(["VME", (client = quoteDetails.Quote.Client, (client.tag === 1) ? "Gevolmachtigde" : ((client.tag === 2) ? "Vereniging van mede-eigenaars" : "VME"))]), delay(() => append((!isPartner) ? singleton(["Geen standaard dossier", CheckBox(quoteDetails.Quote.ManualInvoice)]) : empty(), delay(() => ((!isPartner) ? singleton(["Korting", DisplayDiscount(quoteDetails.Quote.Discount)]) : empty())))));
                                })))))),
                            })]),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])), delay(() => {
                            let elems_2;
                            let vme;
                            const matchValue_3 = quoteDetails.Quote.VME_OrganizationName;
                            if (matchValue_3 == null) {
                                vme = ["", null];
                            }
                            else {
                                const organizationName = matchValue_3;
                                vme = ["", ""];
                            }
                            const otherOwnerStatus = defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, quoteDetails.Quote.OwnerStatusOther), "");
                            return singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 2))), (elems_2 = [createElement(Col, {
                                classes: empty_1(),
                                children: ofArray([createElement(InfoTitle, {
                                    text: "VME",
                                }), MuiHelpers_createElement(Divider, []), createElement(DisplayData, {
                                    data: toList(delay(() => {
                                        let matchValue_4;
                                        return append((matchValue_4 = quoteDetails.Quote.Owner_CompanyName, (matchValue_4 == null) ? ((empty())) : singleton(["Gebouw", NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_4)])), delay(() => {
                                            const ownerFirstname = defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, quoteDetails.Quote.Owner_FirstName), "");
                                            const ownerLastname = defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, quoteDetails.Quote.Owner_LastName), "");
                                            return append(singleton([quoteDetails.Quote.Owner_IsVME ? "Beheerder" : "Beheerder", `${ownerFirstname} ${ownerLastname}`]), delay(() => append(singleton(["Adres", Address_address(quoteDetails.Quote.Owner_Address)]), delay(() => append(singleton(["Emailadres", Email___UnsafeUnwrap_Z27D11339(quoteDetails.Quote.Owner_Email)]), delay(() => append(singleton(["Telefoon", defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, quoteDetails.Quote.Owner_PhoneNumber), "-")]), delay(() => append(singleton(["VME KBO nummer", defaultArg(map(VatNumber___UnsafeUnwrap_18C04ACB, quoteDetails.Quote.Owner_VatNumber), "-")]), delay(() => {
                                                const matchValue_5 = [isPartner, quoteDetails.Quote.VME_OrganizationName];
                                                return (matchValue_5[1] == null) ? (matchValue_5[0] ? singleton(["", null]) : singleton(["VME Organisatie Naam", "-"])) : singleton(["VME Organisatie Naam", matchValue_5[1].Value]);
                                            }))))))))));
                                        }));
                                    })),
                                })]),
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]));
                        }));
                    }));
                })),
            })), delay(() => {
                let elems_4, matchValue_10, matchValue_11, matchValue_12, matchValue_13, matchValue_14, matchValue_15, matchValue_16, value_50;
                const readOnlyCheckboxField = (label, checkedR) => MuiHelpers_createElement(FormControlLabel, [["label", label], ["control", MuiHelpers_createElement(Checkbox, [["disabled", true], ["checked", checkedR]])]]);
                return append(singleton(createElement(Row, {
                    classes: singleton_1(Gap.L),
                    children: singleton_1(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 3))), (elems_4 = [createElement(Col, {
                        classes: empty_1(),
                        children: ofArray([createElement(InfoTitle, {
                            text: "Inspectielocatie",
                        }), MuiHelpers_createElement(Divider, []), createElement(Row, {
                            classes: empty_1(),
                            children: ofArray([createElement(Col, {
                                classes: singleton_1(flexExpand),
                                children: toList(delay(() => append(singleton(createElement(DisplayData, {
                                    data: ofArray([["Type", InspectionLocation_type$0027(quoteDetails.Quote.InspectionLocation)], ["Straat", NonEmptyString___UnsafeUnwrap_Z73AD07C(quoteDetails.Quote.Address.Street)], ["Nummer", NonEmptyString___UnsafeUnwrap_Z73AD07C(quoteDetails.Quote.Address.Number)], ["Bus", defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, quoteDetails.Quote.Address.Bus), "--")], ["Postcode", NonEmptyString___UnsafeUnwrap_Z73AD07C(quoteDetails.Quote.Address.PostalCode)], ["Gemeente", NonEmptyString___UnsafeUnwrap_Z73AD07C(quoteDetails.Quote.Address.City)]]),
                                })), delay(() => {
                                    let elements, matchValue_7, matchValue_8, matchValue_9;
                                    const matchValue_6 = quoteDetails.Quote.InspectionLocation;
                                    switch (matchValue_6.tag) {
                                        case 14:
                                        case 16:
                                        case 15: {
                                            let insData;
                                            const xs = [MuiHelpers_createElement(RadioGroup, [["row", false], (elements = [readOnlyCheckboxField(InspectionLocation_extra(new InspectionLocationExtra(0)), (matchValue_7 = quoteDetails.Quote.InspectionLocationBerging, (matchValue_7 == null) ? false : matchValue_7)), readOnlyCheckboxField(InspectionLocation_extra(new InspectionLocationExtra(1)), (matchValue_8 = quoteDetails.Quote.InspectionLocationStaanplaats, (matchValue_8 == null) ? false : matchValue_8)), readOnlyCheckboxField(InspectionLocation_extra(new InspectionLocationExtra(2)), (matchValue_9 = quoteDetails.Quote.InspectionLocationGaragebox, (matchValue_9 == null) ? false : matchValue_9))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])];
                                            insData = react.createElement(react.Fragment, {}, ...xs);
                                            return singleton(createElement(DisplayData, {
                                                data: singleton_1(["Extra ruimtes aanwezig", insData]),
                                            }));
                                        }
                                        default: {
                                            return singleton(null);
                                        }
                                    }
                                })))),
                            }), createElement(Col, {
                                classes: singleton_1(flexExpand),
                                children: singleton_1(createElement(DisplayData, {
                                    data: ofArray([["Contact afspraak", Quote_contact(quoteDetails.Quote, quoteDetails.Partner)], ["Telefoon contactpersoon", Quote_contactPhoneNumber(quoteDetails.Quote, quoteDetails.Partner)], ["Gebouwdeel/Vleugel/Blok", (matchValue_10 = quoteDetails.Quote.BuildingDetail, (matchValue_10 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_10))], ["Wooneenheden", (matchValue_11 = quoteDetails.Quote.HousingUnit, (matchValue_11 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_11))], ["Jaar van constructie", (matchValue_12 = quoteDetails.Quote.YearsOfConstruction, (matchValue_12 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_12))], ["Jaar van renovatie", (matchValue_13 = quoteDetails.Quote.YearsOfRenovation, (matchValue_13 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_13))], ["Aantal ingangen in het gebouw", (matchValue_14 = quoteDetails.Quote.NumberOfEntrances, (matchValue_14 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_14))], ["Leidingisolatie (lopende meter)", (matchValue_15 = quoteDetails.Quote.PipeIsolationMeters, (matchValue_15 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_15))], ["Gangen per verdiep", (matchValue_16 = quoteDetails.Quote.HallwaysPerLevel, (matchValue_16 == null) ? "-" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_16))], ["Stooklokaal", (value_50 = defaultArg(quoteDetails.Quote.BoilerRoom, false), createElement(CheckIcon, {
                                        props: [],
                                        value: value_50,
                                    }))]]),
                                })),
                            })]),
                        })]),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])),
                })), delay(() => append(singleton(QuoteInvoice(quoteDetails)), delay(() => append(singleton(createElement(DocumentsList, {
                    quoteDocuments: quoteDetails.QuoteDocuments,
                    getFile: getFile,
                    deleteFile: void 0,
                })), delay(() => singleton(createElement(ImageList, {
                    quoteDocuments: quoteDetails.QuoteDocuments,
                    getFile: getFile,
                    getImage: getImage,
                    deleteFile: void 0,
                }))))))));
            })))),
        })),
    });
}

