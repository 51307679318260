import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.68.0/React.fs.js";
import { RouterModule_nav, RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { createObj } from "./fable_modules/fable-library.3.7.17/Util.js";
import { singleton, delay, toList } from "./fable_modules/fable-library.3.7.17/Seq.js";
import { mainRouter, login, appRouter } from "./AppRoutes.fs.js";
import { createElement } from "react";
import * as react from "react";
import { List } from "./pages/CustomerList.fs.js";
import { Create } from "./pages/CustomerCreate.fs.js";
import { Edit } from "./pages/CustomerEdit.fs.js";
import { Detail } from "./pages/CustomerDetail.fs.js";
import { List as List_1 } from "./pages/DossierList.fs.js";
import { Create as Create_1 } from "./pages/DossierCreate.fs.js";
import { Edit as Edit_1 } from "./pages/DossierEdit.fs.js";
import { Detail as Detail_1 } from "./pages/DossierDetail.fs.js";
import { CompensationsEdit } from "./pages/Compensations.fs.js";
import { Quotes } from "./pages/Quotes.fs.js";
import { QuoteDetail } from "./pages/QuoteDetail.fs.js";
import { singleton as singleton_2, empty, ofArray } from "./fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.25.0/./Remoting.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { normalizePublicCibRoutes, normalizeRoutes } from "./Server.fs.js";
import { toConsole, split, printf, toText } from "./fable_modules/fable-library.3.7.17/String.js";
import { ICibFormApi$reflection, IPartnerPortalApi$reflection } from "../../shared/Api.fs.js";
import { useAuth0 } from "@auth0/auth0-react";
import { PartnerId as PartnerId_1 } from "../../shared/Domain.fs.js";
import { Auth0PartnerUserPrivileges, Auth0PartnerUserClaims } from "../../shared/Auth0Constants.fs.js";
import { awaitPromise, startImmediate } from "./fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { map } from "./fable_modules/fable-library.3.7.17/Array.js";
import { Progress } from "../../DesignSystems/DesignSystem.fs.js";
import { AppBar } from "./components/AppBar.fs.js";
import { Login } from "./pages/Login.fs.js";
import { Create as Create_2 } from "./pages/CibForm.fs.js";

export function AppRouter(appRouterInputProps) {
    let elements;
    const user = appRouterInputProps.user;
    const api = appRouterInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    return RouterModule_router(createObj(ofArray([["onUrlChanged", patternInput[1]], (elements = toList(delay(() => {
        let children;
        const matchValue = appRouter(patternInput[0]);
        let pattern_matching_result, page, page_1, id, page_2, id_1, page_3, page_4, page_5, id_2, page_6, id_3, page_7, page_8, page_9, page_10, code, page_11;
        if (matchValue != null) {
            if (matchValue.tag === 1) {
                if (matchValue != null) {
                    pattern_matching_result = 0;
                    page = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 4) {
                if (matchValue != null) {
                    pattern_matching_result = 1;
                    page_1 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 3) {
                if (matchValue != null) {
                    pattern_matching_result = 2;
                    id = matchValue.fields[0];
                    page_2 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 2) {
                if (matchValue != null) {
                    pattern_matching_result = 3;
                    id_1 = matchValue.fields[0];
                    page_3 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 5) {
                if (matchValue != null) {
                    pattern_matching_result = 4;
                    page_4 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 8) {
                if (matchValue != null) {
                    pattern_matching_result = 5;
                    page_5 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 7) {
                if (matchValue != null) {
                    pattern_matching_result = 6;
                    id_2 = matchValue.fields[0];
                    page_6 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 6) {
                if (matchValue != null) {
                    pattern_matching_result = 7;
                    id_3 = matchValue.fields[0];
                    page_7 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 10) {
                if (matchValue != null) {
                    pattern_matching_result = 8;
                    page_8 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 11) {
                if (matchValue != null) {
                    pattern_matching_result = 9;
                    page_9 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 13) {
                if (matchValue != null) {
                    pattern_matching_result = 10;
                    page_10 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else if (matchValue.tag === 12) {
                if (matchValue != null) {
                    pattern_matching_result = 11;
                    code = matchValue.fields[0];
                    page_11 = matchValue;
                }
                else {
                    pattern_matching_result = 12;
                }
            }
            else {
                pattern_matching_result = 12;
            }
        }
        else {
            pattern_matching_result = 12;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton(createElement(List, {
                    currentPage: page,
                    api: api,
                }));
            }
            case 1: {
                return singleton(createElement(Create, {
                    currentPage: page_1,
                    api: api,
                }));
            }
            case 2: {
                return singleton(createElement(Edit, {
                    currentPage: page_2,
                    api: api,
                    customerCode: id,
                }));
            }
            case 3: {
                return singleton(createElement(Detail, {
                    currentPage: page_3,
                    api: api,
                    customerCode: id_1,
                }));
            }
            case 4: {
                return singleton(createElement(List_1, {
                    currentPage: page_4,
                    api: api,
                }));
            }
            case 5: {
                return singleton(createElement(Create_1, {
                    currentPage: page_5,
                    api: api,
                }));
            }
            case 6: {
                return singleton(createElement(Edit_1, {
                    currentPage: page_6,
                    api: api,
                    dossierCode: id_2,
                }));
            }
            case 7: {
                return singleton(createElement(Detail_1, {
                    currentPage: page_7,
                    api: api,
                    dossierCode: id_3,
                    auth0User: user,
                }));
            }
            case 8: {
                return singleton(createElement(CompensationsEdit, {
                    currentPage: page_8,
                    api: api,
                }));
            }
            case 9: {
                return singleton(createElement(CompensationsEdit, {
                    currentPage: page_9,
                    api: api,
                }));
            }
            case 10: {
                return singleton(createElement(Quotes, {
                    currentPage: page_10,
                    api: api,
                    auth0User: user,
                }));
            }
            case 11: {
                return singleton(createElement(QuoteDetail, {
                    currentPage: page_11,
                    api: api,
                    quoteCode: code,
                    auth0User: user,
                }));
            }
            case 12: {
                return singleton((children = ofArray([createElement("h1", {
                    children: ["Not found"],
                }), createElement("a", {
                    onClick: (_arg) => {
                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(login)), 1, 1);
                    },
                    children: "Go home",
                })]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                })));
            }
        }
    })), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

export function createApi(token) {
    let options_1;
    return Remoting_buildProxy_64DC51C((options_1 = RemotingModule_withRouteBuilder(normalizeRoutes, RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options_1)), IPartnerPortalApi$reflection());
}

export function createPublicCibFormApi() {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(normalizePublicCibRoutes, RemotingModule_createApi()), ICibFormApi$reflection());
}

export function App() {
    let user;
    const auth = useAuth0();
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const token = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(createApi(token));
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Auth0PartnerUserClaims([], new PartnerId_1(0), ""));
    const auth0User = patternInput_2[0];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            const matchValue = auth.user;
            if (matchValue == null) {
                return singleton_1.Zero();
            }
            else {
                return singleton_1.Bind(awaitPromise(auth.getIdTokenClaims()), (_arg_1) => {
                    const userClaims = _arg_1;
                    const tkn = userClaims.__raw;
                    patternInput_1[1](createApi(tkn));
                    patternInput[1](tkn);
                    patternInput_2[1](new Auth0PartnerUserClaims(map((p) => {
                        switch (p) {
                            case "Partner": {
                                return new Auth0PartnerUserPrivileges(0);
                            }
                            case "Syndic": {
                                return new Auth0PartnerUserPrivileges(1);
                            }
                            default: {
                                return new Auth0PartnerUserPrivileges(2);
                            }
                        }
                    }, split(userClaims.privilege, [","], null, 0)), new PartnerId_1(userClaims.partner_id), ""));
                    return singleton_1.Zero();
                });
            }
        }));
    }, [auth.isLoading]);
    const matchValue_1 = auth.user;
    if (matchValue_1 != null) {
        if ((user = matchValue_1, token === "")) {
            const user_1 = matchValue_1;
            return createElement(Progress, {
                classes: empty(),
            });
        }
        else {
            const user_2 = matchValue_1;
            return createElement(AppBar, {
                auth0User: auth0User,
                children: singleton_2(createElement(AppRouter, {
                    api: patternInput_1[0],
                    user: auth0User,
                })),
            });
        }
    }
    else if (auth.isLoading) {
        return createElement(Progress, {
            classes: empty(),
        });
    }
    else {
        return createElement(Login, null);
    }
}

export function MainRouter() {
    let elements;
    const auth = useAuth0();
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const currentUrl = patternInput[0];
    const publicCibApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(normalizePublicCibRoutes, RemotingModule_createApi()), ICibFormApi$reflection());
    return RouterModule_router(createObj(ofArray([["onUrlChanged", patternInput[1]], (elements = toList(delay(() => {
        toConsole(printf("current url %A"))(currentUrl);
        const matchValue = mainRouter(currentUrl);
        let pattern_matching_result, code;
        if (matchValue != null) {
            if (matchValue.tag === 0) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 9) {
                pattern_matching_result = 1;
                code = matchValue.fields[0];
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton(createElement(Login, null));
            }
            case 1: {
                return singleton(createElement(Create_2, {
                    api: publicCibApi,
                    code: code,
                }));
            }
            case 2: {
                return singleton(createElement(App, null));
            }
        }
    })), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

