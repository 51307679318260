import { Union, Record } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Types.js";
import { QuoteCode as QuoteCode_1, PartnerId$reflection, CustomerCode$reflection, QuoteCode$reflection } from "../../shared/Domain.fs.js";
import { union_type, record_type, bool_type, int32_type, array_type, string_type, option_type } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { DateRange$reflection } from "../FilterUtils.fs.js";
import { ofArray } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import { map, choose } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Array.js";
import { Quote_parseStatus } from "../../shared/Format.fs.js";
import { map as map_1 } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/Option.js";
import { fromDate } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/DateOffset.js";
import { QuoteSearchTerms } from "../../shared/DomainHelpers.fs.js";
import { toOption } from "../String.fs.js";
import { isNullOrWhiteSpace } from "../../partnerportal/src/fable_modules/fable-library.3.7.17/String.js";

export class State extends Record {
    constructor(QuoteCode, PartnerName, CustomerName, CustomerCode, Address, PhoneNumber, DateRange, PartnerId, Status, PageNumber, PageSize, PartnerPortal, Urgent) {
        super();
        this.QuoteCode = QuoteCode;
        this.PartnerName = PartnerName;
        this.CustomerName = CustomerName;
        this.CustomerCode = CustomerCode;
        this.Address = Address;
        this.PhoneNumber = PhoneNumber;
        this.DateRange = DateRange;
        this.PartnerId = PartnerId;
        this.Status = Status;
        this.PageNumber = (PageNumber | 0);
        this.PageSize = (PageSize | 0);
        this.PartnerPortal = PartnerPortal;
        this.Urgent = Urgent;
    }
}

export function State$reflection() {
    return record_type("QuoteFilter.State", [], State, () => [["QuoteCode", option_type(QuoteCode$reflection())], ["PartnerName", option_type(string_type)], ["CustomerName", option_type(string_type)], ["CustomerCode", option_type(CustomerCode$reflection())], ["Address", option_type(string_type)], ["PhoneNumber", option_type(string_type)], ["DateRange", option_type(DateRange$reflection())], ["PartnerId", option_type(PartnerId$reflection())], ["Status", array_type(string_type)], ["PageNumber", int32_type], ["PageSize", int32_type], ["PartnerPortal", option_type(bool_type)], ["Urgent", option_type(bool_type)]]);
}

export function searchTerms(state) {
    return new QuoteSearchTerms(state.QuoteCode, state.PartnerName, state.CustomerName, state.Address, state.PhoneNumber, ofArray(choose((x) => x, map(Quote_parseStatus, state.Status))), map_1((x_1) => fromDate(x_1.Date1), state.DateRange), map_1((x_2) => fromDate(x_2.Date2), state.DateRange), false, state.CustomerCode, state.PartnerPortal, state.Urgent, state.PartnerId);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetDossierId", "SetPartnerName", "SetCustomerName", "SetAddress", "SetPhoneNumber", "SetStatus", "SetPageNumber", "SetDateRange", "SetPartnerPortal", "SetUrgent"];
    }
}

export function Msg$reflection() {
    return union_type("QuoteFilter.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", array_type(string_type)]], [["Item", int32_type]], [["Item", option_type(DateRange$reflection())]], [["Item", option_type(bool_type)]], [["Item", option_type(bool_type)]]]);
}

export const initialState = new State(void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, [], 0, 25, void 0, void 0);

export function update(state, _arg) {
    switch (_arg.tag) {
        case 2: {
            return new State(state.QuoteCode, state.PartnerName, toOption(_arg.fields[0]), state.CustomerCode, state.Address, state.PhoneNumber, state.DateRange, state.PartnerId, state.Status, state.PageNumber, state.PageSize, state.PartnerPortal, state.Urgent);
        }
        case 0: {
            const dossierNumber = _arg.fields[0];
            if (isNullOrWhiteSpace(dossierNumber)) {
                return new State(void 0, state.PartnerName, state.CustomerName, state.CustomerCode, state.Address, state.PhoneNumber, state.DateRange, state.PartnerId, state.Status, state.PageNumber, state.PageSize, state.PartnerPortal, state.Urgent);
            }
            else {
                return new State(new QuoteCode_1(dossierNumber), state.PartnerName, state.CustomerName, state.CustomerCode, state.Address, state.PhoneNumber, state.DateRange, state.PartnerId, state.Status, state.PageNumber, state.PageSize, state.PartnerPortal, state.Urgent);
            }
        }
        case 3: {
            return new State(state.QuoteCode, state.PartnerName, state.CustomerName, state.CustomerCode, toOption(_arg.fields[0]), state.PhoneNumber, state.DateRange, state.PartnerId, state.Status, state.PageNumber, state.PageSize, state.PartnerPortal, state.Urgent);
        }
        case 4: {
            return new State(state.QuoteCode, state.PartnerName, state.CustomerName, state.CustomerCode, state.Address, toOption(_arg.fields[0]), state.DateRange, state.PartnerId, state.Status, state.PageNumber, state.PageSize, state.PartnerPortal, state.Urgent);
        }
        case 7: {
            return new State(state.QuoteCode, state.PartnerName, state.CustomerName, state.CustomerCode, state.Address, state.PhoneNumber, _arg.fields[0], state.PartnerId, state.Status, state.PageNumber, state.PageSize, state.PartnerPortal, state.Urgent);
        }
        case 5: {
            return new State(state.QuoteCode, state.PartnerName, state.CustomerName, state.CustomerCode, state.Address, state.PhoneNumber, state.DateRange, state.PartnerId, _arg.fields[0], state.PageNumber, state.PageSize, state.PartnerPortal, state.Urgent);
        }
        case 6: {
            return new State(state.QuoteCode, state.PartnerName, state.CustomerName, state.CustomerCode, state.Address, state.PhoneNumber, state.DateRange, state.PartnerId, state.Status, _arg.fields[0], state.PageSize, state.PartnerPortal, state.Urgent);
        }
        case 8: {
            return new State(state.QuoteCode, state.PartnerName, state.CustomerName, state.CustomerCode, state.Address, state.PhoneNumber, state.DateRange, state.PartnerId, state.Status, state.PageNumber, state.PageSize, _arg.fields[0], state.Urgent);
        }
        case 9: {
            return new State(state.QuoteCode, state.PartnerName, state.CustomerName, state.CustomerCode, state.Address, state.PhoneNumber, state.DateRange, state.PartnerId, state.Status, state.PageNumber, state.PageSize, state.PartnerPortal, _arg.fields[0]);
        }
        default: {
            return new State(state.QuoteCode, toOption(_arg.fields[0]), state.CustomerName, state.CustomerCode, state.Address, state.PhoneNumber, state.DateRange, state.PartnerId, state.Status, state.PageNumber, state.PageSize, state.PartnerPortal, state.Urgent);
        }
    }
}

