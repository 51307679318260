import { fss } from "../fable_modules/Fss-lib.Fable.1.0.3/FssFable.fs.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { ColorClass_Color__hex_Z721C83C5, ColorClass_Color__get_black, ColorClass_Color__get_white } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Color.fs.js";
import { BackgroundColor } from "../fable_modules/Fss-lib.Core.1.0.4/css/Background.fs.js";
import { Color } from "../fable_modules/Fss-lib.Core.1.0.4/css/Color.fs.js";
import { BorderBottomWidth, BorderBottomStyle, BorderBottomColor, BorderRightWidth, BorderRightStyle, BorderRightColor } from "../fable_modules/Fss-lib.Core.1.0.4/css/Border.fs.js";
import { BorderClasses_BorderStyleParent__get_solid } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Border.fs.js";
import { unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B, unitHelpers_DirectionalLength__value_Z498FEB3B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { sec, vh, px } from "../fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { Height, Width, MinWidth, MinHeight } from "../fable_modules/Fss-lib.Core.1.0.4/css/ContentSize.fs.js";
import { TransitionDuration__value_74CCD5DD, TransitionProperty__get_width } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Transition.fs.js";
import { TransitionTimingFunction, TransitionDuration, TransitionProperty } from "../fable_modules/Fss-lib.Core.1.0.4/css/Transition.fs.js";
import { AnimationClasses_AnimationTimingFunction__get_ease } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Animation.fs.js";
import { OverflowClasses_OverflowClass__get_hidden } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Overflow.fs.js";
import { Overflow } from "../fable_modules/Fss-lib.Core.1.0.4/css/Overflow.fs.js";
import { VisibilityClasses_Opacity__value_5E38073B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Visibility.fs.js";
import { Opacity } from "../fable_modules/Fss-lib.Core.1.0.4/css/Visibility.fs.js";
import { FlexClasses_JustifyContent__get_start } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Flex.fs.js";
import { FlexWrap, JustifyContent } from "../fable_modules/Fss-lib.Core.1.0.4/css/Flex.fs.js";
import { MarginRight, center, TextColor, Boldness, TextSize, hCenter, appBarLogo, spaceBetween, flexExpand, fullWidth, Padding, PaddingBottom as PaddingBottom_1, PaddingTop as PaddingTop_1, PaddingRight, PaddingLeft, Var } from "../../../Styles/Utils.fs.js";
import { append as append_1, empty as empty_1, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { CssRuleWithNone__get_none, CssRule__get_initial } from "../fable_modules/Fss-lib.Core.1.0.4/Types/MasterTypes.fs.js";
import { Auth0PartnerUserPrivileges, Auth0PartnerUserClaims__HasPrivilege_567ADD95 } from "../../../shared/Auth0Constants.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import ListItem from "@material-ui/core/ListItem";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { PaddingBottom, PaddingTop } from "../fable_modules/Fss-lib.Core.1.0.4/css/Padding.fs.js";
import Button from "@material-ui/core/Button";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";
import { RouterModule_urlSegments, RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.17/Types.js";
import { equals } from "../fable_modules/fable-library.3.7.17/Util.js";
import { Page, compensations, customers, quotes, dossiers, dossierCreate, appRouter } from "../AppRoutes.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { createElement } from "react";
import * as react from "react";
import Add from "@material-ui/icons/Add";
import Work from "@material-ui/icons/Work";
import WorkOutline from "@material-ui/icons/WorkOutline";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import Money from "@material-ui/icons/Money";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import Divider from "@material-ui/core/Divider";
import { useAuth0 } from "@auth0/auth0-react";
import { useUsername, useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import AppBar_1 from "@material-ui/core/AppBar";
import { BoxShadow } from "../fable_modules/Fss-lib.Core.1.0.4/css/BoxShadow.fs.js";
import Toolbar from "@material-ui/core/Toolbar";
import { logo } from "../Img.fs.js";
import IconButton from "@material-ui/core/IconButton";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { PriceListModal } from "./PriceListModal.fs.js";

export const drawerWidth = 195;

export function drawerStyle(isOpen) {
    return fss(toList(delay(() => append(singleton(ColorClass_Color__get_white(BackgroundColor)), delay(() => append(singleton(ColorClass_Color__get_black(Color)), delay(() => append(singleton(ColorClass_Color__hex_Z721C83C5(BorderRightColor, "f0f0f0")), delay(() => append(singleton(BorderClasses_BorderStyleParent__get_solid(BorderRightStyle)), delay(() => append(singleton(unitHelpers_DirectionalLength__value_Z498FEB3B(BorderRightWidth, px(1))), delay(() => append(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MinHeight, vh(100))), delay(() => append(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MinWidth, px(220))), delay(() => append(singleton(TransitionProperty__get_width(TransitionProperty)), delay(() => append(singleton(TransitionDuration__value_74CCD5DD(TransitionDuration, sec(0.2))), delay(() => append(singleton(AnimationClasses_AnimationTimingFunction__get_ease(TransitionTimingFunction)), delay(() => append(singleton(OverflowClasses_OverflowClass__get_hidden(Overflow)), delay(() => (isOpen ? append(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(drawerWidth))), delay(() => singleton(VisibilityClasses_Opacity__value_5E38073B(Opacity, 1)))) : singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(40)))))))))))))))))))))))))))));
}

export const menuButtonStyle = fss(ofArray([FlexClasses_JustifyContent__get_start(JustifyContent), ColorClass_Color__hex_Z721C83C5(Color, Var.Colors.TextLight)]));

export const menuButtonActive = fss(singleton_1(ColorClass_Color__hex_Z721C83C5(Color, "#47B9B3")));

export const flexNoWrap = fss(singleton_1(CssRule__get_initial(FlexWrap)));

export function cornerStyle(auth0User) {
    if (!Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(1))) {
        return fss(singleton_1(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, px(89))));
    }
    else {
        return fss(singleton_1(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, px(89))));
    }
}

export function MenuButton(menuButtonInputProps) {
    let elems;
    const active = menuButtonInputProps.active;
    const url = menuButtonInputProps.url;
    const icon = menuButtonInputProps.icon;
    const text = menuButtonInputProps.text;
    return MuiHelpers_createElement(ListItem, [["key", text], ["disableGutters", true], Feliz_prop__prop_fss_Static_72C268A9(toList(delay(() => append(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingTop, px(0))), delay(() => append(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(PaddingBottom, px(0))), delay(() => (active ? singleton(ColorClass_Color__hex_Z721C83C5(BackgroundColor, "f0f0f0")) : empty())))))))), (elems = [MuiHelpers_createElement(Button, [["className", join(" ", toList(delay(() => append(singleton(menuButtonStyle), delay(() => append(singleton(PaddingLeft.M), delay(() => append(singleton(PaddingRight.M), delay(() => append(singleton(PaddingTop_1.M), delay(() => append(singleton(PaddingBottom_1.M), delay(() => (active ? singleton(menuButtonActive) : empty()))))))))))))))], ["onClick", (_arg) => {
        RouterModule_nav(ofArray(url), 1, 1);
    }], ["startIcon", icon], ["fullWidth", true], ["children", text]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
}

export function Menu(auth0User) {
    let props;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const currentPage = patternInput[0];
    const isActivePage = (page) => {
        let copyOfStruct;
        if (currentPage == null) {
            return false;
        }
        else {
            const currentPage_1 = currentPage;
            console.log("Active:", (copyOfStruct = currentPage_1, toString(copyOfStruct)));
            return equals(currentPage_1, page);
        }
    };
    useReact_useEffect_Z101E1A95(() => {
        patternInput[1](appRouter(RouterModule_urlSegments(window.location.hash, 1)));
    }, [window.location.hash]);
    const addNewDossierButton = MuiHelpers_createElement(Button, [["size", "small"], ["onClick", (_arg) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierCreate)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Add, props))], ["children", "Dossier starten"]]);
    let dossiersLink;
    let icon;
    const props_2 = {};
    icon = react.createElement(Work, props_2);
    const url = Page$2__toUrl_2B594(dossiers);
    dossiersLink = ((active) => createElement(MenuButton, {
        text: "Dossiers",
        icon: icon,
        url: url,
        active: active,
    }));
    let quotesLink;
    let icon_1;
    const props_3 = {};
    icon_1 = react.createElement(WorkOutline, props_3);
    const url_1 = Page$2__toUrl_2B594(quotes);
    quotesLink = ((active_1) => createElement(MenuButton, {
        text: "Offertes",
        icon: icon_1,
        url: url_1,
        active: active_1,
    }));
    let customersLink;
    let icon_2;
    const props_4 = {};
    icon_2 = react.createElement(PeopleAlt, props_4);
    const url_2 = Page$2__toUrl_2B594(customers);
    customersLink = ((active_2) => createElement(MenuButton, {
        text: "Klanten",
        icon: icon_2,
        url: url_2,
        active: active_2,
    }));
    let compensationsLink;
    let icon_3;
    const props_5 = {};
    icon_3 = react.createElement(Money, props_5);
    const url_3 = Page$2__toUrl_2B594(compensations);
    compensationsLink = ((active_3) => createElement(MenuButton, {
        text: "Vergoedingen",
        icon: icon_3,
        url: url_3,
        active: active_3,
    }));
    return createElement(Col, {
        classes: empty_1(),
        children: toList(delay(() => append(Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(0)) ? singleton(createElement("div", {
            className: Padding.M,
            children: Interop_reactApi.Children.toArray([addNewDossierButton]),
        })) : empty(), delay(() => append(singleton(customersLink(isActivePage(new Page(1)))), delay(() => append(Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(1)) ? append(singleton(MuiHelpers_createElement(Divider, [])), delay(() => singleton(quotesLink(isActivePage(new Page(13)))))) : empty(), delay(() => append(singleton(MuiHelpers_createElement(Divider, [])), delay(() => append(singleton(dossiersLink(isActivePage(new Page(5)))), delay(() => append(singleton(MuiHelpers_createElement(Divider, [])), delay(() => ((!Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(1))) ? append(singleton(compensationsLink(isActivePage(new Page(10)))), delay(() => singleton(MuiHelpers_createElement(Divider, [])))) : empty()))))))))))))))),
    });
}

export function AppBar(appBarInputProps) {
    const children = appBarInputProps.children;
    const auth0User = appBarInputProps.auth0User;
    const auth = useAuth0();
    const drawerOpen = useToggle(true);
    const username = useUsername();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setPriceListModalOpen = patternInput[1];
    return createElement(Row, {
        classes: ofArray([fullWidth, flexNoWrap]),
        children: ofArray([createElement(Col, {
            classes: singleton_1(drawerStyle(drawerOpen.State)),
            children: ofArray([createElement(Row, {
                classes: singleton_1(cornerStyle(auth0User)),
                children: empty_1(),
            }), MuiHelpers_createElement(Divider, []), createElement(Menu, auth0User)]),
        }), createElement(Col, {
            classes: singleton_1(flexExpand),
            children: toList(delay(() => {
                let elems_4, elements;
                return append(singleton(MuiHelpers_createElement(AppBar_1, [["style", {
                    backgroundColor: "#FFFFFF",
                }], Feliz_prop__prop_fss_Static_72C268A9(ofArray([CssRuleWithNone__get_none(BoxShadow), ColorClass_Color__hex_Z721C83C5(BorderBottomColor, "f0f0f0"), BorderClasses_BorderStyleParent__get_solid(BorderBottomStyle), unitHelpers_DirectionalLength__value_Z498FEB3B(BorderBottomWidth, px(1))])), ["position", "sticky"], (elems_4 = [MuiHelpers_createElement(Toolbar, [["classes.root", spaceBetween], (elements = toList(delay(() => append(singleton(createElement("img", {
                    className: appBarLogo,
                    alt: "Asbest Consulting Logo",
                    src: logo,
                })), delay(() => append(Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(1)) ? singleton(createElement("div", {
                    className: join(" ", [hCenter, TextSize.Xl, Boldness.b600, TextColor.Secondary]),
                    children: Interop_reactApi.Children.toArray(["Syndic Portaal"]),
                })) : empty(), delay(() => singleton(createElement(Row, {
                    classes: singleton_1(center),
                    children: toList(delay(() => append(singleton(createElement("b", {
                        style: {
                            color: "#000000",
                        },
                        children: username,
                        className: join(" ", [MarginRight.M]),
                    })), delay(() => append((!Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(1))) ? singleton(MuiHelpers_createElement(Button, [["color", "primary"], ["variant", "contained"], ["className", join(" ", [MarginRight.M])], ["onClick", (_arg) => {
                        setPriceListModalOpen(true);
                    }], ["children", Interop_reactApi.Children.toArray(["Prijslijst"])]])) : empty(), delay(() => {
                        let elems_2, props_5;
                        return singleton(MuiHelpers_createElement(IconButton, [["color", "primary"], ["onClick", (_arg_1) => {
                            auth.logout();
                        }], (elems_2 = [(props_5 = {}, react.createElement(ExitToApp, props_5))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]));
                    })))))),
                })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])), delay(() => {
                    const children_2 = append_1(children, singleton_1(createElement(PriceListModal, {
                        isOpen: patternInput[0],
                        onClose: setPriceListModalOpen,
                    })));
                    return singleton(createElement(Row, {
                        classes: empty_1(),
                        children: children_2,
                    }));
                }));
            })),
        })]),
    });
}

