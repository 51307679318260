import { useSnackbar } from "notistack";
import { useDebouncedValue, useUsername, useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { QuoteStatus_get_list, QuoteStatus, NonEmptyString_get_Create, PhoneNumber_get_Create, SelectOptionData$1_Create_Z510A3DC0 } from "../../../shared/Domain.fs.js";
import { map, toArray, defaultArg, some } from "../fable_modules/fable-library.3.7.17/Option.js";
import { useReact_useMemo_CF4EA67, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { map as map_2, empty as empty_1, singleton as singleton_1, append, delay, toList, iterate } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { RouterModule_encodeParts, RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { sortByDescending, length, map as map_1, toArray as toArray_1, empty, singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { quoteDetail, dossierDetail } from "../AppRoutes.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { createElement } from "react";
import * as react from "react";
import { comparePrimitives, equals, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { printf, toConsole, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { alignEnd, TableHeaderCell, cursorPointer, MarginLeft, TextColor, fullWidth, MarginTop, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../../../Styles/Utils.fs.js";
import { DateParsing_parse, Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { ChooseContactQuote } from "../../../DesignSystems/Contacts.fs.js";
import { Col, Row } from "../../../Components/UtilComponents.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { fromDate } from "../fable_modules/fable-library.3.7.17/DateOffset.js";
import { Quote_status, Address_address, GeneralFormats_dateTimeStamp, GeneralFormats_dateTimeOffset } from "../../../shared/Format.fs.js";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { fromDateTimeOffset } from "../fable_modules/fable-library.3.7.17/Date.js";
import { Auth0PartnerUserPrivileges, Auth0PartnerUserClaims__HasPrivilege_567ADD95 } from "../../../shared/Auth0Constants.fs.js";
import { noContentMessage, QuoteStatusChip, QuoteStatusChipSyndic } from "../../../DesignSystems/DossierUtils.fs.js";
import { downloadFile } from "../DownloadFile.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { MultiSelect, TableHeader, FilterField } from "../../../DesignSystems/DesignSystem.fs.js";
import { searchTerms, initialState, update, Msg } from "../../../DesignSystems/quote/QuoteFilter.fs.js";
import { FilterFieldDateRange } from "../../../DesignSystems/FilterUtils.fs.js";
import TableHead from "@material-ui/core/TableHead";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { Page } from "../components/Page.fs.js";
import Paper from "@material-ui/core/Paper";

export function ConvertQuoteModal(convertQuoteModalInputProps) {
    let elems_2;
    const disableBtn = convertQuoteModalInputProps.disableBtn;
    const setStatus = convertQuoteModalInputProps.setStatus;
    const setShowModal = convertQuoteModalInputProps.setShowModal;
    const showModal = convertQuoteModalInputProps.showModal;
    const quoteDetails = convertQuoteModalInputProps.quoteDetails;
    const api = convertQuoteModalInputProps.api;
    const snackbar = useSnackbar();
    const buttonsDisabled = useToggle(false);
    const contact = useFormField(quoteDetails.Quote.HomeVisitContact, (arg_2) => some(SelectOptionData$1_Create_Z510A3DC0(arg_2)));
    const contactPhoneNo = useFormField(defaultArg(quoteDetails.Quote.HomeVisitContactEmail, ""), PhoneNumber_get_Create());
    const contactName = useFormField(defaultArg(quoteDetails.Quote.HomeVisitContactName, ""), NonEmptyString_get_Create());
    const username = useUsername();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const approvalTicked = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const approvalMeetingDate = patternInput_1[0];
    const invalidDate = useToggle(false);
    const onConvertConfirmation = (code, approvalMeetingDate_1) => {
        iterate((data) => {
            const matchValue_1 = data.Contact;
            if (matchValue_1 == null) {
                contact.ShowErrorState();
            }
            else {
                const contactDet = matchValue_1;
                startImmediate(singleton.Delay(() => {
                    disableBtn.On();
                    return singleton.Bind(api.ConvertQuoteToDossier(code, contactDet, data.ContactName, data.ContactEmail, approvalMeetingDate_1), (_arg) => {
                        let code_1;
                        const dossierCode = _arg;
                        return singleton.Combine((dossierCode.tag === 1) ? ((ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${dossierCode.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false)), singleton.Zero())) : ((code_1 = dossierCode.fields[0], (ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Successfully converted Quote to Dossier. Dossier code is ${code_1.Value}.`, SnackbarProp_Variant_Z609E1E86("info"), new EnqueueSnackbarOption(1, false)), (RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierDetail, code_1.Value)), 1, 1), (setStatus(new QuoteStatus(1)), singleton.Zero()))))), singleton.Delay(() => {
                            disableBtn.Off();
                            setShowModal(false);
                            return singleton.Zero();
                        }));
                    });
                }));
            }
        }, toArray(useFormValidator(contact.Validator, (unitVar_1, x) => {
            if (x == null) {
                return {
                    Contact: void 0,
                    ContactEmail: void 0,
                    ContactName: void 0,
                };
            }
            else {
                const x_1 = x;
                const matchValue = x_1.Value;
                switch (matchValue.tag) {
                    case 2:
                    case 3: {
                        const emailsVal = useFormValidator(contactPhoneNo.Validator, (r, x_3) => ({
                            ContactEmail: x_3,
                            ContactName: r.ContactName,
                        }), useFormValidator(contactName.Validator, (unitVar_2, x_2) => ({
                            ContactName: x_2,
                        }), useForm())).Validate();
                        if (emailsVal == null) {
                            return {
                                Contact: x_1.Value,
                                ContactEmail: void 0,
                                ContactName: void 0,
                            };
                        }
                        else {
                            const e = emailsVal;
                            return {
                                Contact: x_1.Value,
                                ContactEmail: e.ContactEmail,
                                ContactName: e.ContactName,
                            };
                        }
                    }
                    default: {
                        return {
                            Contact: x_1.Value,
                            ContactEmail: void 0,
                            ContactName: void 0,
                        };
                    }
                }
            }
        }, useForm()).Validate()));
    };
    return MuiHelpers_createElement(Modal, [["open", showModal], ["onClose", (_arg_2, v) => {
        setShowModal(false);
    }], ["onClick", (e_2) => {
        e_2.stopPropagation();
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.M, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], ["onClick", (e_3) => {
        e_3.stopPropagation();
    }], (elems_2 = toList(delay(() => {
        let elems, value_9;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = [(value_9 = (`Bent u zeker dat u de offerte ${quoteDetails.Quote.Code.Value} wilt omzetten naar een dossier?`), createElement("h2", {
            children: [value_9],
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton_1(createElement(ChooseContactQuote, {
            contactOption: contact,
            partnerName: username,
            isPartnerSelected: true,
            homeVisitContactName: contactName,
            homeVisitContactPhoneNo: contactPhoneNo,
            isOptional: false,
        })), delay(() => append(singleton_1(createElement(Row, {
            classes: singleton_2(MarginTop.L),
            children: singleton_2(createElement(Col, {
                classes: empty(),
                children: singleton_2(MuiHelpers_createElement(FormControlLabel, [["label", "Goedkeuring algemene vergadering"], ["control", MuiHelpers_createElement(Switch, [["onChange", (e_4) => {
                    patternInput[1](Browser_Types_Event__Event_get_Checked(e_4));
                }]])]])),
            })),
        })), delay(() => append(approvalTicked ? singleton_1(createElement(Row, {
            classes: singleton_2(MarginTop.S),
            children: singleton_2(createElement(Col, {
                classes: empty(),
                children: toList(delay(() => append(singleton_1(createElement("b", {
                    children: ["Datum"],
                })), delay(() => {
                    let dt;
                    return append(singleton_1(createElement("input", {
                        type: "date",
                        onChange: (ev) => {
                            iterate((n) => {
                                patternInput_1[1](fromDate(n));
                            }, toArray(DateParsing_parse(ev.target.value)));
                        },
                        className: join(" ", [fullWidth]),
                        value: (approvalMeetingDate == null) ? "" : ((dt = approvalMeetingDate, (toConsole(printf("date1 %A"))(dt), GeneralFormats_dateTimeOffset(dt)))),
                    })), delay(() => (invalidDate.State ? singleton_1(createElement("div", {
                        className: join(" ", [MarginTop.S, TextColor.Danger]),
                        children: Interop_reactApi.Children.toArray(["Please select a date"]),
                    })) : empty_1())));
                })))),
            })),
        })) : empty_1(), delay(() => singleton_1(createElement(Row, {
            classes: singleton_2(MarginTop.L),
            children: ofArray([MuiHelpers_createElement(Button, [["onClick", (e_5) => {
                if (approvalTicked) {
                    if (approvalMeetingDate == null) {
                        invalidDate.On();
                    }
                    else {
                        const dt_1 = approvalMeetingDate;
                        invalidDate.Off();
                        onConvertConfirmation(quoteDetails.Quote.Code, approvalMeetingDate);
                    }
                }
                else {
                    invalidDate.Off();
                    onConvertConfirmation(quoteDetails.Quote.Code, approvalMeetingDate);
                }
            }], ["className", join(" ", [])], ["disabled", disableBtn.State], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (e_6) => {
                setShowModal(false);
            }], ["disabled", disableBtn.State], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Annuleren"]])]),
        }))))))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]]);
}

export function QuoteTableRow(quoteTableRowInputProps) {
    let elems_8;
    const auth0User = quoteTableRowInputProps.auth0User;
    const url = quoteTableRowInputProps.url;
    const detail = quoteTableRowInputProps.detail;
    const api = quoteTableRowInputProps.api;
    const snackbar = useSnackbar();
    const disableBtn = useToggle(false);
    const patternInput = useFeliz_React__React_useState_Static_1505(detail.Quote.Status);
    const status = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowModal = patternInput_1[1];
    const xs_2 = [createElement(ConvertQuoteModal, {
        api: api,
        quoteDetails: detail,
        showModal: patternInput_1[0],
        setShowModal: setShowModal,
        setStatus: patternInput[1],
        disableBtn: disableBtn,
    }), MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["onClick", (_arg_3) => {
        window.open(RouterModule_encodeParts(ofArray(url(detail.Quote.Code)), 1), "_blank");
    }], ["key", detail.Quote.Id.Value], (elems_8 = toList(delay(() => append(singleton_1(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([detail.Quote.Code.Value])]])), delay(() => {
        let children_1;
        return append(singleton_1((children_1 = singleton_2(GeneralFormats_dateTimeStamp(fromDateTimeOffset(detail.Quote.CreatedOn, 0))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), delay(() => {
            let children_2;
            return append(singleton_1((children_2 = toList(delay(() => (Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(1)) ? singleton_1(createElement(QuoteStatusChipSyndic, {
                status: status,
            })) : singleton_1(createElement(QuoteStatusChip, {
                status: status,
            }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), delay(() => {
                let vmeName;
                const matchValue = detail.Quote.Owner_CompanyName;
                vmeName = ((matchValue == null) ? "" : matchValue.Value);
                let ownerPersonName;
                let oFName;
                const matchValue_1 = detail.Quote.Owner_FirstName;
                oFName = ((matchValue_1 == null) ? "" : matchValue_1.Value);
                let oLName;
                const matchValue_2 = detail.Quote.Owner_LastName;
                oLName = ((matchValue_2 == null) ? "" : matchValue_2.Value);
                ownerPersonName = (`${oFName} ${oLName}`);
                return append(singleton_1(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([vmeName])]])), delay(() => append(singleton_1(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([ownerPersonName])]])), delay(() => {
                    let children_5;
                    return append(singleton_1((children_5 = singleton_2(Address_address(detail.Quote.Address)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))), delay(() => {
                        let children_6;
                        return append(singleton_1((children_6 = toList(delay(() => (equals(status, new QuoteStatus(1)) ? singleton_1(null) : singleton_1(MuiHelpers_createElement(Button, [["disabled", disableBtn.State], ["onClick", (e_1) => {
                            e_1.preventDefault();
                            e_1.stopPropagation();
                            setShowModal(true);
                        }], ["variant", "contained"], ["color", "default"], ["children", "Omzetten naar dossier"]]))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))), delay(() => {
                            let children_7, filePath_1, filePath_2;
                            return singleton_1((children_7 = singleton_2((filePath_1 = detail.Quote.QuoteDocument, (filePath_1 == null) ? null : ((filePath_2 = filePath_1, createElement("a", {
                                target: "_blank",
                                href: "#",
                                onClick: (e) => {
                                    let filePath;
                                    e.preventDefault();
                                    e.stopPropagation();
                                    startImmediate((filePath = filePath_2, singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.GetDocument(filePath), (_arg) => {
                                        const file = _arg;
                                        if (file.tag === 1) {
                                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${file.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                            return singleton.Zero();
                                        }
                                        else {
                                            return singleton.Bind(downloadFile(file.fields[0], filePath), () => singleton.Return());
                                        }
                                    })), (_arg_2) => {
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${_arg_2.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                        return singleton.Zero();
                                    }))));
                                },
                                children: "Offerte PDF",
                            }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]])));
                        }));
                    }));
                }))));
            }));
        }));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

export function QuotesDataPartnerPortal(quotesDataPartnerPortalInputProps) {
    let elems_12, elems_11, children_9, children_8, children, children_1, children_2, children_3, children_4, children_5, children_6, children_7;
    const auth0User = quotesDataPartnerPortalInputProps.auth0User;
    const url = quotesDataPartnerPortalInputProps.url;
    const dispatch = quotesDataPartnerPortalInputProps.dispatch;
    const state = quotesDataPartnerPortalInputProps.state;
    const data = quotesDataPartnerPortalInputProps.data;
    const api = quotesDataPartnerPortalInputProps.api;
    const xs_1 = [MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_12 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_11 = [(children_9 = singleton_2((children_8 = ofArray([(children = singleton_2(createElement(Col, {
        classes: singleton_2(TableHeaderCell),
        children: ofArray([FilterField("Filter", (arg_1) => {
            dispatch(new Msg(0, arg_1));
        }, defaultArg(map((d) => d.Value, state.QuoteCode), "")), createElement(TableHeader, {
            text: "Offerte nummer",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton_2(createElement(Col, {
        classes: singleton_2(TableHeaderCell),
        children: ofArray([FilterFieldDateRange("Filter", (arg_3) => {
            dispatch(new Msg(7, arg_3));
        }, state.DateRange), createElement(TableHeader, {
            text: "Datum en tijd",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_2 = singleton_2(createElement(Col, {
        classes: singleton_2(TableHeaderCell),
        children: ofArray([createElement(MultiSelect, {
            label: "Filter",
            items: toArray_1(map_1(Quote_status, QuoteStatus_get_list())),
            v: state.Status,
            onChange: (arg_5) => {
                dispatch(new Msg(5, arg_5));
            },
        }), createElement(TableHeader, {
            text: "Status",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_3 = singleton_2(createElement(Col, {
        classes: singleton_2(TableHeaderCell),
        children: ofArray([FilterField("Filter", (arg_7) => {
            dispatch(new Msg(2, arg_7));
        }, defaultArg(state.CustomerName, "")), createElement(TableHeader, {
            text: "VME",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])), (children_4 = singleton_2(createElement(Col, {
        classes: singleton_2(TableHeaderCell),
        children: ofArray([FilterField("Filter", (arg_9) => {
            dispatch(new Msg(2, arg_9));
        }, defaultArg(state.CustomerName, "")), createElement(TableHeader, {
            text: "Beheerder",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]])), (children_5 = singleton_2(createElement(Col, {
        classes: singleton_2(TableHeaderCell),
        children: ofArray([FilterField("Filter", (arg_11) => {
            dispatch(new Msg(3, arg_11));
        }, defaultArg(state.Address, "")), createElement(TableHeader, {
            text: "Inspectielocatie",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]])), (children_6 = singleton_2(createElement(Col, {
        classes: singleton_2(TableHeaderCell),
        children: singleton_2(createElement(TableHeader, {
            text: "",
        })),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]])), (children_7 = singleton_2(createElement(Col, {
        classes: singleton_2(TableHeaderCell),
        children: singleton_2(createElement(TableHeader, {
            text: "",
        })),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]])), createElement(DisplayDeferred, {
        data: data,
        view: (quoteDetails) => {
            const xs = toList(delay(() => {
                let children_10;
                return (length(quoteDetails.Data) === 0) ? singleton_1(noContentMessage(8, "Er zijn nog geen offertes")) : singleton_1((children_10 = toList(delay(() => map_2((detail) => createElement(QuoteTableRow, {
                    api: api,
                    detail: detail,
                    url: url,
                    auth0User: auth0User,
                }), sortByDescending((s) => s.Quote.Id.Value, quoteDetails.Data, {
                    Compare: comparePrimitives,
                })))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_10))]])));
            }));
            return react.createElement(react.Fragment, {}, ...xs);
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]), createElement(Col, {
        classes: empty(),
        children: singleton_2(createElement(DisplayDeferred, {
            data: data,
            view: (dossiers) => {
                let values;
                return MuiHelpers_createElement(TablePagination, [["className", join(" ", [alignEnd])], ["rowsPerPage", state.PageSize], ["page", state.PageNumber], ["count", dossiers.TotalPages], ["onChangePage", (_arg, v) => {
                    dispatch(new Msg(6, v));
                }], (values = (new Int32Array([])), ["rowsPerPageOptions", Array.from(values)]), ["onChangeRowsPerPage", (value_29) => {
                }]]);
            },
        })),
    })];
    return react.createElement(react.Fragment, {}, ...xs_1);
}

export function Quotes(quotesInputProps) {
    let children;
    const auth0User = quotesInputProps.auth0User;
    const api = quotesInputProps.api;
    const currentPage = quotesInputProps.currentPage;
    const patternInput = useReact_useReducer_2B9E6EA0(update, initialState);
    const state_1 = patternInput[0];
    const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => searchTerms(state_1), [state_1]));
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPartnerQuotesDetails(new PagingQuery(state_1.PageNumber, state_1.PageSize), search), [state_1.PageNumber, state_1.PageSize, search]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Offertes",
        actions: [],
        children: [(children = singleton_2(createElement(QuotesDataPartnerPortal, {
            api: api,
            data: data,
            state: state_1,
            dispatch: patternInput[1],
            url: (code) => Page$2__toUrl_2B594(quoteDetail, code.Value),
            auth0User: auth0User,
        })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))],
    });
}

