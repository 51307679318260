import { createElement } from "react";
import { join } from "../partnerportal/src/fable_modules/fable-library.3.7.17/String.js";
import { grid, col, row } from "../Styles/Utils.fs.js";
import { cons } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import { Interop_reactApi } from "../partnerportal/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";

export function Row(rowInputProps) {
    const children = rowInputProps.children;
    const classes = rowInputProps.classes;
    return createElement("div", {
        className: join(" ", cons(row, classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Col(colInputProps) {
    const children = colInputProps.children;
    const classes = colInputProps.classes;
    return createElement("div", {
        className: join(" ", cons(col, classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Grid(gridInputProps) {
    const children = gridInputProps.children;
    const classes = gridInputProps.classes;
    return createElement("div", {
        className: join(" ", cons(grid, classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

