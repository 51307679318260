import { MuiHelpers_createElement } from "../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Card_1 from "@material-ui/core/Card";
import { printf, toConsole, split, isNullOrEmpty, join } from "../partnerportal/src/fable_modules/fable-library.3.7.17/String.js";
import { cursorPointer, paddingDataDisplayWide, paddingDataDisplay, TextColor, PaddingBottom, MarginBottom, cursorDefault, paddingPrint, error as error_1, alignCenter, Gap, progress, Boldness, Var, Padding } from "../Styles/Utils.fs.js";
import { Interop_reactApi } from "../partnerportal/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { stringHash, equals, createObj } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Util.js";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../partnerportal/src/fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { ColorClass_Color__hex_Z721C83C5 } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Color.fs.js";
import { Color } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/Color.fs.js";
import { empty as empty_1, cons, ofArray, singleton } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField_1 from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../partnerportal/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { collect, map, empty, singleton as singleton_1, append, delay, toList } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Seq.js";
import { contains, map as map_1, equalsWith, concat, mapIndexed } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Array.js";
import { Col, Row } from "../Components/UtilComponents.fs.js";
import Button from "@material-ui/core/Button";
import { parse } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Int32.js";
import Decimal from "../partnerportal/src/fable_modules/fable-library.3.7.17/Decimal.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import { newGuid } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Guid.js";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { union_type, getUnionCases, makeUnion, name as name_4 } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { value as value_27, some } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Option.js";
import { Union, toString } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Types.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Link_1 from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { keyValueList } from "../partnerportal/src/fable_modules/fable-library.3.7.17/MapUtil.js";
import CheckBox_1 from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import grey from "@material-ui/core/colors/grey";
import Chip from "@material-ui/core/Chip";
import { Appointment_status } from "../shared/Format.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { RouterModule_encodeParts } from "../partnerportal/src/fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import { unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { px } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { MaxWidth } from "../partnerportal/src/fable_modules/Fss-lib.Core.1.0.4/css/ContentSize.fs.js";
import { UrgentChip as UrgentChip_1, InfoChip } from "../Styles/ClientPageStyles.fs.js";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

export function Card(children) {
    return MuiHelpers_createElement(Card_1, [["className", join(" ", [Padding.M])], ["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function ErrorText(errorTextInputProps) {
    const text = errorTextInputProps.text;
    return createElement("div", createObj(ofArray([Feliz_prop__prop_fss_Static_72C268A9(singleton(ColorClass_Color__hex_Z721C83C5(Color, Var.Colors.Danger))), ["children", text]])));
}

export function TableHeader(tableHeaderInputProps) {
    const text = tableHeaderInputProps.text;
    return createElement("div", {
        className: join(" ", [Boldness.b600]),
        children: text,
    });
}

export function Progress(progressInputProps) {
    let elems;
    const classes = progressInputProps.classes;
    return createElement("div", createObj(ofArray([["className", join(" ", cons(progress, classes))], (elems = [MuiHelpers_createElement(LinearProgress, [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function TextField(formFieldHook, label, error, required) {
    return MuiHelpers_createElement(TextField_1, [["value", formFieldHook.FieldValue], ["onChange", (e) => {
        formFieldHook.SetFieldValue(Browser_Types_Event__Event_get_Value(e));
    }], ["variant", "standard"], ["label", label], ["error", formFieldHook.Error], ["helperText", formFieldHook.Error ? error : ""], ["required", required]]);
}

export function EmailField(formFieldHook, label, error, required) {
    return MuiHelpers_createElement(TextField_1, [["type", "email"], ["value", formFieldHook.FieldValue], ["onChange", (e) => {
        formFieldHook.SetFieldValue(Browser_Types_Event__Event_get_Value(e));
    }], ["variant", "standard"], ["label", label], ["error", formFieldHook.Error], ["helperText", formFieldHook.Error ? error : ""], ["required", required]]);
}

export function EmailFieldDisabled(formFieldHook, label, error, required, disabled) {
    return MuiHelpers_createElement(TextField_1, [["disabled", disabled], ["type", "email"], ["value", formFieldHook.FieldValue], ["onChange", (e) => {
        formFieldHook.SetFieldValue(Browser_Types_Event__Event_get_Value(e));
    }], ["variant", "standard"], ["label", label], ["error", formFieldHook.Error], ["helperText", formFieldHook.Error ? error : ""], ["required", required]]);
}

export function MultiEmailField(formFieldHook, label, error, required) {
    const patternInput = useFeliz_React__React_useState_Static_1505([""]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(isNullOrEmpty(formFieldHook.FieldValue) ? [""] : split(formFieldHook.FieldValue, [","], null, 0));
    const textState_1 = patternInput_1[0];
    const setTextState_1 = patternInput_1[1];
    const xs = toList(delay(() => append(mapIndexed((i_1, v_1) => createElement(Row, {
        classes: singleton(Gap.S),
        children: toList(delay(() => append(singleton_1(MuiHelpers_createElement(TextField_1, [["type", "email"], ["fullWidth", true], ["value", textState_1[i_1]], ["onChange", (e) => {
            const t = Browser_Types_Event__Event_get_Value(e);
            const updatedText = mapIndexed((i, v) => {
                if (i === i_1) {
                    return t;
                }
                else {
                    return v;
                }
            }, textState_1);
            setTextState_1(updatedText);
            formFieldHook.SetFieldValue(join(",", updatedText));
        }], ["variant", "standard"], ["label", label], ["error", formFieldHook.Error], ["helperText", formFieldHook.Error ? error : ""], ["required", required]])), delay(() => ((i_1 !== 0) ? singleton_1(MuiHelpers_createElement(Button, [["children", "Remove"], ["onClick", (_arg) => {
            const newState = textState_1.filter((v_2) => (v_2 !== textState_1[i_1]));
            setTextState_1(newState);
            formFieldHook.SetFieldValue(join(",", newState));
        }]])) : empty()))))),
    }), textState_1), delay(() => singleton_1(MuiHelpers_createElement(Button, [["children", "Email toevoegen"], ["onClick", (_arg_1) => {
        setTextState_1(concat([textState_1, [""]]));
    }]]))))));
    return react.createElement(react.Fragment, {}, ...xs);
}

export function FilterField(label, onChange, v) {
    return MuiHelpers_createElement(TextField_1, [["label", label], ["value", v], ["onChange", (ev) => {
        onChange(ev.target.value);
    }]]);
}

export function TextFieldDisabled(formFieldHook, label, error, required, disabled) {
    return MuiHelpers_createElement(TextField_1, [["value", formFieldHook.FieldValue], ["onChange", (e) => {
        formFieldHook.SetFieldValue(Browser_Types_Event__Event_get_Value(e));
    }], ["disabled", disabled], ["variant", "standard"], ["label", label], ["error", formFieldHook.Error], ["helperText", formFieldHook.Error ? error : ""], ["required", required]]);
}

export function NumberField(formFieldHook, label, error, required) {
    return MuiHelpers_createElement(TextField_1, [["type", "number"], ["value", formFieldHook.FieldValue], ["onChange", (e) => {
        const s = Browser_Types_Event__Event_get_Value(e);
        const i = parse(s, 511, false, 32) | 0;
        formFieldHook.SetFieldValue(i);
        toConsole(printf("%s %i"))(s)(i);
    }], ["variant", "standard"], ["label", label], ["error", formFieldHook.Error], ["helperText", formFieldHook.Error ? error : ""], ["required", required]]);
}

export function NumberFieldDecimal(formFieldHook, label, error, required) {
    return MuiHelpers_createElement(TextField_1, [["type", "number"], ["value", formFieldHook.FieldValue], ["onChange", (e) => {
        formFieldHook.SetFieldValue(new Decimal(Browser_Types_Event__Event_get_Value(e)));
    }], ["variant", "standard"], ["label", label], ["error", formFieldHook.Error], ["helperText", formFieldHook.Error ? error : ""], ["required", required]]);
}

export function NumberFieldFloat(formFieldHook, label, error, required, disabled, adornmentString) {
    return MuiHelpers_createElement(TextField_1, [["InputProps", {
        startAdornment: MuiHelpers_createElement(InputAdornment, [["position", "start"], ["children", adornmentString]]),
    }], ["type", "number"], ["value", formFieldHook.FieldValue], ["onChange", (e) => {
        const s = Browser_Types_Event__Event_get_Value(e);
        if (s.length === 0) {
            formFieldHook.SetFieldValue("0");
        }
        else {
            formFieldHook.SetFieldValue(s);
        }
    }], ["variant", "standard"], ["label", label], ["error", formFieldHook.Error], ["helperText", formFieldHook.Error ? error : ""], ["required", required], ["disabled", disabled]]);
}

export function FileField(formFieldHook, label, error, required) {
    let elems;
    let id;
    let copyOfStruct = newGuid();
    id = copyOfStruct;
    return createElement("label", createObj(ofArray([["htmlFor", id], (elems = [createElement("input", {
        id: id,
        style: {
            display: "none",
        },
        type: "file",
        onChange: (ev) => {
            const files = ev.target.files;
            if ((!(files == null)) && (files.length > 0)) {
                formFieldHook.SetFieldValue(files.item(0));
            }
        },
        onClick: (e) => {
            e.stopPropagation();
        },
        required: required,
    }), createElement(Row, {
        classes: singleton(Gap.M),
        children: ofArray([createElement(Col, {
            classes: empty_1(),
            children: singleton(MuiHelpers_createElement(Button, [["variant", "contained"], ["children", label], ["component", "span"]])),
        }), createElement(Col, {
            classes: singleton(alignCenter),
            children: toList(delay(() => {
                const matchValue = formFieldHook.FieldValue;
                if (matchValue == null) {
                    return singleton_1("Geen bestand geselecteerd");
                }
                else {
                    const file_1 = matchValue;
                    return singleton_1(file_1.name);
                }
            })),
        })]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function OptionField(formFieldHook, trueValue, label, error, required) {
    const radi = MuiHelpers_createElement(Radio, [["checked", equals(formFieldHook.FieldValue, trueValue)]]);
    return MuiHelpers_createElement(FormControlLabel, toList(delay(() => append(singleton_1(["label", label]), delay(() => append(singleton_1(["control", radi]), delay(() => append(singleton_1(["onChange", (e) => {
        if (Browser_Types_Event__Event_get_Checked(e)) {
            formFieldHook.SetFieldValue(trueValue);
        }
        else {
            formFieldHook.SetFieldValue(void 0);
        }
    }]), delay(() => (formFieldHook.Error ? singleton_1(["classes.label", error_1]) : singleton_1(["classes.label", ""])))))))))));
}

export function OptionFieldDisabled(formFieldHook, trueValue, label, error, required, disabled) {
    const radi = MuiHelpers_createElement(Radio, [["checked", equals(formFieldHook.FieldValue, trueValue)], ["disabled", disabled]]);
    return MuiHelpers_createElement(FormControlLabel, toList(delay(() => append(singleton_1(["disabled", disabled]), delay(() => append(singleton_1(["label", label]), delay(() => append(singleton_1(["control", radi]), delay(() => append(singleton_1(["onChange", (e) => {
        if (Browser_Types_Event__Event_get_Checked(e)) {
            formFieldHook.SetFieldValue(trueValue);
        }
        else {
            formFieldHook.SetFieldValue(void 0);
        }
    }]), delay(() => (formFieldHook.Error ? singleton_1(["classes.label", error_1]) : singleton_1(["classes.label", ""])))))))))))));
}

export function fromString(cases, s) {
    const matchValue = cases.filter((case$) => (name_4(case$) === s));
    if ((!equalsWith(equals, matchValue, null)) && (matchValue.length === 1)) {
        return some(makeUnion(matchValue[0], []));
    }
    else {
        return void 0;
    }
}

export function SelectOptionField(formFieldHook, label, error, required, t) {
    let elements, elems_1;
    const corpTypes = getUnionCases(t);
    let v;
    const matchValue = formFieldHook.FieldValue;
    if (matchValue == null) {
        v = "";
    }
    else {
        let copyOfStruct = value_27(matchValue);
        v = toString(copyOfStruct);
    }
    const children = ofArray([MuiHelpers_createElement(InputLabel, [(elements = [createElement("span", {
        children: [label],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elements))]), ["className", join(" ", toList(delay(() => (formFieldHook.Error ? singleton_1(error_1) : singleton_1("")))))]]), MuiHelpers_createElement(Select, [["required", required], ["value", v], ["onChange", (ev) => {
        const s = ev.target.value;
        toConsole(printf("selected %s"))(s);
        formFieldHook.SetFieldValue(fromString(corpTypes, s));
    }], (elems_1 = toList(delay(() => map((corp_1) => {
        const text = name_4(corp_1);
        return MuiHelpers_createElement(MenuItem, [["children", text], ["value", text]]);
    }, corpTypes))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])]);
    return MuiHelpers_createElement(FormControl, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function MultiSelect(multiSelectInputProps) {
    let elements_1, elems_1;
    const onChange = multiSelectInputProps.onChange;
    const v = multiSelectInputProps.v;
    const items = multiSelectInputProps.items;
    return MuiHelpers_createElement(FormControl, [(elements_1 = [MuiHelpers_createElement(InputLabel, [["children", multiSelectInputProps.label]]), MuiHelpers_createElement(Select, [["multiple", true], ["onChange", (e_1, _arg) => {
        onChange(Browser_Types_Event__Event_get_Value(e_1));
    }], ["value", v], ["renderValue", (x) => join(", ", x)], (elems_1 = map_1((item) => {
        let elements;
        return MuiHelpers_createElement(MenuItem, [["key", item], ["value", item], (elements = [MuiHelpers_createElement(Checkbox, [["checked", contains(item, v, {
            Equals: (x_1, y) => (x_1 === y),
            GetHashCode: stringHash,
        })]]), item], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
    }, items), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]);
}

export function RenderBreadcrumbs(renderBreadcrumbsInputProps) {
    const current = renderBreadcrumbsInputProps.current;
    const links = renderBreadcrumbsInputProps.links;
    const children = toList(delay(() => append(collect((matchValue) => singleton_1(MuiHelpers_createElement(Link_1, [["underline", "hover"], ["color", "inherit"], ["href", matchValue[1]], ["children", matchValue[0]]])), links), delay(() => singleton_1(MuiHelpers_createElement(Typography, [["color", "textPrimary"], ["children", current]]))))));
    return MuiHelpers_createElement(Breadcrumbs, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function CheckIcon(checkIconInputProps) {
    const value = checkIconInputProps.value;
    const props = checkIconInputProps.props;
    if (value) {
        const props_1 = keyValueList(props, 1);
        return react.createElement(CheckBox_1, props_1);
    }
    else {
        const props_2 = keyValueList(props, 1);
        return react.createElement(CheckBoxOutlineBlank, props_2);
    }
}

export function appointmentStatusColor(status) {
    switch (status.tag) {
        case 2: {
            return green["200"];
        }
        case 1: {
            return green["300"];
        }
        case 3: {
            return amber["800"];
        }
        default: {
            return grey["300"];
        }
    }
}

export function AppointmentStatusChip(appointmentStatusChipInputProps) {
    const status = appointmentStatusChipInputProps.status;
    return MuiHelpers_createElement(Chip, [["style", {
        backgroundColor: appointmentStatusColor(status),
    }], ["label", Appointment_status(status)]]);
}

export class DiscountOption extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoDiscount", "FlatDiscount", "PercentageDiscount"];
    }
}

export function DiscountOption$reflection() {
    return union_type("DesignSystem.DiscountOption", [], DiscountOption, () => [[], [], []]);
}

export function DiscountOption__get_Label(this$) {
    switch (this$.tag) {
        case 1: {
            return "Afgesproken prijs";
        }
        case 2: {
            return "Percentage korting";
        }
        default: {
            return "Geen korting";
        }
    }
}

export function Info(infoInputProps) {
    const primary = infoInputProps.primary;
    const children = singleton(MuiHelpers_createElement(ListItemText, [["secondary", infoInputProps.secondary], ["primary", primary]]));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function InfoTitle(infoTitleInputProps) {
    let elements;
    const text = infoTitleInputProps.text;
    return MuiHelpers_createElement(ListItem, [["classes.gutters", paddingPrint], (elements = [MuiHelpers_createElement(ListItemText, [["primary", createElement("div", {
        className: join(" ", [Boldness.b700, Padding.Xxs]),
        children: text,
    })]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
}

export function Link(linkInputProps) {
    const url = linkInputProps.url;
    const text = linkInputProps.text;
    return createElement("a", {
        target: "_blank",
        href: `${RouterModule_encodeParts(ofArray(url), 1)}`,
        children: `${text}`,
    });
}

export function InfoLink(infoLinkInputProps) {
    let elems;
    const url = infoLinkInputProps.url;
    const primary = infoLinkInputProps.primary;
    const secondary = infoLinkInputProps.secondary;
    const children = singleton(createElement(Row, {
        classes: empty_1(),
        children: singleton(createElement("div", createObj(singleton((elems = [createElement(Link, {
            text: primary,
            url: url,
        }), MuiHelpers_createElement(ListItemText, [["secondary", secondary]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))),
    }));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function InfoCheckbox(infoCheckboxInputProps) {
    let elems;
    const state = infoCheckboxInputProps.state;
    const secondary = infoCheckboxInputProps.secondary;
    const children = singleton(createElement(Row, {
        classes: empty_1(),
        children: singleton(createElement("div", createObj(singleton((elems = [MuiHelpers_createElement(Checkbox, [["className", join(" ", [cursorDefault, MarginBottom.Xxs, PaddingBottom.Xxs])], ["edge", "start"], ["checked", state]]), MuiHelpers_createElement(ListItemText, [["secondary", secondary]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))),
    }));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function DataHeader(dataHeaderInputProps) {
    const text = dataHeaderInputProps.text;
    return createElement("div", {
        className: join(" ", [TextColor.VeryLight]),
        children: text,
    });
}

export function DisplayData(displayDataInputProps) {
    let children_1;
    const data = displayDataInputProps.data;
    const children_2 = singleton((children_1 = toList(delay(() => collect((matchValue) => {
        let children, elems;
        return singleton_1((children = ofArray([MuiHelpers_createElement(TableCell, [["className", join(" ", [paddingPrint, paddingDataDisplay])], (elems = [createElement(DataHeader, {
            text: matchValue[0],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), MuiHelpers_createElement(TableCell, [["className", join(" ", [paddingPrint])], ["children", matchValue[1]]])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])));
    }, data))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])));
    return MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]);
}

export function DisplayDataWide(displayDataWideInputProps) {
    let children_1;
    const data = displayDataWideInputProps.data;
    const children_2 = singleton((children_1 = toList(delay(() => collect((matchValue) => {
        let children, elems;
        return singleton_1((children = ofArray([MuiHelpers_createElement(TableCell, [["className", join(" ", [paddingPrint, paddingDataDisplayWide])], (elems = [createElement(DataHeader, {
            text: matchValue[0],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), MuiHelpers_createElement(TableCell, [["className", join(" ", [paddingPrint])], ["children", matchValue[1]]])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])));
    }, data))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])));
    return MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]);
}

export function PartnerChip(partnerChipInputProps) {
    let elems;
    const onClick = partnerChipInputProps.onClick;
    const partner = partnerChipInputProps.partner;
    return createElement("div", createObj(ofArray([Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MaxWidth, px(300)))), (elems = [MuiHelpers_createElement(Chip, toList(delay(() => append(singleton_1(["label", `${partner.CompanyName.Value} - ${partner.Id.Value}`]), delay(() => {
        const matchValue = onClick;
        if (matchValue == null) {
            return empty();
        }
        else {
            const onClick_1 = matchValue;
            return singleton_1(["onClick", (_arg) => {
                onClick_1();
            }]);
        }
    })))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function CibFormChip(cibFormChipInputProps) {
    let elems;
    const onClick = cibFormChipInputProps.onClick;
    if (cibFormChipInputProps.isCibDossier) {
        return createElement("div", createObj(ofArray([Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MaxWidth, px(100)))), (elems = [MuiHelpers_createElement(Chip, toList(delay(() => append(singleton_1(["className", join(" ", [InfoChip])]), delay(() => append(singleton_1(["label", "CIB"]), delay(() => {
            const matchValue = onClick;
            if (matchValue == null) {
                return empty();
            }
            else {
                const onClick_1 = matchValue;
                return singleton_1(["onClick", (_arg) => {
                    onClick_1();
                }]);
            }
        })))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return null;
    }
}

export function UrgentChip(urgentChipInputProps) {
    let elems;
    if (urgentChipInputProps.isUrgent) {
        return createElement("div", createObj(ofArray([Feliz_prop__prop_fss_Static_72C268A9(singleton(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MaxWidth, px(100)))), (elems = [MuiHelpers_createElement(Chip, [["className", join(" ", [UrgentChip_1])], ["label", "Dringend"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return null;
    }
}

export function DownloadLink(downloadLinkInputProps) {
    const onDownload = downloadLinkInputProps.onDownload;
    const text = downloadLinkInputProps.text;
    return createElement("a", {
        target: "_blank",
        href: "#",
        onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            onDownload();
        },
        children: `${text}`,
    });
}

export function DownloadLinkWithTitle(downloadLinkWithTitleInputProps) {
    let elems;
    const onDownload = downloadLinkWithTitleInputProps.onDownload;
    const primary = downloadLinkWithTitleInputProps.primary;
    const secondary = downloadLinkWithTitleInputProps.secondary;
    const children = singleton(createElement(Row, {
        classes: empty_1(),
        children: singleton(createElement("div", createObj(singleton((elems = [createElement("a", {
            target: "_blank",
            href: "#",
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                onDownload();
            },
            children: `${primary}`,
        }), MuiHelpers_createElement(ListItemText, [["secondary", secondary]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))),
    }));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function CheckBox(state) {
    return MuiHelpers_createElement(Checkbox, [["className", join(" ", [cursorDefault, MarginBottom.Xxs, PaddingBottom.Xxs])], ["edge", "start"], ["checked", state]]);
}

export function DisplayDiscount(discount) {
    switch (discount.tag) {
        case 1: {
            return `€ ${discount.fields[0]}`;
        }
        case 2: {
            return (`${discount.fields[0]}`) + " %";
        }
        default: {
            return "-";
        }
    }
}

export function Collapsible(collapsibleInputProps) {
    let elems_1;
    const disabled = collapsibleInputProps.disabled;
    const collapseToggle = collapsibleInputProps.collapseToggle;
    const children = collapsibleInputProps.children;
    const title = collapsibleInputProps.title;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("h2", createObj(ofArray([["className", join(" ", [cursorPointer])], ["onClick", (_arg) => {
            collapseToggle.Toggle();
        }], (elems = [createElement(Row, {
            classes: singleton(alignCenter),
            children: toList(delay(() => {
                let props, props_1;
                return append(collapseToggle.State ? singleton_1((props = {}, react.createElement(ArrowRight, props))) : singleton_1((props_1 = {}, react.createElement(ArrowDropDown, props_1))), delay(() => singleton_1(title)));
            })),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => (((!collapseToggle.State) && (!disabled)) ? singleton_1(children) : empty())));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

