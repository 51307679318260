import { singleton } from "./fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { toString } from "./fable_modules/fable-library.3.7.17/Date.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.17/String.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.17/Choice.js";

export function downloadFile_CSV(api, query, searchTerms) {
    return singleton.Delay(() => singleton.Bind(api.ExportSelection_CSV(query, searchTerms), (_arg) => {
        const fileContents = _arg;
        let fileName;
        if (searchTerms == null) {
            fileName = "dossiers lijst";
        }
        else {
            const searchTerms_1 = searchTerms;
            const matchValue = [searchTerms_1.StartDate, searchTerms_1.EndDate];
            if (matchValue[0] == null) {
                if (matchValue[1] == null) {
                    fileName = "dossiers lijst";
                }
                else {
                    const endDate_1 = matchValue[1];
                    const endString_1 = toString(endDate_1, "dd-MM-yyyy");
                    fileName = (`dossierlijst_vóór_${endString_1}`);
                }
            }
            else if (matchValue[1] == null) {
                const startDate_1 = matchValue[0];
                const startString_1 = toString(startDate_1, "dd-MM-yyyy");
                fileName = (`dossierlijst_na_${startString_1}`);
            }
            else {
                const endDate = matchValue[1];
                const startDate = matchValue[0];
                const startString = toString(startDate, "dd-MM-yyyy");
                const endString = toString(endDate, "dd-MM-yyyy");
                fileName = (`dossiers_lijst_${startString}_${endString}`);
            }
        }
        if (fileContents.tag === 1) {
            throw (new Error(fileContents.fields[0]));
            return singleton.Zero();
        }
        else {
            const blob = new Blob([fileContents.fields[0].buffer], { 'type': "text/csv" });
            const dataUrl = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.style = "display: none";
            anchor.href = dataUrl;
            anchor.download = toText(printf("%s.csv"))(fileName);
            anchor.click();
            anchor.remove();
            return singleton.Zero();
        }
    }));
}

export function downloadFile(fileContents, filePath) {
    return singleton.Delay(() => {
        const blob = new Blob([fileContents.buffer], { 'type': "" });
        const dataUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.style = "display: none";
        anchor.href = dataUrl;
        anchor.download = toText(printf("%s"))(filePath.Value);
        anchor.click();
        anchor.remove();
        return singleton.Zero();
    });
}

export function getImage(getDoc, filePath) {
    return singleton.Delay(() => singleton.Bind(getDoc(filePath), (_arg) => {
        const fileContents = _arg;
        if (fileContents.tag === 1) {
            throw (new Error(fileContents.fields[0]));
            return singleton.Return(new FSharpResult$2(1, "error fetching document"));
        }
        else {
            const blob = new Blob([fileContents.fields[0].buffer], { 'type': "image/jpeg" });
            const dataUrl = window.URL.createObjectURL(blob);
            return singleton.Return(new FSharpResult$2(0, dataUrl));
        }
    }));
}

