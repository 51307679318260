import { useSnackbar } from "notistack";
import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { Integer_get_Create, Compensation_get_DefaultCompensation, Compensation, Decimal___UnsafeCreate_32C73145, Decimal_get_Create, SelectOptionData$1_Create_Z510A3DC0 } from "../../../shared/Domain.fs.js";
import { compare, fromParts } from "../fable_modules/fable-library.3.7.17/Decimal.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { createElement } from "react";
import * as react from "react";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.17/List.js";
import { MarginRight, MarginTop, Gap, PaddingBottom } from "../../../Styles/Utils.fs.js";
import { CompensationOptions } from "../../../DesignSystems/DossierUtils.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { iterate } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { compensationEdit, compensations } from "../AppRoutes.fs.js";
import { toArray } from "../fable_modules/fable-library.3.7.17/Option.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";
import Edit from "@material-ui/icons/Edit";
import { Page } from "../components/Page.fs.js";
import { Compensation_compensation } from "../../../shared/Format.fs.js";

export function CompensationEditForm(compensationEditFormInputProps) {
    const api = compensationEditFormInputProps.api;
    const compensation = compensationEditFormInputProps.compensation;
    const snackbar = useSnackbar();
    const compensationData = useFormField(compensation, SelectOptionData$1_Create_Z510A3DC0);
    const compensationCustom = useFormField((compensation.tag === 11) ? compensation.fields[0].Value : fromParts(0, 0, 0, false, 0), Decimal_get_Create());
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setDisableButtons = patternInput[1];
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCompensationAmount(), []);
    const form = useFormValidator(compensationData.Validator, (unitVar_1, x) => {
        if (x.Value.tag === 11) {
            if (compare(compensationCustom.FieldValue, fromParts(0, 0, 0, false, 0)) > 0) {
                return {
                    Compensation: new Compensation(11, Decimal___UnsafeCreate_32C73145(compensationCustom.FieldValue)),
                };
            }
            else {
                return {
                    Compensation: void 0,
                };
            }
        }
        else {
            return {
                Compensation: x.Value,
            };
        }
    }, useForm());
    return createElement(DisplayDeferred, {
        data: data,
        view: (compensation_2) => {
            const comp = (compensation_2 == null) ? Compensation_get_DefaultCompensation() : compensation_2;
            return createElement(Col, {
                classes: empty(),
                children: ofArray([createElement(Row, {
                    classes: singleton(PaddingBottom.M),
                    children: singleton("De prijslijst bovenaan is gebasseerd op de standaard vergoeding. Een afwijkende prijslijst kan via de backoffice aangevraagd worden."),
                }), createElement(Row, {
                    classes: singleton(PaddingBottom.S),
                    children: singleton("Standaardprijzen zonder vergoeding (excl. btw):"),
                }), createElement(Row, {
                    classes: ofArray([Gap.M, PaddingBottom.M]),
                    children: ofArray([createElement(Col, {
                        classes: empty(),
                        children: ofArray([createElement(Row, {
                            classes: empty(),
                            children: singleton("Woonhuis"),
                        }), createElement(Row, {
                            classes: empty(),
                            children: singleton("Appartement"),
                        }), createElement(Row, {
                            classes: empty(),
                            children: singleton("Studio/éénslaapkamerappartement"),
                        })]),
                    }), createElement(Col, {
                        classes: empty(),
                        children: ofArray([createElement(Row, {
                            classes: empty(),
                            children: singleton("€ 470"),
                        }), createElement(Row, {
                            classes: empty(),
                            children: singleton("€ 400"),
                        }), createElement(Row, {
                            classes: empty(),
                            children: singleton("€ 375"),
                        })]),
                    })]),
                }), createElement(CompensationOptions, {
                    compensationData: compensationData,
                    compensationCustom: compensationCustom,
                    showHeader: false,
                }), createElement(Row, {
                    classes: ofArray([Gap.M, MarginTop.L]),
                    children: singleton(MuiHelpers_createElement(Button, [["disabled", patternInput[0]], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg_2) => {
                        iterate((data_1) => {
                            iterate((data_2) => {
                                setDisableButtons(true);
                                console.log(data_2);
                                startImmediate(singleton_1.Delay(() => singleton_1.TryFinally(singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => singleton_1.Bind(api.UpdateCompensationAmount(data_2), (_arg) => {
                                    const result = _arg;
                                    if (result.tag === 0) {
                                        setDisableButtons(false);
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Compensation updated", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(compensations)), 1, 1);
                                        return singleton_1.Zero();
                                    }
                                    else {
                                        const err = result.fields[0];
                                        setDisableButtons(false);
                                        console.log(err);
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                        return singleton_1.Zero();
                                    }
                                })), (_arg_1) => {
                                    const e = _arg_1;
                                    setDisableButtons(false);
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.  [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    console.log(e);
                                    return singleton_1.Zero();
                                })), () => {
                                })));
                            }, toArray(data_1.Compensation));
                        }, toArray(form.Validate()));
                    }], ["children", Interop_reactApi.Children.toArray(["Bewaren"])]])),
                })]),
            });
        },
    });
}

export function Compensations(compensationsInputProps) {
    let props;
    const api = compensationsInputProps.api;
    const currentPage = compensationsInputProps.currentPage;
    const snackbar = useSnackbar();
    const compensationData = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const compensationCustom = useFormField(0, Integer_get_Create());
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCompensationAmount(), []);
    const editButton = MuiHelpers_createElement(Button, [["onClick", (_arg) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(compensationEdit)), 1, 1);
    }], ["className", join(" ", [MarginRight.M])], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Edit, props))], ["children", "Bewerken"]]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "",
        actions: [editButton],
        children: [createElement(DisplayDeferred, {
            data: data,
            view: (compensation) => {
                let value_7;
                if (compensation == null) {
                    return "No data!";
                }
                else {
                    const compensation_1 = compensation;
                    return createElement(Col, {
                        classes: empty(),
                        children: singleton((value_7 = Compensation_compensation(compensation_1), createElement("h2", {
                            children: [value_7],
                        }))),
                    });
                }
            },
        })],
    });
}

export function CompensationsEdit(compensationsEditInputProps) {
    const api = compensationsEditInputProps.api;
    const currentPage = compensationsEditInputProps.currentPage;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCompensationAmount(), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Vergoedingen",
        actions: [],
        children: [createElement(DisplayDeferred, {
            data: data,
            view: (compensation) => {
                const comp = (compensation == null) ? (new Compensation(2)) : compensation;
                return createElement(CompensationEditForm, {
                    compensation: comp,
                    api: api,
                });
            },
        })],
    });
}

