import { useSnackbar } from "notistack";
import { useUsername, useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { QuoteCode, QuoteStatus, NonEmptyString_get_Create, PhoneNumber_get_Create, SelectOptionData$1_Create_Z510A3DC0 } from "../../../shared/Domain.fs.js";
import { toArray, defaultArg, some } from "../fable_modules/fable-library.3.7.17/Option.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList, iterate } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { join, printf, toConsole } from "../fable_modules/fable-library.3.7.17/String.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { empty, singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { customerDetail, dossierDetail as dossierDetail_1 } from "../AppRoutes.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { MarginRight, MarginLeft, TextColor, fullWidth, MarginTop, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../../../Styles/Utils.fs.js";
import { DateParsing_parse, Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { ChooseContactQuote } from "../../../DesignSystems/Contacts.fs.js";
import { Col, Row } from "../../../Components/UtilComponents.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { fromDate } from "../fable_modules/fable-library.3.7.17/DateOffset.js";
import { GeneralFormats_dateTimeOffset } from "../../../shared/Format.fs.js";
import Button from "@material-ui/core/Button";
import { getImage, downloadFile } from "../DownloadFile.fs.js";
import { Page } from "../components/Page.fs.js";
import { Auth0PartnerUserPrivileges, Auth0PartnerUserClaims__HasPrivilege_567ADD95 } from "../../../shared/Auth0Constants.fs.js";
import { DisplayQuoteInfoSyndic } from "../../../DesignSystems/quote/QuoteDetailsSyndic.fs.js";
import { DisplayQuoteInfo } from "../../../DesignSystems/quote/QuoteDetails.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { ErrorText } from "../../../DesignSystems/DesignSystem.fs.js";

export function ConvertQuoteModal(convertQuoteModalInputProps) {
    let elems_2;
    const disableBtn = convertQuoteModalInputProps.disableBtn;
    const setStatus = convertQuoteModalInputProps.setStatus;
    const setShowModal = convertQuoteModalInputProps.setShowModal;
    const showModal = convertQuoteModalInputProps.showModal;
    const quoteDetails = convertQuoteModalInputProps.quoteDetails;
    const api = convertQuoteModalInputProps.api;
    const snackbar = useSnackbar();
    const buttonsDisabled = useToggle(false);
    const contact = useFormField(quoteDetails.Quote.HomeVisitContact, (arg_2) => some(SelectOptionData$1_Create_Z510A3DC0(arg_2)));
    const contactPhoneNo = useFormField(defaultArg(quoteDetails.Quote.HomeVisitContactEmail, ""), PhoneNumber_get_Create());
    const contactName = useFormField(defaultArg(quoteDetails.Quote.HomeVisitContactName, ""), NonEmptyString_get_Create());
    const username = useUsername();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setApprovalTicked = patternInput[1];
    const approvalTicked = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setApprovalMeetingDate = patternInput_1[1];
    const approvalMeetingDate = patternInput_1[0];
    const invalidDate = useToggle(false);
    const onConvertConfirmation = (code, approvalMeetingDate_1) => {
        iterate((data) => {
            const matchValue_1 = data.Contact;
            if (matchValue_1 == null) {
                contact.ShowErrorState();
            }
            else {
                const contactDet = matchValue_1;
                startImmediate(singleton.Delay(() => {
                    disableBtn.On();
                    toConsole(printf("approvalMeetingDate %A"))(approvalMeetingDate_1);
                    return singleton.Bind(api.ConvertQuoteToDossier(code, contactDet, data.ContactName, data.ContactEmail, approvalMeetingDate_1), (_arg) => {
                        let code_1;
                        const dossierCode = _arg;
                        return singleton.Combine((dossierCode.tag === 1) ? ((ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${dossierCode.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false)), singleton.Zero())) : ((code_1 = dossierCode.fields[0], (ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Successfully converted Offerte to Dossier. Dossier code is ${code_1.Value}.`, SnackbarProp_Variant_Z609E1E86("info"), new EnqueueSnackbarOption(1, false)), (RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierDetail_1, code_1.Value)), 1, 1), (setStatus(new QuoteStatus(1)), singleton.Zero()))))), singleton.Delay(() => {
                            disableBtn.Off();
                            setShowModal(false);
                            return singleton.Zero();
                        }));
                    });
                }));
            }
        }, toArray(useFormValidator(contact.Validator, (unitVar_1, x) => {
            if (x == null) {
                return {
                    Contact: void 0,
                    ContactEmail: void 0,
                    ContactName: void 0,
                };
            }
            else {
                const x_1 = x;
                const matchValue = x_1.Value;
                switch (matchValue.tag) {
                    case 2:
                    case 3: {
                        const emailsVal = useFormValidator(contactPhoneNo.Validator, (r, x_3) => ({
                            ContactEmail: x_3,
                            ContactName: r.ContactName,
                        }), useFormValidator(contactName.Validator, (unitVar_2, x_2) => ({
                            ContactName: x_2,
                        }), useForm())).Validate();
                        if (emailsVal == null) {
                            return {
                                Contact: x_1.Value,
                                ContactEmail: void 0,
                                ContactName: void 0,
                            };
                        }
                        else {
                            const e = emailsVal;
                            return {
                                Contact: x_1.Value,
                                ContactEmail: e.ContactEmail,
                                ContactName: e.ContactName,
                            };
                        }
                    }
                    default: {
                        return {
                            Contact: x_1.Value,
                            ContactEmail: void 0,
                            ContactName: void 0,
                        };
                    }
                }
            }
        }, useForm()).Validate()));
    };
    return MuiHelpers_createElement(Modal, [["open", showModal], ["onClose", (_arg_2, v) => {
        setShowModal(false);
    }], ["onClick", (e_2) => {
        e_2.stopPropagation();
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.M, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], ["onClick", (e_3) => {
        e_3.stopPropagation();
    }], (elems_2 = toList(delay(() => {
        let elems, value_9;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = [(value_9 = (`Bent u zeker dat u de offerte ${quoteDetails.Quote.Code.Value} wilt omzetten naar een dossier?`), createElement("h2", {
            children: [value_9],
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton_1(createElement(ChooseContactQuote, {
            contactOption: contact,
            partnerName: username,
            isPartnerSelected: true,
            homeVisitContactName: contactName,
            homeVisitContactPhoneNo: contactPhoneNo,
            isOptional: false,
        })), delay(() => append(singleton_1(createElement(Row, {
            classes: singleton_2(MarginTop.L),
            children: singleton_2(createElement(Col, {
                classes: empty(),
                children: singleton_2(MuiHelpers_createElement(FormControlLabel, [["label", "Goedkeuring algemene vergadering"], ["control", MuiHelpers_createElement(Switch, [["onChange", (e_4) => {
                    setApprovalTicked(Browser_Types_Event__Event_get_Checked(e_4));
                }]])]])),
            })),
        })), delay(() => append(approvalTicked ? singleton_1(createElement(Row, {
            classes: singleton_2(MarginTop.S),
            children: singleton_2(createElement(Col, {
                classes: empty(),
                children: toList(delay(() => append(singleton_1(createElement("b", {
                    children: ["Datum"],
                })), delay(() => {
                    let dt;
                    return append(singleton_1(createElement("input", {
                        type: "date",
                        onChange: (ev) => {
                            iterate((n) => {
                                setApprovalMeetingDate(fromDate(n));
                            }, toArray(DateParsing_parse(ev.target.value)));
                        },
                        className: join(" ", [fullWidth]),
                        value: (approvalMeetingDate == null) ? "" : ((dt = approvalMeetingDate, (toConsole(printf("date1 %A"))(dt), GeneralFormats_dateTimeOffset(dt)))),
                    })), delay(() => (invalidDate.State ? singleton_1(createElement("div", {
                        className: join(" ", [MarginTop.S, TextColor.Danger]),
                        children: Interop_reactApi.Children.toArray(["Please select a date"]),
                    })) : empty_1())));
                })))),
            })),
        })) : empty_1(), delay(() => singleton_1(createElement(Row, {
            classes: singleton_2(MarginTop.L),
            children: ofArray([MuiHelpers_createElement(Button, [["onClick", (e_5) => {
                if (approvalTicked) {
                    if (approvalMeetingDate == null) {
                        invalidDate.On();
                    }
                    else {
                        const dt_1 = approvalMeetingDate;
                        invalidDate.Off();
                        onConvertConfirmation(quoteDetails.Quote.Code, approvalMeetingDate);
                    }
                }
                else {
                    invalidDate.Off();
                    onConvertConfirmation(quoteDetails.Quote.Code, approvalMeetingDate);
                }
            }], ["className", join(" ", [])], ["disabled", disableBtn.State], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (e_6) => {
                setShowModal(false);
                setApprovalMeetingDate(void 0);
                setApprovalTicked(false);
            }], ["disabled", disableBtn.State], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Annuleren"]])]),
        }))))))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]]);
}

export function RenderQuoteDetail(renderQuoteDetailInputProps) {
    const auth0User = renderQuoteDetailInputProps.auth0User;
    const quoteDetail = renderQuoteDetailInputProps.quoteDetail;
    const dossierCode = renderQuoteDetailInputProps.dossierCode;
    const api = renderQuoteDetailInputProps.api;
    const currentPage = renderQuoteDetailInputProps.currentPage;
    const snackbar = useSnackbar();
    const disableBtn = useToggle(false);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowModal = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(quoteDetail.Quote.Status);
    const DownloadLink = (filePath_1) => {
        if (filePath_1 == null) {
            return null;
        }
        else {
            const filePath_2 = filePath_1;
            return createElement("a", {
                target: "_blank",
                href: "#",
                onClick: (e) => {
                    let filePath;
                    e.preventDefault();
                    e.stopPropagation();
                    startImmediate((filePath = filePath_2, singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.GetDocument(filePath), (_arg) => {
                        const file = _arg;
                        if (file.tag === 1) {
                            const err = file.fields[0];
                            console.error(err);
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton.Zero();
                        }
                        else {
                            return singleton.Bind(downloadFile(file.fields[0], filePath), () => singleton.Return());
                        }
                    })), (_arg_2) => {
                        const ex = _arg_2;
                        console.error(ex);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${ex.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }))));
                },
                children: "Download",
            });
        }
    };
    const convertBtn = equals(quoteDetail.Quote.Status, new QuoteStatus(1)) ? null : MuiHelpers_createElement(Button, [["disabled", disableBtn.State], ["onClick", (e_1) => {
        e_1.preventDefault();
        e_1.stopPropagation();
        setShowModal(true);
    }], ["className", join(" ", [MarginRight.S])], ["variant", "contained"], ["color", "default"], ["children", "Omzetten naar dossier"]]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "",
        actions: [convertBtn],
        children: toList(delay(() => (Auth0PartnerUserClaims__HasPrivilege_567ADD95(auth0User, new Auth0PartnerUserPrivileges(1)) ? append(singleton_1(createElement(ConvertQuoteModal, {
            api: api,
            quoteDetails: quoteDetail,
            showModal: patternInput[0],
            setShowModal: setShowModal,
            setStatus: patternInput_1[1],
            disableBtn: disableBtn,
        })), delay(() => singleton_1(createElement(DisplayQuoteInfoSyndic, {
            quoteDetails: quoteDetail,
            customerUrl: (customerId) => Page$2__toUrl_2B594(customerDetail, customerId.Value),
            isPartner: true,
            downloadLink: DownloadLink,
            getFile: api.GetDocument,
            getImage: (getDoc) => ((filePath_3) => getImage(getDoc, filePath_3)),
        })))) : singleton_1(createElement(DisplayQuoteInfo, {
            quoteDetails: quoteDetail,
            customerUrl: (customerId_1) => Page$2__toUrl_2B594(customerDetail, customerId_1.Value),
            isPartner: true,
            downloadLink: DownloadLink,
            getFile: api.GetDocument,
            getImage: (getDoc_1) => ((filePath_4) => getImage(getDoc_1, filePath_4)),
        }))))),
    });
}

export function QuoteDetail(quoteDetailInputProps) {
    const auth0User = quoteDetailInputProps.auth0User;
    const quoteCode = quoteDetailInputProps.quoteCode;
    const api = quoteDetailInputProps.api;
    const currentPage = quoteDetailInputProps.currentPage;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetQuoteDetails(new QuoteCode(quoteCode)), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (dossierDetails) => {
            if (dossierDetails == null) {
                return createElement(Page, {
                    currentPage: currentPage,
                    title: "",
                    actions: [],
                    children: [createElement(ErrorText, {
                        text: `Geen Quotes gevonden met code ${quoteCode}`,
                    })],
                });
            }
            else {
                const dossierDetail = dossierDetails;
                return createElement(RenderQuoteDetail, {
                    currentPage: currentPage,
                    api: api,
                    dossierCode: quoteCode,
                    quoteDetail: dossierDetail,
                    auth0User: auth0User,
                });
            }
        },
    });
}

