import { Record } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Types.js";
import { record_type, class_type } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { useToggle } from "../CustomHooks/CustomHooks.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../partnerportal/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { addDays as addDays_1, month, year, today, create, addMonths, fromDateTimeOffset, utcNow, toString, date } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Date.js";
import { createElement } from "react";
import { join, printf, toConsole } from "../partnerportal/src/fable_modules/fable-library.3.7.17/String.js";
import { MuiHelpers_createElement } from "../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { createObj } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Util.js";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import { PaddingTop, MarginTop, fullWidth, Gap, Padding } from "../Styles/Utils.fs.js";
import { Col, Row } from "../Components/UtilComponents.fs.js";
import { ofArray, empty, singleton } from "../partnerportal/src/fable_modules/fable-library.3.7.17/List.js";
import { iterate } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Seq.js";
import { toArray } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Option.js";
import { Interop_reactApi, DateParsing_parse } from "../partnerportal/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { GeneralFormats_dateTime } from "../shared/Format.fs.js";
import { weekEndDate, getDayStart } from "./Utils.fs.js";
import { addDays } from "../partnerportal/src/fable_modules/fable-library.3.7.17/DateOffset.js";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../partnerportal/src/fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { rgba } from "../partnerportal/src/fable_modules/Feliz.1.68.0/Colors.fs.js";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

export class DateRange extends Record {
    constructor(Date1, Date2) {
        super();
        this.Date1 = Date1;
        this.Date2 = Date2;
    }
}

export function DateRange$reflection() {
    return record_type("FilterUtils.DateRange", [], DateRange, () => [["Date1", class_type("System.DateTime")], ["Date2", class_type("System.DateTime")]]);
}

export function FilterFieldDateRange(label, onChange, v) {
    let elems_1, elements, dt, dt_1;
    let patternInput;
    if (v == null) {
        patternInput = [void 0, void 0];
    }
    else {
        const d = v;
        patternInput = [d.Date1, d.Date2];
    }
    const dropDownOpen = useToggle(false);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(patternInput[0]);
    const setDate1 = patternInput_1[1];
    const date1 = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(patternInput[1]);
    const setDate2 = patternInput_2[1];
    const date2 = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const setDateRangeText = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const setAnchorEl = patternInput_4[1];
    const anchorEl = patternInput_4[0];
    useReact_useEffect_Z101E1A95(() => {
        const matchValue = [date1, date2];
        let pattern_matching_result, date1_1, date2_1;
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                pattern_matching_result = 0;
                date1_1 = matchValue[0];
                date2_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                onChange(new DateRange(date1_1, date2_1));
                let s;
                let copyOfStruct = date(date1_1);
                s = toString(copyOfStruct, "dd/MM/yyyy");
                let e;
                let copyOfStruct_1 = date(date2_1);
                e = toString(copyOfStruct_1, "dd/MM/yyyy");
                setDateRangeText(`${s}-${e}`);
                break;
            }
            case 1: {
                onChange(void 0);
                setDateRangeText("");
                break;
            }
        }
    }, [date1, date2]);
    const htmlBtn = createElement("button", {
        children: "Select",
        onClick: (e_1) => {
        },
    });
    const openPopover = (e_2) => {
        const el = e_2.currentTarget;
        dropDownOpen.Toggle();
        toConsole(printf("%A"))(el);
        setAnchorEl(el);
    };
    const btn = MuiHelpers_createElement(Button, [["onClick", (e_3) => {
        openPopover(e_3);
    }], ["variant", "contained"], ["color", "default"], ["children", "Select"]]);
    const hidePopover = () => {
        setAnchorEl(void 0);
    };
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [MuiHelpers_createElement(TextField, [["label", label], ["type", "text"], ["readOnly", true], ["value", patternInput_3[0]], ["onClick", (e_4) => {
        openPopover(e_4);
    }]]), MuiHelpers_createElement(Popover, [["classes.paper", Padding.M], ["anchorOrigin", {
        vertical: "bottom",
        horizontal: "left",
    }], ["anchorEl", anchorEl], ["open", anchorEl != null], ["onClose", (_arg_1, v_1) => {
        hidePopover();
    }], (elements = [createElement(Row, {
        classes: singleton(Gap.M),
        children: ofArray([createElement(Col, {
            classes: empty(),
            children: ofArray(["Date From", createElement("input", {
                type: "date",
                onChange: (ev) => {
                    iterate((n) => {
                        setDate1(n);
                    }, toArray(DateParsing_parse(ev.target.value)));
                },
                className: join(" ", [fullWidth]),
                value: (date1 == null) ? "" : ((dt = date1, (toConsole(printf("date1 %A"))(dt), GeneralFormats_dateTime(dt)))),
            })]),
        }), createElement(Col, {
            classes: empty(),
            children: ofArray(["Date To", createElement("input", {
                type: "date",
                onChange: (ev_1) => {
                    iterate((n_1) => {
                        setDate2(n_1);
                        setAnchorEl(void 0);
                    }, toArray(DateParsing_parse(ev_1.target.value)));
                },
                className: join(" ", [fullWidth]),
                value: (date2 == null) ? "" : ((dt_1 = date2, (toConsole(printf("date2 %A"))(dt_1), GeneralFormats_dateTime(dt_1)))),
            })]),
        })]),
    }), createElement(Row, {
        classes: ofArray([Gap.M, MarginTop.M]),
        children: ofArray([createElement(Col, {
            classes: empty(),
            children: singleton(MuiHelpers_createElement(Button, [["onClick", (e_5) => {
                let copyOfStruct_3, copyOfStruct_2, copyOfStruct_5, copyOfStruct_4;
                setDate1((copyOfStruct_3 = getDayStart((copyOfStruct_2 = utcNow(), date(copyOfStruct_2))), fromDateTimeOffset(copyOfStruct_3, 0)));
                setDate2((copyOfStruct_5 = ((copyOfStruct_4 = weekEndDate, addDays(copyOfStruct_4, 1))), fromDateTimeOffset(copyOfStruct_5, 0)));
                hidePopover();
            }], ["variant", "contained"], ["color", "default"], ["size", "small"], ["children", "Deze week"]])),
        }), createElement(Col, {
            classes: empty(),
            children: singleton(MuiHelpers_createElement(Button, [["onClick", (e_6) => {
                let copyOfStruct_6, copyOfStruct_7, copyOfStruct_10, copyOfStruct_9;
                let monthEnd;
                let copyOfStruct_8 = addMonths(create((copyOfStruct_6 = today(), year(copyOfStruct_6)), (copyOfStruct_7 = today(), month(copyOfStruct_7)), 1), 1);
                monthEnd = addDays_1(copyOfStruct_8, -1);
                setDate1((copyOfStruct_10 = getDayStart((copyOfStruct_9 = utcNow(), date(copyOfStruct_9))), fromDateTimeOffset(copyOfStruct_10, 0)));
                setDate2(monthEnd);
                hidePopover();
            }], ["variant", "contained"], ["color", "default"], ["size", "small"], ["children", "Deze maand"]])),
        }), createElement(Col, {
            classes: empty(),
            children: singleton(MuiHelpers_createElement(Button, [["onClick", (e_7) => {
                setDate1(void 0);
                setDate2(void 0);
                hidePopover();
            }], ["variant", "contained"], ["color", "default"], ["size", "small"], ["children", "Alles"]])),
        })]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function CheckboxFilter(checkboxFilterInputProps) {
    let elements;
    const onChange = checkboxFilterInputProps.onChange;
    const checked$0027 = checkboxFilterInputProps["checked\u0027"];
    const label = checkboxFilterInputProps.label;
    const checkbox = MuiHelpers_createElement(Checkbox, [["checked", checked$0027], ["onChange", (e) => {
        onChange(Browser_Types_Event__Event_get_Checked(e));
    }]]);
    return MuiHelpers_createElement(FormGroup, [(elements = [MuiHelpers_createElement(FormControlLabel, [["className", PaddingTop.M], ["style", {
        whiteSpace: "nowrap",
        marginLeft: 0,
        color: rgba(0, 0, 0, 0.54),
    }], ["label", [createElement("div", {
        children: Interop_reactApi.Children.toArray([label]),
    })]], ["control", checkbox], ["labelPlacement", "start"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
}

export function ToggleFilter(toggleFilterInputProps) {
    let elements_2, elements_3;
    const valueRight = toggleFilterInputProps.valueRight;
    const labelRight = toggleFilterInputProps.labelRight;
    const valueLeft = toggleFilterInputProps.valueLeft;
    const labelLeft = toggleFilterInputProps.labelLeft;
    const onChange = toggleFilterInputProps.onChange;
    const value = toggleFilterInputProps.value;
    const label = toggleFilterInputProps.label;
    const toggle = MuiHelpers_createElement(ToggleButtonGroup, [["size", "small"], ["value", value], ["exclusive", true], ["onChange", (_arg, v) => {
        onChange(v);
    }], (elements_2 = [MuiHelpers_createElement(ToggleButton, [["value", valueLeft], ["children", Interop_reactApi.Children.toArray([labelLeft])]]), MuiHelpers_createElement(ToggleButton, [["value", valueRight], ["children", Interop_reactApi.Children.toArray([labelRight])]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
    return MuiHelpers_createElement(FormGroup, [(elements_3 = [MuiHelpers_createElement(FormControlLabel, [["className", PaddingTop.M], ["style", {
        whiteSpace: "nowrap",
        marginLeft: 0,
        color: rgba(0, 0, 0, 0.54),
    }], ["label", [createElement("div", {
        children: Interop_reactApi.Children.toArray([label]),
    })]], ["control", toggle], ["labelPlacement", "start"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]);
}

