import { createElement } from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { TextColor, MarginTop, MarginBottom, fullWidth, textCenter, vCenter, hCenter, alignCenter, appBarLogo, spaceBetween, flexExpand } from "../../../Styles/Utils.fs.js";
import { empty, ofArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import AppBar_1 from "@material-ui/core/AppBar";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { CssRuleWithNone__get_none } from "../fable_modules/Fss-lib.Core.1.0.4/Types/MasterTypes.fs.js";
import { BoxShadow } from "../fable_modules/Fss-lib.Core.1.0.4/css/BoxShadow.fs.js";
import { ColorClass_Color__hex_Z721C83C5 } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Color.fs.js";
import { BorderBottomWidth, BorderBottomStyle, BorderBottomColor } from "../fable_modules/Fss-lib.Core.1.0.4/css/Border.fs.js";
import { BorderClasses_BorderStyleParent__get_solid } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Border.fs.js";
import { unitHelpers_CssRuleWithLength__value_Z498FEB3B, unitHelpers_DirectionalLength__value_Z498FEB3B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { px } from "../fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import Toolbar from "@material-ui/core/Toolbar";
import { logo } from "../Img.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { fss } from "../fable_modules/Fss-lib.Fable.1.0.3/FssFable.fs.js";
import { FontSize } from "../fable_modules/Fss-lib.Core.1.0.4/css/Font.fs.js";
import { useAuth0 } from "@auth0/auth0-react";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";
import Button from "@material-ui/core/Button";

export function AppBar(children) {
    let elems_1, elements;
    return createElement(Col, {
        classes: singleton(flexExpand),
        children: ofArray([MuiHelpers_createElement(AppBar_1, [["style", {
            backgroundColor: "#FFFFFF",
        }], Feliz_prop__prop_fss_Static_72C268A9(ofArray([CssRuleWithNone__get_none(BoxShadow), ColorClass_Color__hex_Z721C83C5(BorderBottomColor, "f0f0f0"), BorderClasses_BorderStyleParent__get_solid(BorderBottomStyle), unitHelpers_DirectionalLength__value_Z498FEB3B(BorderBottomWidth, px(1))])), ["position", "sticky"], (elems_1 = [MuiHelpers_createElement(Toolbar, [["classes.root", spaceBetween], (elements = [createElement("img", {
            className: appBarLogo,
            alt: "Asbest Consulting Logo",
            src: logo,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement(Row, {
            classes: empty(),
            children: children,
        })]),
    });
}

export const titleClass = fss(singleton(unitHelpers_CssRuleWithLength__value_Z498FEB3B(FontSize, px(52))));

export function Login() {
    const auth = useAuth0();
    return AppBar(singleton(createElement(Col, {
        classes: ofArray([alignCenter, hCenter, vCenter, textCenter, fullWidth]),
        children: toList(delay(() => {
            const patternInput = (window.location.hostname.indexOf("syndic") >= 0) ? ["Syndic Portaal", "syndic"] : ["Partner Portaal", "partner"];
            return append(singleton_1(createElement("h1", {
                children: patternInput[0],
                className: join(" ", [MarginBottom.L, MarginTop.Xl, TextColor.Primary, titleClass]),
            })), delay(() => {
                let children, elems;
                return singleton_1((children = singleton(MuiHelpers_createElement(Button, [["color", "secondary"], ["variant", "contained"], ["onClick", (_arg) => {
                    auth.loginWithPopup();
                }], (elems = [`Login als ${patternInput[1]}`], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                })));
            }));
        })),
    })));
}

